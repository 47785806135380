import { call, put, takeLatest } from "redux-saga/effects";
import connectMySlice, { connectMyDataRequest } from "./connectMySlice";
import { getMyConnects } from "services/connectService";

function* fetchMyConnectSaga(
  action: ReturnType<typeof connectMyDataRequest>
): object {
  yield put(connectMySlice.actions.get_connect_all_data());

  try {
    const response = yield call(getMyConnects);
    yield put(connectMySlice.actions.success_connect_all_data(response.data));
  } catch (e) {
    yield put(connectMySlice.actions.error_connect_all_data(e as Error));
  }
}

function* connectMySaga() {
  yield takeLatest(connectMyDataRequest.type, fetchMyConnectSaga);
}

export default connectMySaga;
