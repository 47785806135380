import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { proposalAllDataRequest } from "redux/features/investor/proposal/proposalAllSlice";
import ProposalTable from "./ProposalTable";
import { toast } from "react-toastify";
import { IDataType } from "redux/features/connect/connectAllSlice";
import { getProposalRequestAll } from "services/InvestorService";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export type IProposalStatus = "pending" | "accepted" | "rejected" | "all";

export default function ProposalTabs() {
  const [value, setValue] = React.useState<IProposalStatus>("all");
  const dispatch = useDispatch();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IProposalStatus
  ) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(
      proposalAllDataRequest({
        status: value === "all" ? null : value,
      })
    );
  }, [dispatch, value]);

  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    const res = await getProposalRequestAll(null)
    if (res?.status === 200 || res.status === 201) {
      setAllData(res?.data);
    } else {
      toast("Something went wrong");
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [value]);

  const acceptedCount = allData.filter((data: IDataType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IDataType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IDataType) => data.status === "Pending").length

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Proposals</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"All" + ` (${allData.length})`}
                value="all"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Pending" + ` (${pendingCount})`}
                value="pending"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Accepted" + ` (${acceptedCount})`}
                value="accepted"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Rejected" + ` (${rejectedCount})`}
                value="rejected"
              />
            </TabList>
          </Box>
          <TabPanel value="all">
            <ProposalTable />
          </TabPanel>
          <TabPanel value="pending">
            <ProposalTable />
          </TabPanel>
          <TabPanel value="accepted">
            <ProposalTable />
          </TabPanel>
          <TabPanel value="rejected">
            <ProposalTable />
          </TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
