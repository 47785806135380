import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TypographyStyleOptions } from "@mui/material/styles/createTypography";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "redux/app/store";
import Router from "routes/routes";
import "./App.css";

const headings: TypographyStyleOptions = {
  fontFamily: "Montserrat,sans-serif",
};

const theme = createTheme({
  typography: {
    fontFamily: "Roboto",
    h1: headings,
    h2: headings,
    h3: headings,
    h4: headings,
    h5: headings,
    h6: headings,
  },
  components: {},
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <PersistGate loading={null} persistor={persistor}>
          {Router()}
        </PersistGate>
        <ToastContainer position="bottom-left" />
      </div>
    </ThemeProvider>
  );
}

export default App;
