import { Box } from "@mui/material";
import { API_BASE_URL } from "constants/api-constants";
import { genderLabel } from "pages/main/componnets/contents/center-content/components/profile/investor/IndividualInvestor";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useMemo, useState } from "react";
import {
  CiAt,
  CiBadgeDollar,
  CiCalendar,
  CiCircleCheck,
  CiHashtag,
  CiMobile3,
  CiSquareCheck,
} from "react-icons/ci";
import ProfileIcon from "./ProfileIcon";
import FullButton from "./FullButton";
import ProposalConnectModal from "pages/dashboard/admin/connect/modal/ProposalConnectModal";
import { IButtonStatus } from "pages/dashboard/investor/feed-details/InvestorFeedDetails";

export default function UserDetailCard({
  details,
  eligibleConnect
}: {
  details?: IInvestorType | null;
  eligibleConnect?: boolean
}) {
  let data: IInvestorType | null | undefined;
  if (details?.investor_profile === "Individual") {
    data = details;
  } else if (details?.investor_profile === "Institutional") {
    data = details;
  }

  const profile = useMemo(() => {
    const pic = data?.files?.find((dl) => {
      return dl?.filename.split("-")[0] === "profile_pic" ?? null;
    });
    return pic;
  }, [data]);

  const [open2, setOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const [requestStatus, setRequestStatus] =
    useState<IButtonStatus>("uninitialized");

  const sendRequest = () => {
    setRequestStatus("pending");
    handleClose();
  };

  return (
    <>
      <div className="card-title">
        <h3>Investor Profile</h3>
        {/* <div className="settings">
          <div className="setting-btn">
            <Link to="/investor/profile">
              <IconButton>
                <Icon.Edit2 className="icon icon-dark stroke-width-3 iw-11 ih-11" />
              </IconButton>
            </Link>
          </div>
        </div> */}
      </div>
      <div className="about-content">
        <ul>
          <li>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="80px" height="80px">
                <ProfileIcon
                  name={data?.full_name ?? ""}
                  profile={profile ? `${API_BASE_URL}/${profile?.path}` : null}
                  sx={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
          </li>
          {details?.connect_status === "Pending"
            ? (
              <div style={{ marginTop: "20px" }}>
                <span>
                  Connect request has been sent. Contact details will be available once you are connected with
                  this user.
                </span>
              </div>
            ) : eligibleConnect ? (
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }}>
                <span>
                  Contact Detail will be available once you are connected with
                  this user.
                </span>

                <FullButton onClick={handleOpen2} text="Connect Now" type="filled" />

                <ProposalConnectModal
                  open={open2}
                  handleClose={handleClose2}
                  onConfirm={sendRequest}
                />
              </div>
            ) : details?.connect_status === "Rejected" ? (
              <div style={{ marginTop: "20px" }}>
                <span>Your connection request has been rejected.</span>
              </div>
            ) : (
              <li>
                <div className="icon">
                  <CiBadgeDollar />
                </div>
                <div className="details">
                  <h5>Type of Investor</h5>
                  <h6>{data?.investor_profile ?? "Individual"}</h6>
                </div>
              </li>
            )}

          {details?.connect_status === "Pending" ||
            details?.connect_status === "Rejected" ||
            details?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiCircleCheck />
              </div>
              <div className="details">
                {data?.investor_profile === "Individual" ? (
                  <h5>Full Name</h5>
                ) : (
                  <h5>Company Name</h5>
                )}
                <h6>{data?.full_name}</h6>
              </div>
            </li>
          )}

          {details?.connect_status === "Pending" ||
            details?.connect_status === "Rejected" ||
            details?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiSquareCheck />
              </div>
              <div className="details">
                <h5>Title/Designation</h5>
                <h6>{details?.title_designation}</h6>
              </div>
            </li>
          )}
          {details?.connect_status === "Pending" ||
            details?.connect_status === "Rejected" ||
            details?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiAt />
              </div>
              <div className="details">
                <h5>Email</h5>
                <h6
                  style={{
                    textTransform: "unset",
                  }}
                >
                  {details?.email ?? <em>Not specified</em>}
                </h6>
              </div>
            </li>
          )}
          {details?.investor_profile === "Institutional" && (
            <li>
              <div className="icon">
                <CiMobile3 />
              </div>
              <div className="details">
                <h5>Phone</h5>
                <h6>{details?.phone}</h6>
              </div>
            </li>
          )}
          {details?.investor_profile === "Individual" && (
            <li>
              <div className="icon">
                <CiCalendar />
              </div>
              <div className="details">
                <h5>Age</h5>
                <h6>{details?.age ?? <em>Not specified</em>}</h6>
              </div>
            </li>
          )}
          {details?.investor_profile === "Individual" && (
            <li>
              <div className="icon">
                <CiHashtag />
              </div>
              <div className="details">
                <h5>Gender</h5>
                <h6>
                  {genderLabel?.find(
                    (el) => el?.value === details?.gender?.toLowerCase()
                  )?.label ?? ""}
                </h6>
              </div>
            </li>
          )}

          {details?.investor_profile === "Individual" && (
            <li>
              <div className="icon">
                <CiMobile3 />
              </div>
              <div className="details">
                <h5>Phone</h5>
                <h6>{details?.phone}</h6>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
