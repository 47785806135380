import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "redux/app/genericState";

export type IAnalyticsData = {
  access_request_sent: number;
  proposal_received: number | null | undefined;
};

interface AnalyticsState extends GenericState<IAnalyticsData | null> {}

const initialState: AnalyticsState = {
  data: null,
  error: undefined,
  status: "none",
};

export const analyticsInvestorData = createAction("ANALYTICS_INVESTOR_DATA");

const analyticsInvestorSlice = createSlice({
  name: "analytics-investor",
  initialState,
  reducers: {
    get_analytics_investor_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_analytics_investor_data: (
      state,
      action: PayloadAction<IAnalyticsData>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_analytics_investor_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default analyticsInvestorSlice;
