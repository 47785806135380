import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuList, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/system";
import useOutsideClick from "hooks/useOutsideClick";
import * as React from "react";
import * as Icon from "react-feather";
import SideDrawer from "./SideDrawer";

const menuItems = [
  {
    label: "Downloads",
    icon: <Icon.Download />,
  },
  {
    label: "About Us",
    icon: <Icon.Info />,
  },
  {
    label: "Faqs",
    icon: <Icon.MessageSquare />,
  },
];

export default function DropDownHam() {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [showDrawer, setShowDrawer] = React.useState<boolean>(false);

  const handleToggle = () => {
    setShowMenu(!showMenu);
  };

  const openMenu = () => {
    setShowMenu(false);
  };
  const dropdownRef = React.useRef<any>(null);
  useOutsideClick(dropdownRef, () => {
    setShowMenu(false);
  });

  return (
    <Box position="relative" ref={dropdownRef}>
      <Box className="desktop-menu">
        <IconButton
          sx={{
            borderRadius: "5px",
          }}
          id="basic-button"
          onClick={handleToggle}
        >
          <MoreVertIcon
            style={{
              fontSize: "18px",
              color: "#0389c9",
            }}
          />
        </IconButton>
      </Box>

      <Box className="mobile-menu">
        <IconButton
          sx={{
            borderRadius: "5px",
          }}
          id="basic-button"
          onClick={() => setShowDrawer(true)}
        >
          <MoreVertIcon
            style={{
              fontSize: "18px",
              color: "#0389c9",
            }}
          />
        </IconButton>
      </Box>
      {showMenu && (
        <Paper
          sx={{
            width: 130,
            position: "absolute",
            top: "calc(100% + 10px)",
            right: 0,
          }}
        >
          <MenuList>
            {menuItems?.map((el) => (
              <MenuItem onClick={openMenu}>{el?.label}</MenuItem>
            ))}
          </MenuList>
        </Paper>
      )}
      <SideDrawer
        open={showDrawer}
        handleClose={() => setShowDrawer(false)}
        list={menuItems}
        hideIProfile={true}
      />
    </Box>
  );
}
