import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import { IProposalStatus } from "pages/dashboard/admin/proposal/ProposalTabs";
import * as React from "react";
import { toast } from "react-toastify";
import { getIdeaholderProposalRequest } from "services/ideaholderService";
import ProposalsTable from "./ProposalsTable";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export default function ProposalsTabs() {
  const [value, setValue] = React.useState<IProposalStatus>("all");
  const [display, setDisplay] = React.useState([]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IProposalStatus
  ) => {
    setValue(newValue);
  };

  const { ideaProfile } = useIdeaHolderState();

  const getProposalsService = async () => {
    if (ideaProfile?.id) {
      const res = await getIdeaholderProposalRequest(
        ideaProfile?.id,
        value === "all" ? null : value
      );
      if (res?.status === 200 || res.status === 201) {
        setDisplay(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  };

  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    if (ideaProfile?.id) {
      const res = await getIdeaholderProposalRequest(ideaProfile?.id, null)
      if (res?.status === 200 || res.status === 201) {
        setAllData(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [ideaProfile?.id, value]);

  const acceptedCount = allData.filter((data: IInvestorType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IInvestorType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IInvestorType) => data.status === "Pending").length

  React.useEffect(() => {
    getProposalsService();
  }, [ideaProfile?.id, value]);

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Idea Proposals</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"All" + ` (${allData.length})`}
                value="all"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Pending" + ` (${pendingCount})`}
                value="pending"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Accepted" + ` (${acceptedCount})`}
                value="accepted"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Rejected" + ` (${rejectedCount})`}
                value="rejected"
              />
            </TabList>
          </Box>
          <TabPanel value="all">
            <ProposalsTable data={display} />
          </TabPanel>
          <TabPanel value="pending">
            <ProposalsTable data={display} />
          </TabPanel>
          <TabPanel value="accepted">
            <ProposalsTable data={display} />
          </TabPanel>
          <TabPanel value="rejected">
            <ProposalsTable data={display} />
          </TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
