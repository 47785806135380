const SuggestedInvestors = [
  {
    src: "../assets/images/story/2.jpg",
    name: "Madan Shrestha",
    class: "skin-overlay",
  },
  {
    src: "../assets/images/story/7.jpg",
    name: "Prashhida Kansakar",
    class: "blue-overlay",
  },
  {
    src: "../assets/images/story/1.jpg",
    name: "Abc Investor",
    class: "pink-overlay",
  },
  {
    src: "../assets/images/story/3.jpg",
    name: "Aman Malakar",
    class: "red-overlay",
  },
];
export default SuggestedInvestors;
