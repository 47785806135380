import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

export default function useIdeaHolderState() {
  const idea = useSelector((state: RootState) => state?.ideaholder);

  const hasIdeaProfile =
    idea?.status === "loaded" &&
    idea?.data &&
    Object.keys(idea?.data)?.length > 0;

  return {
    status: idea?.status ?? "none",
    ideaProfile: idea?.data ?? null,
    hasIdeaProfile: !!hasIdeaProfile,
  };
}
