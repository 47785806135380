import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import LandingCard from "components/LandingCard";
import LandingCardData from "data/LandingCard";
import useLoginState from "hooks/useLoginState";
import ProfileBox from "pages/main/componnets/contents/left-content/components/ProfileBox";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function ProfileMain() {
  const { userDetails, isLoggedIn } = useLoginState();
  const data = LandingCardData();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form" style={{ minHeight: "310px" }}>
        <div className="setting-wrapper about-profile">
          <div className="top-form">
            <h3>Account Dashboard</h3>
          </div>
          <div className="title-main">
            <span>Account Details</span>
          </div>
          <form className="theme-form">
            <div className="form-wrap">
              <div className="form-group">
                <ul className="about-list p-0">
                  <li>
                    <h5 className="title"> Full Name:</h5>
                    <h6 className="content">{userDetails?.name}</h6>
                  </li>
                  <li>
                    <h5 className="title">Email: </h5>
                    <h6 className="content">{userDetails?.email}</h6>
                  </li>
                  <li>
                    <h5 className="title">Contact Number: </h5>
                    <h6 className="content">
                      {userDetails?.phone_no ?? <em>Not available</em>}
                    </h6>
                  </li>
                  <li>
                    <span className="m-d">Address Details</span>
                  </li>
                  <li>
                    <h5 className="title"> Municipality</h5>
                    <h6 className="content">
                      {userDetails?.location?.municipality?.name ?? (
                        <em>Not available</em>
                      )}
                    </h6>
                  </li>
                  <li>
                    <h5 className="title"> Area Name</h5>
                    <h6 className="content">
                      {userDetails?.location?.area?.area_name ?? (
                        <em>Not available</em>
                      )}
                    </h6>
                  </li>
                  <li>
                    <h5 className="title">Street Name</h5>
                    <h6 className="content">
                      {userDetails?.location?.street?.street_name ?? (
                        <em>Not available</em>
                      )}
                    </h6>
                  </li>
                </ul>
                {/* 
                <div className="title-main">
                  <span>Change Password</span>
                </div>

                <div className="box-gap">
                  <TextField
                    id="outlined-password-input"
                    label="Old Password"
                    type="password"
                    autoComplete="Old Password"
                    fullWidth
                  />
                </div>

                <div className="box-gap">
                  <TextField
                    id="outlined-password-input"
                    label="New Password"
                    type="text"
                    autoComplete="New Password"
                    fullWidth
                  />
                </div>
                <div className="box-gap">
                  <TextField
                    id="outlined-password-input"
                    label="Re-enter Password"
                    type="text"
                    autoComplete="Re-enter Password"
                    fullWidth
                  />
                </div> */}

                {/* <div className="box-gap m-top">
                  <CustomButton text="Change Password" type="filled" />
                </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className="idea-form"
        style={{ minHeight: "310px", marginTop: "10px" }}
      >
        <div className="setting-wrapper about-profile">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Paper
                elevation={1}
                sx={{
                  flex: 1,
                }}
              >
                <ProfileBox />
              </Paper>
            </Grid>
            {data.slice(1, 3).map((dl, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box display="flex" justifyContent="center" height="100%">
                  <LandingCard dl={dl} key={index} showView={dl?.showView} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
