import IconButton from "@mui/material/IconButton";
import { Box, styled } from "@mui/system";
import SideDrawer from "components/SideDrawer";
import sidebarContent from "data/SidebarContent";
import useLoginState from "hooks/useLoginState";
import { useState } from "react";
import {
  AiFillBulb,
  AiFillHome,
  AiOutlineBulb,
  AiOutlineHome,
} from "react-icons/ai";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import {
  RiAdminFill,
  RiAdminLine,
  RiBriefcase4Fill,
  RiBriefcase4Line,
} from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const ContentCenter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "0.3s ease-in-out",
});

export default function BottomNavbar() {
  const loc = useLocation();
  const [open, setOpen] = useState(false);
  const currentLoc = loc?.pathname?.split("/")[1];

  const { isAdmin } = useLoginState();

  return (
    <Box className="mobile-fix-menu">
      <Box
        height="100%"
        display="flex"
        justifyContent="space-between"
        sx={{
          "& svg": {
            fontSize: "1.5rem",
          },
        }}
      >
        <MenuItem
          title="home"
          icon={currentLoc === "home" ? <AiFillHome /> : <AiOutlineHome />}
          slug="/"
        />
        <MenuItem
          title="idea"
          icon={currentLoc === "idea" ? <AiFillBulb /> : <AiOutlineBulb />}
          slug="/idea"
        />
        <MenuItem
          title="investor"
          icon={
            currentLoc === "investor" ? (
              <RiBriefcase4Fill />
            ) : (
              <RiBriefcase4Line />
            )
          }
          slug="/investor"
        />

        {isAdmin && (
          <MenuItem
            title="admin"
            icon={currentLoc === "admin" ? <RiAdminFill /> : <RiAdminLine />}
            slug="/admin"
          />
        )}

        <ContentCenter flex={1}>
          <IconButton
            sx={{
              padding: "6px 20px",
              backgroundColor: open ? "rgba(3, 137, 201, 0.05)" : "#fff",
            }}
            onClick={() => setOpen(!open)}
          >
            <HiOutlineMenuAlt3
              style={{
                fontSize: "22px",
                color: "#0389c9",
              }}
            />
          </IconButton>
        </ContentCenter>
      </Box>
      <SideDrawer
        open={open}
        handleClose={() => setOpen(false)}
        list={sidebarContent[currentLoc]}
      />
    </Box>
  );
}

const MenuItem = ({
  title,
  slug,
  icon,
}: {
  title: string;
  slug: string;
  icon: React.ReactNode;
}) => {
  const loc = useLocation();
  const currentLoc = loc?.pathname?.split("/")[1];
  return (
    <ContentCenter flex={2}>
      <ContentCenter
        style={{
          width: "60px",
          padding: "6px 20px",
          borderRadius: "18px",
          backgroundColor:
            currentLoc === title ? "rgba(3, 137, 201, 0.05)" : "#fff",
        }}
      >
        <Link to={slug}>
          <Box display="flex">
            {icon}
            {/* <span
              style={{
                textTransform: "capitalize",
              }}
              className="nav-text"
            >
              {title}
            </span> */}
          </Box>
        </Link>
      </ContentCenter>
    </ContentCenter>
  );
};
