import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { AxiosError } from "axios";
import AddressComponent from "components/AddressComponent";
import CustomButton from "components/CustomButton";
import PhoneCountryInput from "components/PhoneCountry";
import UploadFile from "components/UploadFile";
import GoogleLibNumber from "google-libphonenumber";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import useLoginState from "hooks/useLoginState";
import usePhoneValidator, {
  CountryCodeType,
  defaultCode,
} from "hooks/usePhoneValidator";
import { IIdeaholderType } from "models/Ideaholder";
import { IResponseMessageType } from "models/ResponseMessage";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ideaholderDetailRequest } from "redux/features/ideaholder/ideaholderSlice";
import { createIdeaholder } from "services/ideaholderService";
import * as yup from "yup";

const phoneUtil = GoogleLibNumber.PhoneNumberUtil.getInstance();

const genderLabel: IGenderType[] = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Rather not say", value: "others" },
];

export type IGenderType = {
  label: string;
  value: "male" | "female" | "others";
};

const validationResolver = (phoneCode: string) => {
  return {
    schema: yup.object().shape({
      full_name: yup
        .string()
        .required("Name is required")
        .max(70, "Text must be less than 70 characters"),
      email: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
      phone: yup
        .string()
        .trim()
        .required("Phone is  required")
        .test(
          "is_phone_valid",
          "Phone number needs to be valid",
          function (value) {
            if (!value?.length) {
              return this.createError({
                message: "Phone number not provided.",
              });
            }
            try {
              const number = phoneUtil.parse(value, phoneCode);

              return phoneUtil.isValidNumber(number);
            } catch (err) {
              return this.createError({
                message: "Phone number is not correct",
              });
            }
          }
        ),
      gender: yup
        .string()
        .required("Gender must be male, female or undisclosed")
        .oneOf(genderLabel.map((gender) => gender.value)),
      age: yup
        .number()
        .typeError("Age must be a number")
        .required("Age is required")
        .min(18, "Age must be greater than 18")
        .max(115, "Age must be less than 115"),
      citizenship_no: yup.string().required("Citizenship number is required"),

      permanent_location_municipality: yup
        .object()
        .shape({
          name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Location is required"),

      permanent_location_area: yup
        .object()
        .shape({
          area_name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Area is required"),

      permanent_location_street: yup
        .object()
        .shape({
          street_name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Street is required"),

      cv: yup
        .mixed()
        .test("required", "You need to provide a cv", (file: File) => {
          if (file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return file && file.size <= 25000000;
          }
        ),
      profile_pic: yup
        .mixed()
        .test("required", "You need to provide a profile pic", (file: File) => {
          if (file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return file && file.size <= 25000000;
          }
        ),
      citizenship: yup
        .mixed()
        .test("required", "You need to provide a citizenship", (file: File) => {
          if (file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return file && file.size <= 25000000;
          }
        ),
    }),
  };
};

export default function IdeaProfileForm({ data }: { data?: IIdeaholderType }) {
  const dispatch = useDispatch();

  // Phone number default country
  const [defaultCountryCode, setDefaultCountryCode] =
    useState<CountryCodeType>(defaultCode);

  // const getDefaultCountry = async () => {
  //   const res = await getDefaultCountryService();
  //   if (res?.status === 200) {
  //     const country = countries.find((x) => x.code === res?.data?.country_code);
  //     setDefaultCountryCode({
  //       code: country?.code ?? "",
  //       label: country?.label ?? "",
  //       phone: country?.phone ?? "",
  //     });
  //   }
  // };
  //

  // form ko schema with useMemo
  const [trackCountryCode, setTrackCountryCode] = useState(
    defaultCountryCode ?? defaultCode
  );

  const registerSchema = useMemo(() => {
    const { schema } = validationResolver(trackCountryCode?.code ?? "np");
    return schema;
  }, [trackCountryCode]);
  //

  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  const { userDetails } = useLoginState();
  const { ideaProfile } = useIdeaHolderState();

  useEffect(() => {
    if (ideaProfile) {
      navigate("/idea/profile/detail");
    }
  }, [ideaProfile, navigate]);

  const formatISGender = (val: string) => {
    const genders = ["others", "male", "female"];
    return genders[+val];
  };

  const hookForm = useForm<IIdeaholderType>({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      full_name: data?.full_name ?? userDetails?.name ?? "",
      email: data?.email ?? userDetails?.email ?? "",
      age: data?.age ?? "",
      phone: data?.phone ?? userDetails?.phone_no ?? "",
      gender:
        data?.gender?.toLowerCase() ??
        formatISGender(userDetails?.gender ?? "") ??
        null,
      citizenship_no: data?.citizenship_no ?? "",
      citizenship: null,
      cv: null,
      profile_pic: null,
      permanent_location_municipality:
        data?.permanent_location_municipality ??
        userDetails?.location?.municipality ??
        null,
      permanent_location_area:
        data?.permanent_location_area ?? userDetails?.location?.area ?? null,
      permanent_location_street:
        data?.permanent_location_street ??
        userDetails?.location?.street ??
        null,
      temporary_location_area: data?.temporary_location_area ?? null,
      temporary_location_street: data?.temporary_location_street ?? null,
      temporary_location_municipality:
        data?.temporary_location_municipality ?? null,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = hookForm;

  //for phone validation

  const {
    watch: watchPhone,
    setValue: setPhoneValue,
    control: controlPhone,
  } = usePhoneValidator({
    useRegisterForm: hookForm,
    defaultCountryCode: defaultCountryCode ?? defaultCode,
  });

  const code = watchPhone("code");
  const setCountry = (code: CountryCodeType) => {
    setTrackCountryCode(code);
    setPhoneValue("code", code);
  };

  //end

  /* Start Form Submit */

  const onSubmit = async (data: IIdeaholderType) => {
    let mainData: IIdeaholderType;
    const fd = new FormData();

    mainData = data as IIdeaholderType;

    fd.append("full_name", mainData?.full_name);
    fd.append("email", mainData?.email);
    fd.append("age", mainData?.age?.toString());
    fd.append("phone", mainData?.phone?.toString());
    fd.append("gender", mainData?.gender ?? "");
    fd.append("citizenship_no", mainData?.citizenship_no);
    fd.append("citizenship", mainData?.citizenship as Blob);
    fd.append("cv", mainData?.cv as Blob);
    fd.append("profile_pic", mainData?.profile_pic as Blob);
    fd.append(
      "temporary_location_street",
      data?.temporary_location_street?.street_name ?? ""
    );
    fd.append(
      "temporary_location_area",
      data?.temporary_location_area?.area_name ?? ""
    );
    fd.append(
      "temporary_location_municipality",
      data?.temporary_location_municipality?.name ?? ""
    );
    fd.append(
      "permanent_location_street",
      data?.permanent_location_street?.street_name || ""
    );
    fd.append(
      "permanent_location_area",
      data?.permanent_location_area?.area_name ?? ""
    );
    fd.append(
      "permanent_location_municipality",
      data?.permanent_location_municipality?.name ?? ""
    );

    setSending(true);
    try {
      await createIdeaholder(fd);
      toast("Business Profile Created Successfully!", { type: "success" });
      dispatch(ideaholderDetailRequest());
      navigate("/idea/profile/detail");
      setSending(false);
    } catch (e) {
      const error = e as AxiosError<IResponseMessageType>;
      let message = error.response?.data?.message || "Some error";
      if (Array.isArray(message)) {
        message = message.join(", ");
      }
      setSending(false);
      toast(message, {
        type: "error",
      });
    }
  };

  /* End Form Submit */

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="top-form">
          <h3>Create Business Profile</h3>
          <h6>Fill in the details below to create an ideaholder profile</h6>
        </div>
        <div className="title-main">
          <span>Personal Details</span>
        </div>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="theme-form"
        >
          <FormProvider {...hookForm}>
            <div className="form-wrap">
              <div className="form-group">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-password-input"
                      label="Full Name"
                      type="text"
                      autoComplete="Full Name"
                      fullWidth
                      required
                      {...register("full_name")}
                      error={!!errors?.full_name}
                      helperText={errors?.full_name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-password-input"
                      label="Email"
                      type="email"
                      autoComplete="current-email"
                      fullWidth
                      required
                      {...register("email")}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <PhoneCountryInput
                      countryCode={code}
                      setCountryCode={setCountry}
                    >
                      <Controller
                        name="num"
                        control={controlPhone}
                        render={({ field, fieldState }) => (
                          <TextField
                            {...field}
                            label="Phone Number"
                            type="tel"
                            autoComplete="current-phone"
                            fullWidth
                            required
                            error={!!errors?.phone}
                            helperText={errors?.phone?.message}
                          />
                        )}
                      />
                    </PhoneCountryInput>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-password-input"
                      label="Age"
                      type="number"
                      autoComplete="current-age"
                      fullWidth
                      required
                      {...register("age")}
                      error={!!errors?.age}
                      helperText={errors?.age?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <InputLabel required>Gender</InputLabel>
                          <Select {...field} fullWidth label="Gender">
                            {genderLabel.map((el) => (
                              <MenuItem key={el?.value} value={el.value}>
                                {el?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {/* <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value}
                      disablePortal
                      options={genderLabel}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      sx={{ minWidth: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          error={!!errors?.gender}
                          helperText={errors?.gender?.message}
                        />
                      )}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <UploadFile
                      name="profile_pic"
                      imageOnly={true}
                      title="Upload your Profile Pic"
                      style={{ marginTop: "0px" }}
                    />
                    {errors && (
                      <h6 className="m-top" style={{ color: "#d32f2f" }}>
                        {errors?.profile_pic?.message}
                      </h6>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="outlined-password-input"
                      label="Citizenship no."
                      type="text"
                      autoComplete="current-citizenship"
                      fullWidth
                      required
                      {...register("citizenship_no")}
                      error={!!errors?.citizenship_no}
                      helperText={errors?.citizenship_no?.message}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <UploadFile
                      name="citizenship"
                      imageOnly={true}
                      title="Upload your Citizenship"
                      style={{ marginTop: "0px" }}
                    />
                    {errors && (
                      <h6 className="m-top" style={{ color: "#d32f2f" }}>
                        {errors?.citizenship?.message}
                      </h6>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <UploadFile name="cv" title="Upload your CV" />
                    {errors && (
                      <h6 className="m-top" style={{ color: "#d32f2f" }}>
                        {errors?.cv?.message}
                      </h6>
                    )}
                  </Grid>
                </Grid>

                <AddressComponent
                  hookForm={hookForm}
                  isReadOnly={false}
                  viewFor={data?.id ? "update" : "create"}
                />

                <Box className="box-gap flex-center" marginTop="20px">
                  <CustomButton
                    isSubmit={true}
                    text="+ Create Business Profile"
                    type="filled"
                    loading={sending}
                  />
                </Box>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      {/* <div id="load-more" className="post-loader">
        <div className="loader-icon">
          <i className="icon-theme iw-25 ih-25" data-feather="rotate-ccw"></i>
        </div>
        <div className="no-more-text">
          <p>no more post</p>
        </div>
      </div> */}
    </div>
  );
}
