import NotAllowed from "components/auth/NotAllowed";
import RedirectToLogin from "components/auth/RedirectToLogin";
import { CLIENT_ID, IDENTITY_SERVER_URL } from "constants/api-constants";
import useLoginState from "hooks/useLoginState";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "redux/features/login/LoginSlice";
import Loader from "../components/Loader";

const AdminGuardComponent = ({ component }: { component: React.ReactNode }) => {
  const { isLoggedIn, isAdmin } = useLoginState();
  const dispatch = useDispatch();

  const logoutUserAndRedirect = () => {
    dispatch(logoutUser());
    const redirectUrl =
      IDENTITY_SERVER_URL +
      "?callback_url=" +
      process.env.REACT_APP_CALLBACK_URL +
      "&client_id=" +
      CLIENT_ID;

    window.location.href = redirectUrl;
  };

  if (isLoggedIn && !isAdmin) {
    return (
      <NotAllowed
        description="Hey! Sorry but you are not allowed here. Login as Admin to access this portal."
        message="*Warning, This will log you out from your current user state."
        onClick={logoutUserAndRedirect}
      />
    );
  }

  if (isLoggedIn && isAdmin) {
    return <Suspense fallback={<Loader />}>{component}</Suspense>;
  }

  return <RedirectToLogin />;
};

export default AdminGuardComponent;
