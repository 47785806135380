import React from "react";

export { default as CallbackHandler } from "pages/auth/CallbackHandler";
export { default as AccessRequestTabs } from "pages/dashboard/admin/access-request/AccessRequestTabs";
export { default as AgreementsTabs } from "pages/dashboard/admin/agreements/AgreementsTab";
export { default as ConnectTabs } from "pages/dashboard/admin/connect/ConnectTabs";
export { default as UserConnectTabs } from "pages/dashboard/admin/connect/user-connect-table/UserConnectTabs";
export { default as InvestorScreeningDetail } from "pages/dashboard/admin/investor-screening/detail/InvestorScreeningDetail";
export { default as InvestorScreeningTabs } from "pages/dashboard/admin/investor-screening/InvestorScreeningTabs";
export { default as MeetingTab } from "pages/dashboard/admin/meeting-schedular/MeetingTab";
export { default as ProposalTabs } from "pages/dashboard/admin/proposal/ProposalTabs";
export { default as ViewIdeaHolderDetail } from "pages/dashboard/admin/proposal/ViewIdeaHolderDetail";
export { default as Detail } from "pages/dashboard/admin/screening-request/detail/Detail";
export { default as ScreeningReqTabs } from "pages/dashboard/admin/screening-request/ScreeningReqTabs";
export { default as StatusTabs } from "pages/dashboard/admin/status/StatusTabs";
export { default as UserDetailTabs } from "pages/dashboard/admin/user-detail/UserDetailTabs";
export { default as UserDetailView } from "pages/dashboard/admin/user-detail/UserDetailView";
export { default as IdeaholderAccessRequestTabs } from "pages/dashboard/idea/access-request/IdeaholderAccessRequestTabs";
// export { default as CreateIdea } from "pages/dashboard/idea/create-idea/CreateIdea";
export { default as Sale } from "pages/dashboard/idea/business/BusinessSaleForm";
export { default as BusinessForm } from "pages/dashboard/idea/business/BusinessForm";
export { default as UpdateBusinessForm } from "pages/dashboard/idea/business/UpdateBusinessForm";
// export { default as UpdateIdea } from "pages/dashboard/idea/create-idea/UpdateIdea";
export { default as IdeaDetail } from "pages/dashboard/idea/detail/IdeaDetail";
export { default as IdeaFeedDetail } from "pages/dashboard/idea/feed-detail/IdeaFeedDetail";
export { default as SaleFeedDetail } from "pages/dashboard/idea/feed-detail/SaleFeedDetail";
export { default as MyIdeasTabs } from "pages/dashboard/idea/my-ideas/MyIdeasTabs";
export { default as ViewIdea } from "pages/dashboard/idea/my-ideas/ViewIdea";
export { default as IdeaProposalsTabs } from "pages/dashboard/idea/proposals/ProposalsTabs";
export { default as InvestorAccessRequestTabs } from "pages/dashboard/investor/access-request/InvestorAccessRequestTabs";
export { default as InvestorDetailMain } from "pages/dashboard/investor/detail/InvestorDetail";
export { default as ViewInvestorDetail } from "pages/dashboard/investor/detail/ViewInvestorDetail";
export { default as InvestorFeedDetails } from "pages/dashboard/investor/feed-details/InvestorFeedDetails";
export { default as InvestorProposalsTabs } from "pages/dashboard/investor/proposals/ProposalsTabs";
export { default as Matrix } from "pages/dashboard/matrix/Matrix";
export { default as Notification } from "pages/dashboard/notification/Notification";
export { default as ProfileMain } from "pages/dashboard/profile/ProfileMain";
export { default as CenterContent } from "pages/main/componnets/contents/center-content/CenterContent";
export { default as IdeaProfileForm } from "pages/main/componnets/contents/center-content/components/profile/idea/IdeaProfileForm";
export { default as InvestorProfileForm } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
export { default as LeftContent } from "pages/main/componnets/contents/left-content/LeftContent";

export const Landing = React.lazy(() => import("../pages/landing/LandingPage"));
export const Mainpage = React.lazy(() => import("pages/main/MainPage"));
export const Dashboard = React.lazy(() => import("pages/dashboard/Dashboard"));
export const LandingPageold = React.lazy(
  () => import("pages/landing/LandingPageold")
);
export const Login = React.lazy(() => import("../pages/auth/Login"));
export const Register = React.lazy(() => import("../pages/auth/Register"));

export const FeedPage = React.lazy(() => import("pages/main/FeedPage"));
