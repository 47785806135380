import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

export default function useInvestorState() {
  const investor = useSelector((state: RootState) => state?.investor);

  const hasInvestorProfile =
    investor?.status === "loaded" &&
    investor?.data &&
    Object.keys(investor?.data)?.length > 0;

  return {
    status: investor?.status ?? "none",
    investorProfile: investor?.data ?? null,
    hasInvestorProfile: !!hasInvestorProfile,
  };
}
