import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { TiTick } from "react-icons/ti";
import CheckIcon from "@mui/icons-material/Check";
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  { field: "SN", headerName: "S.N", width: 40 },
  { field: "IdeaHolder", headerName: "Idea Holder", width: 200 },
  { field: "Idea", headerName: "Idea", width: 180 },
  {
    field: "Date",
    headerName: "Date",
    width: 100,
  },
  {
    field: "Investment",
    headerName: "Investment",
    width: 140,
  },
  {
    field: "Location",
    headerName: "Location",
    width: 100,
  },
  {
    field: "Status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <CheckIcon />
        </Box>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    SN: 1,
    IdeaHolder: "Pizza Hut",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 2,
    SN: 2,
    IdeaHolder: "Nobel Alu Shop",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 3,
    SN: 3,
    IdeaHolder: "Hospital and Innovation",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 4,
    SN: 4,
    IdeaHolder: "Tv show",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 5,
    SN: 5,
    IdeaHolder: "Himalayan Show",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 6,
    SN: 6,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 7,
    SN: 7,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 8,
    SN: 8,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 9,
    SN: 9,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 10,
    SN: 10,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
  {
    id: 11,
    SN: 11,
    IdeaHolder: "Snow",
    Idea: "Momo stall",
    Date: "2022/10/10",
    Investment: "12000",
    Location: "Kathmandu",
    Action: "Start Screening",
  },
];

export default function ScreeningTable() {
  return (
    <Link to="/admin/screening-request/detail">
      <div style={{ height: 390, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          // onRowClick={handleClick}
          // checkboxSelection
        />
      </div>
    </Link>
  );
}
