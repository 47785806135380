import AdminGuardComponent from "guards/AdminGuardComponent";
import AuthGuardComponent from "guards/AuthGuardComponent";
import IdeaGuardComponent from "guards/IdeaGuardComponent";
import InvestorGuardComponent from "guards/InvestorGuardComponent";
import * as pages from "pages";
import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "../components/404NotFound";
import Loader from "../components/Loader";

export default function Router() {
  return useRoutes([
    {
      path: "/login",
      element: <SuspenseComponent component={<pages.Login />} />,
    },
    {
      path: "/callback",
      element: <SuspenseComponent component={<pages.CallbackHandler />} />,
    },
    {
      path: "/register",
      element: <SuspenseComponent component={<pages.Register />} />,
    },
    {
      path: "/",
      element: <SuspenseComponent component={<pages.Landing />} />,
    },
    {
      path: "/landing",
      element: <SuspenseComponent component={<pages.LandingPageold />} />,
    },
    {
      element: <AuthGuardComponent component={<pages.Mainpage />} />,
      children: [
        {
          path: "/idea",
          element: <SuspenseComponent component={<pages.FeedPage />} />,
          children: [
            {
              path: "/idea",
              element: <pages.LeftContent />,
              children: [
                {
                  index: true,
                  element: <pages.CenterContent />,
                },
                {
                  path: "/idea/detail/:id",
                  element: <pages.IdeaFeedDetail />,
                },
                {
                  path: "/idea/sale-detail/:id",
                  element: <pages.SaleFeedDetail />,
                },
              ],
            },
            {
              path: "/idea/user-profile",
              element: <pages.ProfileMain />,
            },
            {
              path: "/idea/notification",
              element: <pages.Notification />,
            },
            {
              path: "/idea/profile",
              element: <pages.IdeaProfileForm />,
            },
            {
              path: "/idea/profile/detail",
              element: <pages.IdeaDetail />,
            },
            {
              path: "/idea/my-ideas",
              element: <IdeaGuardComponent component={<pages.MyIdeasTabs />} />,
            },
            {
              path: "/idea/access-request",
              element: (
                <IdeaGuardComponent
                  component={<pages.IdeaholderAccessRequestTabs />}
                />
              ),
            },
            {
              path: "/idea/proposals",
              element: (
                <IdeaGuardComponent component={<pages.IdeaProposalsTabs />} />
              ),
            },
            // {
            //   path: "/idea/create-idea",
            //   element: <pages.CreateIdea />,
            // },
            {
              path: "/idea/details/:id",
              element: <pages.Detail />,
            },
            {
              path: "/idea/view-detail/:id",
              element: <pages.ViewIdea />,
            },
            // {
            //   path: "/idea/update-idea/:id",
            //   element: <pages.UpdateIdea />,
            // },
            {
              path: "/idea/investor/details/:id",
              element: <pages.ViewInvestorDetail />,
            },
            {
              path: "/idea/business",
              element: <pages.BusinessForm />,
            },
            {
              path: "/idea/business/:id",
              element: <pages.UpdateBusinessForm />,
            },
            {
              path: "/idea/connect",
              element: <pages.UserConnectTabs />,
            },
          ],
        },
        {
          path: "/investor",
          element: <SuspenseComponent component={<pages.FeedPage />} />,
          children: [
            {
              path: "/investor",
              element: <pages.LeftContent />,
              children: [
                {
                  index: true,
                  element: <pages.CenterContent />,
                },
                {
                  path: "/investor/detail/:id",
                  element: <pages.InvestorFeedDetails />,
                },
              ],
            },
            {
              path: "/investor/user-profile",
              element: <pages.ProfileMain />,
            },
            {
              path: "/investor/notification",
              element: <pages.Notification />,
            },
            {
              path: "/investor/profile",
              element: <pages.InvestorProfileForm />,
            },
            {
              path: "/investor/detail",
              element: (
                <InvestorGuardComponent
                  component={<pages.InvestorDetailMain />}
                />
              ),
            },
            {
              path: "/investor/access-request",
              element: (
                <InvestorGuardComponent
                  component={<pages.InvestorAccessRequestTabs />}
                />
              ),
            },
            {
              path: "/investor/proposals",
              element: (
                <InvestorGuardComponent
                  component={<pages.InvestorProposalsTabs />}
                />
              ),
            },
            {
              path: "/investor/idea-holder/:id",
              element: <pages.ViewIdeaHolderDetail />,
            },
            {
              path: "/investor/connect",
              element: <pages.UserConnectTabs />,
            },
          ],
        },
      ],
    },
    {
      element: <AdminGuardComponent component={<pages.Mainpage />} />,
      children: [
        {
          path: "/admin",
          element: <AdminGuardComponent component={<pages.Dashboard />} />,
          children: [
            {
              path: "/admin",
              element: <pages.LeftContent />,
              children: [
                {
                  index: true,
                  element: <pages.Matrix />,
                },
              ],
            },

            {
              path: "/admin/user-detail",
              element: <pages.ProfileMain />,
            },
            {
              path: "/admin/screening-request",
              element: <pages.ScreeningReqTabs />,
            },
            {
              path: "/admin/screening-request/detail/:id",
              element: <pages.Detail />,
            },
            {
              path: "/admin/proposals",
              element: <pages.ProposalTabs />,
            },
            {
              path: "/admin/status",
              element: <pages.StatusTabs />,
            },
            {
              path: "/admin/agreements",
              element: <pages.AgreementsTabs />,
            },
            {
              path: "/admin/meeting-scheduler",
              element: <pages.MeetingTab />,
            },
            {
              path: "/admin/connect",
              element: <pages.ConnectTabs />,
            },
            {
              path: "/admin/investor-screening",
              element: <pages.InvestorScreeningTabs />,
            },
            {
              path: "/admin/investor-screening/detail/:id",
              element: <pages.InvestorScreeningDetail />,
            },
            {
              path: "/admin/user",
              element: <pages.UserDetailTabs />,
            },
            {
              path: "/admin/user/:id",
              element: <pages.UserDetailView />,
            },
            {
              path: "/admin/access-request",
              element: <pages.AccessRequestTabs />,
            },
            {
              path: "/admin/user-profile",
              element: <pages.ProfileMain />,
            },
            {
              path: "/admin/notification",
              element: <pages.Notification />,
            },
            {
              path: "/admin/idea-holder/:id",
              element: <pages.ViewIdeaHolderDetail />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
}

const SuspenseComponent = ({ component }: { component: React.ReactNode }) => (
  <Suspense fallback={<Loader />}>{component}</Suspense>
);
