import { Box, Checkbox, FormControlLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { AxiosError } from "axios";
import { IArea, IStreet } from "components/AddressComponent";
import useInvestorState from "hooks/useInvestorState";
import { CountryCodeType, defaultCode } from "hooks/usePhoneValidator";
import { IResponseMessageType } from "models/ResponseMessage";
import { IButtonStatus } from "pages/dashboard/investor/feed-details/InvestorFeedDetails";
import React, { useEffect, useState } from "react";
import { CiGlobe, CiLock } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { investorDetailRequest } from "redux/features/investor/investorSlice";
import { IMunicipality } from "redux/features/location/muniSlice";
import { createInvestor, updateInvestor } from "services/InvestorService";
import { countries } from "services/json/countries";
import { getDefaultCountryService } from "services/userService";
import IndividualInvestor from "./IndividualInvestor";
import InstitutionalInvestor from "./InstitutionalInvestor";

type IStatus = "unknown" | "success" | "error";

const InvestorTypes = [
  {
    label: "Individual" as InvestorType,
    value: "Individual" as InvestorType,
    icon: <CiLock />,
  },
  {
    label: "Institutional" as InvestorType,
    value: "Institutional" as InvestorType,
    icon: <CiGlobe />,
  },
];

export type InvestorType = "Institutional" | "Individual";

export interface IInvestorSector {
  created_at: string;
  deleted_at: string | null;
  description: string;
  id: number;
  name: string;
  status: number;
  updated_at: string;
}

export interface IInvestorFileType {
  id: number;
  filename: string;
  path: string;
  investor_type: number;
}

export interface IInvestorCommonType {
  id?: number;
  user_id?: number;
  view_count?: number;
  like_count?: number;
  files?: IInvestorFileType[];
  favourite_count?: number;
  investment_sectors?: IInvestorSector[];
  investor_profile: InvestorType; // discriminator
  interest_in_buying_business: string | number;
  email: string;
  phone: string | number;
  permanent_location_area: IArea | null;
  permanent_location_street: IStreet | null;
  permanent_location_municipality: IMunicipality | null;
  temporary_location_area: IArea | null;
  temporary_location_street: IStreet | null;
  temporary_location_municipality: IMunicipality | null;
  investment_preferred_location: string;
  investor_detail: string;
  investor_interests: string;
  max_amount: string | number;
  min_amount: string | number;
  remarks: string;
  title_designation: string;
  status?: string;
  status_reason?: string;
  has_favourite?: number;
  has_followed?: number;
  has_liked?: number;
  proposal?: {
    status: IButtonStatus;
  }[];
  // connect?: {
  //   status: "Pending" | "Accepted";
  // }[];
  connect_status?: "Pending" | "Accepted" | "Rejected" | "";
  active?: string;
}

export interface IInstitutionalType extends IInvestorCommonType {
  investor_profile: "Institutional";
  full_name: string;
  pan: File | null;
  ocr: File | null;
  company_seal: File | null;
}

export interface IIndividualType extends IInvestorCommonType {
  investor_profile: "Individual";
  full_name: string;
  age: string | number;
  gender: string | null;
  citizenship: File | null;
  cv: File | null;
  profile_pic: File | null;
}

export const InvestorCategoriesList = [
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "IT",
    id: 1,
    name: "IT",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Agriculture",
    id: 2,
    name: "Agriculture",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Building, Construction and Maintenance",
    id: 3,
    name: "Building, Construction and Maintenance",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Business Services",
    id: 4,
    name: "Business Service",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Education",
    id: 5,
    name: "Education",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Energy",
    id: 6,
    name: "Energy",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Finance",
    id: 7,
    name: "Finance",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Food & Beverage",
    id: 8,
    name: "Food & Beverage",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Healthcare",
    id: 9,
    name: "Healthcare",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Industrial",
    id: 10,
    name: "Industrial",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Logistics",
    id: 11,
    name: "Logistics",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Media",
    id: 12,
    name: "Media",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Retail Shops",
    id: 13,
    name: "Retail Shops",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Technology",
    id: 14,
    name: "Technology",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Textiles",
    id: 15,
    name: "Textiles",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Travel & Leisure",
    id: 16,
    name: "Travel & Leisure",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
];

export type IInvestorType = IInstitutionalType | IIndividualType;

export default function InvestorProfileForm() {
  const login = useSelector(
    (state: RootState) => state?.login?.data?.userDetails
  );
  // Phone
  const [defaultCountryCode, setDefaultCountryCode] =
    useState<CountryCodeType>(defaultCode);
  // End Phone
  const [sending, setSending] = useState(false);
  const [select, setSelect] = React.useState<InvestorType>("Institutional");
  const [isChecked, setIsChecked] = React.useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const investorData = useSelector((state: RootState) => state.investor.data);

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as InvestorType);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  // Phone
  const getDefaultCountry = async () => {
    const res = await getDefaultCountryService();
    if (res?.status === 200) {
      const country = countries.find((x) => x.code === res?.data?.country_code);
      setDefaultCountryCode({
        code: country?.code ?? "",
        label: country?.label ?? "",
        phone: country?.phone ?? "",
      });
    }
  };

  useEffect(() => {
    getDefaultCountry();
  }, []);

  // End Phone

  useEffect(() => {
    if (investorData) setSelect(investorData?.investor_profile);
  }, [investorData]);

  useEffect(() => {
    if (investorData) {
      if (investorData?.interest_in_buying_business === "1") {
        setIsChecked(true);
      }
    }
  }, [investorData]);

  const onSubmit = async (data: IInvestorType) => {
    let mainData: IInvestorType;

    const fd = new FormData();
    if (data?.investor_profile === "Institutional") {
      mainData = data as IInstitutionalType;
      fd.append("full_name", mainData?.full_name);
      fd.append("pan", mainData?.pan as Blob);
      fd.append("ocr", mainData?.ocr as Blob);
      fd.append("company_seal", mainData?.company_seal as Blob);
    }

    if (data?.investor_profile === "Individual") {
      mainData = data as IIndividualType;
      fd.append("full_name", mainData?.full_name);
      fd.append("age", mainData?.age?.toString());
      fd.append("gender", mainData?.gender ?? "");
      fd.append("citizenship", mainData?.citizenship as Blob);
      fd.append("cv", mainData?.cv as Blob);
      fd.append("profile_pic", mainData?.profile_pic as Blob);
    }

    fd.append("email", data?.email);
    fd.append("phone", data?.phone?.toString());
    fd.append("investor_profile", data?.investor_profile?.toLowerCase());
    fd.append("interest_in_buying_business", isChecked ? "1" : "0");
    fd.append(
      "temporary_location_area",
      data?.temporary_location_area?.area_name ?? ""
    );
    fd.append(
      "temporary_location_street",
      data?.temporary_location_street?.street_name ?? ""
    );
    fd.append(
      "temporary_location_municipality",
      data?.temporary_location_municipality?.name ?? ""
    );
    fd.append(
      "permanent_location_area",
      data?.permanent_location_area?.area_name ?? ""
    );
    fd.append(
      "permanent_location_street",
      data?.permanent_location_street?.street_name ?? ""
    );
    fd.append(
      "permanent_location_municipality",
      data?.permanent_location_municipality?.name ?? ""
    );

    data?.investment_sectors?.forEach((sector) => {
      fd.append("sectors[]", sector?.id?.toString());
    });

    // fd.append("sectors", data?.investment_sectors);
    fd.append(
      "investment_preferred_location",
      data?.investment_preferred_location
    );
    fd.append("investor_detail", data?.investor_detail);
    fd.append("investor_interests", data?.investor_interests);
    fd.append("max_amount", data?.max_amount?.toString());
    fd.append("min_amount", data?.min_amount?.toString());
    fd.append("remarks", data?.remarks?.toString());
    fd.append("title_designation", data?.title_designation);

    setSending(true);

    try {
      if (investorData && investorData?.id) {
        await updateInvestor(investorData?.id, fd);
        toast("Investor Profile Updated Successfully!", {
          type: "success",
        });
      } else {
        await createInvestor(fd);
        toast("Investor Profile Created Successfully!", {
          type: "success",
        });
      }
      dispatch(investorDetailRequest());
      navigate("/investor/detail");

      setSending(false);
    } catch (e) {
      const error = e as AxiosError<IResponseMessageType>;
      let message = error.response?.data?.message || "Some error";
      if (Array.isArray(message)) {
        message = message.join(", ");
      }
      setSending(false);
      toast(message, {
        type: "error",
      });
    }
  };

  const { status } = useInvestorState();

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="top-form">
          <h3>{investorData ? "Update" : "Create"} Investor Profile</h3>
          {investorData ? (
            <h6>Change details below to update your investor profile</h6>
          ) : (
            <h6>Fill in the details below to create an investor profile</h6>
          )}
        </div>
        <div>
          <Grid container marginTop="20px" spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="select-investor-type-label">
                  Type of Investor
                </InputLabel>
                <Select
                  labelId="select-investor-type-label"
                  id="select-investor-type"
                  value={select}
                  label="Type of Investor"
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    },
                  }}
                  disabled={!!investorData}
                >
                  {InvestorTypes.map((el) => (
                    <MenuItem
                      key={el?.value}
                      value={el.value}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box component="span" display="flex">
                        {el?.icon}
                      </Box>
                      <span className="dropdown-text">{el?.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                }
                label="Interest in Buying Business"
              />
            </Grid>
          </Grid>
        </div>
        {select === "Individual" && status !== "loading" && (
          <IndividualInvestor
            defaultCountryCode={defaultCountryCode}
            data={investorData as IIndividualType}
            onSubmit={onSubmit}
            loading={sending}
            viewFor="view"
          />
        )}
        {select === "Institutional" && status !== "loading" && (
          <InstitutionalInvestor
            defaultCountryCode={defaultCountryCode}
            data={investorData as IInstitutionalType}
            onSubmit={onSubmit}
            loading={sending}
            viewFor="view"
          />
        )}
      </div>
    </div>
  );
}
