import { IInvestorStatusType } from "pages/dashboard/admin/investor-screening/InvestorScreeningTabs";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import httpClient from "./httpClient";

export const createInvestor = async (data: FormData) => {
  return await httpClient.post("/investor/create", data);
};

export const updateInvestor = async (id: number, data: FormData) => {
  return await httpClient.put(`/investor/${id}/update`, data);
};

export const getAllInvestor = async (status: IInvestorStatusType | null, active?: string) => {
  return await httpClient.get("/investor", {
    params: {
      status,
      active
    },
  });
};

export const getInvestorDetail = async () => {
  return await httpClient.get<IInvestorType>(`/investor/user`);
};

export const getMyInvestorProfile = async () => {
  return await httpClient.get<IInvestorType>(`/investor/me`);
};

export const getInvestor = async (id: number) => {
  return await httpClient.get(`/investor/${id}`);
};

export const updateStatus = async (
  id: number,
  payload: {
    status: string;
    resubmit_reason: string;
  }
) => {
  return await httpClient.put(`/investor/${id}/update-status`, payload);
};

export const investorDeactivationStatus = async (
  id: number,
  payload: {
    active: string;
  }
) => {
  return await httpClient.put(`/investor/${id}/update-active`, payload);
};

export const getProposalRequestAll = async (status: string | null) => {
  return await httpClient.get(`/proposal`, {
    params: {
      status,
    },
  });
};

export const getProposalRequestIdeaHolder = async (id: number | undefined) => {
  return await httpClient.get(`proposal/ideaholder_id/${id}`);
};

export const getProposalRequestInvestor = async (
  id: number | undefined,
  status: string | null
) => {
  let url = `proposal/investor_id/${id}`;
  if (status) {
    url += `?status=${status}`;
  }
  return await httpClient.get(url);
};

export const deleteProposal = async (id: number | undefined) => {
  return await httpClient.delete(`proposal/${id}/delete`);
};

export const updateProposal = async (
  id: number | undefined,
  payload: {
    ideaholder_id: number | undefined;
    idea_id: number;
    investor_id: number;
    status: string;
  }
) => {
  return await httpClient.put(`proposal/${id}/update`, payload);
};

export const postLikeByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  liked_by: number | undefined;
}) => {
  return await httpClient.post("/investor-like/like", payload);
};

export const postDisLikeByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  liked_by: number | undefined;
}) => {
  return await httpClient.post<any>("/investor-like/unlike", payload as any);
};

export const postFavouriteByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  favorite_by: number | undefined;
}) => {
  return await httpClient.post("/investor-favourites/favorite", payload);
};

export const postUnfavouiteByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  favorite_by: number | undefined;
}) => {
  return await httpClient.post<any>("/investor-favourites/unfavorite", payload);
};

export const followByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  follower_user_id: number | undefined;
}) => {
  return await httpClient.post("/investor-follow/follow", payload);
};

export const unfollowByIdeaHolder = async (payload: {
  investor_id: number | undefined;
  follower_user_id: number | undefined;
}) => {
  return await httpClient.post("/investor-follow/unfollow", payload);
};

// export const changeInvestorFile = async (
//   id: number | null,
//   file: FormData,
//   investorId: number | undefined
// ) => {
//   return await httpClient.put(
//     `/investor/${investorId}/update-file?file_id=${id}`,
//     file
//   );
// };

export const changeInvestorFile = async (id: number | null, file: FormData) => {
  return await httpClient.put(`/investor/${id}/update-file`, file);
};
export const getInvestorAnalytics = async () => {
  return await httpClient.get("/investor/analytics");
};
