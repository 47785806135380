import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { investorAllDataRequest } from "redux/features/investor/investorAllSlice";
import InvestorScreeningTable from "./InvestorScreeningTable";
import { getAllInvestor } from "services/InvestorService";
import { IDataType } from "redux/features/connect/connectAllSlice";
import { toast } from "react-toastify";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export type IInvestorStatusType =
  | "all"
  | "accepted"
  | "rejected"
  | "pending"
  | "re-review"
  | "resubmit";

export default function InvestorScreeningTabs() {
  const [value, setValue] = React.useState<IInvestorStatusType>("all");
  const [isFetched, setIsFetched] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      investorAllDataRequest({
        status: value === "all" ? null : value,
      })
    );
  }, [dispatch, isFetched, value]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IInvestorStatusType
  ) => {
    setIsFetched(false);
    setValue(newValue);
  };
  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    const res = await getAllInvestor(null)
    if (res?.status === 200 || res.status === 201) {
      setAllData(res?.data);
    } else {
      toast("Something went wrong");
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [value]);

  const acceptedCount = allData.filter((data: IDataType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IDataType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IDataType) => data.status === "Pending").length
  const reSubmitCount = allData.filter((data: IDataType) => data.status === "Resubmit").length
  const reReviewCount = allData.filter((data: IDataType) => data.status === "Re-review").length

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Investor Screening Requests</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"All" + ` (${allData.length})`}
                value="all"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Pending" + ` (${pendingCount})`}
                value="pending"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Resubmit" + ` (${reSubmitCount})`}
                value="resubmit"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Re-Review" + ` (${reReviewCount})`}
                value="re-review"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Accepted" + ` (${acceptedCount})`}
                value="accepted"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Rejected" + ` (${rejectedCount})`}
                value="rejected"
              />
            </TabList>
          </Box>
          <TabPanel value="all">
            <InvestorScreeningTable />
          </TabPanel>
          <TabPanel value="pending">
            <InvestorScreeningTable />
          </TabPanel>
          <TabPanel value="resubmit">
            <InvestorScreeningTable />
          </TabPanel>
          <TabPanel value="re-review">
            <InvestorScreeningTable />
          </TabPanel>
          <TabPanel value="accepted">
            <InvestorScreeningTable />
          </TabPanel>
          <TabPanel value="rejected">
            <InvestorScreeningTable />
          </TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
