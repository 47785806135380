import IdeaDetailCard from "components/IdeaDetailCard";
import MoreIdeaDetailCard from "components/MoreIdeaDetailCard";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useParams } from "react-router-dom";
import { getIdeaholder } from "services/ideaholderService";

export default function ViewIdeaHolderDetail() {
  const [ideaholder, setIdeaHolder] = useState<IIdeaholderTypeResponse | null>(
    null
  );
  const [loading, setLoading] = useState(false);

  const { id } = useParams<{
    id: string;
  }>();

  const getIdeaDetail = async () => {
    if (id) {
      setLoading(true);
      const res = await getIdeaholder(+id);
      if (res?.data) {
        setIdeaHolder(res?.data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getIdeaDetail();
  }, [id]);

  return (
    <>
      <div
        className="content-left col-4 res-full-width order-1"
        style={{ width: "fit-content", maxWidth: "380px" }}
      >
        <div
          className="profile-about sticky-top"
          style={{ position: "sticky", top: "80px" }}
        >
          <IdeaDetailCard details={ideaholder} />

          {/* <div className="about-footer">
            <ul>
              <li className="fb">
                <a href="https://www.facebook.com">
                  <Icon.Facebook />
                </a>
              </li>
              <li className="tw">
                <a href="https://www.twitter.com">
                  <Icon.Twitter />
                </a>
              </li>
              <li className="wa">
                <a href="https://www.linkedin.com">
                  <Icon.Linkedin />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div
        className="content-center col-xl-8"
        style={{ width: "calc(100% - 380px)" }}
      >
        <MoreIdeaDetailCard data={ideaholder} hideDeactivate={true} />
      </div>
    </>
  );
}
