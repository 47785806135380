export const PRIMARY_COLOR = "#0389c9";
export const SECONDARY_COLOR = "#e84118";
export const TERTIARY_COLOR = "#4cd137";

export const STATUS_COLOR: {
  [key: string]: string;
} = {
  new: "#45aaf2",
  pending: "#45aaf2",
  "re-review": "#e9af27",
  resubmit: "#e9af27",
  accepted: "#35d735",
  rejected: "#ff5e57",
};
