import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { GenericState } from "redux/app/genericState";

interface IdeaholderState
  extends GenericState<IIdeaholderTypeResponse | null> {}

const initialState: IdeaholderState = {
  data: null,
  error: undefined,
  status: "none",
};

export const ideaholderDataRequest = createAction<{
  id: number;
}>("IDEAHOLDER_DATA_REQUEST");

export const ideaholderDetailRequest = createAction(
  "IDEAHOLDER_DETAIL_REQUEST"
);

const ideaholderSlice = createSlice({
  name: "ideaholder",
  initialState,
  reducers: {
    get_ideaholder_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_ideaholder_data: (
      state,
      action: PayloadAction<IIdeaholderTypeResponse>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_ideaholder_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default ideaholderSlice;
