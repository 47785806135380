import { Box, Checkbox, FormControlLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CustomButton from "components/CustomButton";
import GoBackButton from "components/GoBackButton";
import Loader from "components/Loader";
import IndividualInvestor from "pages/main/componnets/contents/center-content/components/profile/investor/IndividualInvestor";
import InstitutionalInvestor from "pages/main/componnets/contents/center-content/components/profile/investor/InstitutionalInvestor";
import {
  IIndividualType,
  IInstitutionalType,
  IInvestorType,
} from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import React, { useEffect, useState } from "react";
import { CiGlobe, CiLock } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { getInvestor } from "services/InvestorService";
import ApproveModal from "./modal/ApproveModal";
import RejectModal from "./modal/RejectModal";
import ReviewModal from "./modal/ReviewModal";

const InvestorTypes = [
  {
    label: "Individual",
    value: "Individual",
    icon: <CiLock />,
  },
  {
    label: "Institutional",
    value: "Institutional",
    icon: <CiGlobe />,
  },
];

type IInvestorTypes = "Institutional" | "Individual";

export default function InvestorScreeningDetail() {
  const [open, setOpen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [screeningData, setScreeningData] = useState<IInvestorType | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = React.useState<IInvestorTypes>("Institutional");
  const [isChecked, setIsChecked] = React.useState(false);

  const handleShowStatus = () => {
    setShowStatus(!showStatus);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleOpenReview = () => setOpenReview(true);
  const handleCloseReview = () => setOpenReview(false);

  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as IInvestorTypes);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const params = useParams<{
    id: string;
  }>();

  const getInvestorDetails = async () => {
    if (params?.id) {
      setLoading(true);
      const res = await getInvestor(+params?.id);
      if (res?.status === 200 && res?.data) {
        const data: IInvestorType = {
          ...res?.data,
          permanent_location_municipality: {
            id: null,
            name: res.data?.permanent_location_municipality,
          },
          permanent_location_street: {
            id: null,
            street_name: res.data?.permanent_location_street,
          },
          permanent_location_area: {
            id: null,
            area_name: res.data?.permanent_location_area,
          },
          temporary_location_municipality: {
            id: null,
            name: res.data?.temporary_location_municipality,
          },
          temporary_location_street: {
            id: null,
            street_name: res.data?.temporary_location_street,
          },
          temporary_location_area: {
            id: null,
            area_name: res.data?.temporary_location_area,
          },
        };
        setSelect(data?.investor_profile);
        setScreeningData(data);
        setLoading(false);
        if (data?.interest_in_buying_business === "1") {
          setIsChecked(true);
        }
      }
    }
  };

  useEffect(() => {
    getInvestorDetails();
  }, [params]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>

      {/* next part */}
      <div className="idea-form">
        <div className="title-main flex-space-between m-top-0 m-bottom-0">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GoBackButton text="Go Back" />
            <span>Investor Details</span>
          </div>
          {/* <span>
            {!showStatus ? (
              // <CustomButton
              //   text="Start Screening"
              //   icon={<VscDebugStart />}
              //   type="filled"
              //   onClick={handleShowStatus}
              // />
              ""
            ) : (
              <span className="status-ongoing">
                <GiSandsOfTime />
                Ongoing Screening
              </span>
            )}
          </span> */}
        </div>
        <div>
          <FormControl
            sx={{ margin: "25px 0 0 0", minWidth: "50%", paddingRight: "8px" }}
          >
            <InputLabel id="select-investor-type-label">
              Type of Investor
            </InputLabel>
            <Select
              labelId="select-investor-type-label"
              id="select-investor-type"
              value={select}
              label="Type of Investor"
              onChange={handleChange}
              style={{ width: "100%" }}
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                },
              }}
              disabled={!!screeningData}
            >
              {InvestorTypes.map((el) => (
                <MenuItem
                  value={el.value}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box component="span" display="flex">
                    {el?.icon}
                  </Box>
                  <span className="dropdown-text">{el?.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            sx={{ margin: "25px 0 0 0", minWidth: "50%", paddingRight: "8px" }}
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                color="primary"
                disabled={!!screeningData}
              />
            }
            label="Interest in Buying Business"
          />
        </div>
        {screeningData?.investor_profile === "Individual" && screeningData && (
          <IndividualInvestor
            data={screeningData as IIndividualType}
            isReadOnly={true}
            onSubmit={() => null}
            viewFor="view"
          />
        )}
        {screeningData?.investor_profile === "Institutional" &&
          screeningData && (
            <InstitutionalInvestor
              data={screeningData as IInstitutionalType}
              isReadOnly={true}
              onSubmit={() => null}
              viewFor="view"
            />
          )}
        <div className="box-gap flex-center m-top">
          {(screeningData?.status !== "Accepted" && screeningData?.status !== "Rejected") && (
            !showStatus ? (
              <CustomButton
                text="Start Screening"
                type="filled"
                onClick={handleShowStatus}
              />
            ) : (
              <>
                <CustomButton
                  text="Reject"
                  type="filled"
                  color="#e84118"
                  onClick={handleOpenReject}
                />
                <RejectModal open={openReject} handleClose={handleCloseReject} />
                <CustomButton
                  text="Resubmit"
                  type="filled"
                  onClick={handleOpenReview}
                />
                <ReviewModal open={openReview} handleClose={handleCloseReview} />

                <CustomButton
                  text="Approve"
                  type="filled"
                  color="#4cd137"
                  onClick={handleOpen}
                />

                <ApproveModal open={open} handleClose={handleClose} />
              </>
            )
          )
          }
        </div>
      </div>
    </div>
  );
}
