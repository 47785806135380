import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import UserConnectSentTable from "./UserConnectSentTable";
import { connectMyDataRequest } from "redux/features/connect/connectMySlice";
import UserConnectReceivedTable from "./UserConnectReceivedTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { toast } from "react-toastify";
import { getMyConnects } from "services/connectService";
import { IDataType } from "redux/features/connect/connectAllSlice";
import UserAllConnectsTable from "./UserAllConnectsTable";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export type IConnectType = "Sent" | "Received";

export type IConnectObjectType = {
  received: Array<IDataType>
  sent: Array<IDataType>
}

export default function UserConnectTabs() {
  const [value, setValue] = React.useState("All");
  const [display, setDisplay] = React.useState<IConnectObjectType>(Object);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IConnectType
  ) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(connectMyDataRequest());
  });

  const getConnectRequestService = async () => {
    const res = await getMyConnects();
    if (res?.status === 200 || res.status === 201) {
      setDisplay(res?.data);
    } else {
      toast("Something went wrong");
    }
  };

  const receivedConnects = display.received;
  const sentConnects = display.sent;
  const allConnects = receivedConnects !== undefined && sentConnects !== undefined ? receivedConnects.concat(sentConnects) : [];

  React.useEffect(() => {
    getConnectRequestService();
  }, [value])

  const sentCount = display.sent !== undefined ? display.sent.length : 0;
  const receivedCount = display.received !== undefined ? display.received.length : 0;

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Connects</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"All" + ` (${allConnects.length})`}
                value="All"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Sent" + ` (${sentCount})`}
                value="Sent"
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label={"Received" + ` (${receivedCount})`}
                value="Received"
              />
            </TabList>
          </Box>
          <TabPanel value="All">
            <UserAllConnectsTable
              allConnects={allConnects}
              getConnectRequestService={getConnectRequestService}
            />
          </TabPanel>
          <TabPanel value="Sent">
            <UserConnectSentTable />
          </TabPanel>
          <TabPanel value="Received">
            <UserConnectReceivedTable
              data={display}
              getConnectRequestService={getConnectRequestService}
            />
          </TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
