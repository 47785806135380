import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { PRIMARY_COLOR } from "constants/color-constants";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function ProfileIcon({
  name,
  profile,
  sx,
}: {
  name: string | null;
  profile?: string | null;
  sx?: React.CSSProperties;
}) {
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      variant="dot"
      sx={{
        display: "inline-block",
        height: "100%",
        width: "100%",
      }}
    >
      {profile ? (
        <Avatar
          sx={{
            height: "100%",
            width: "100%",
            color: PRIMARY_COLOR,
            backgroundColor: "rgb(216 242 255 / 54%)",
            ...sx,
          }}
          alt={name ?? ""}
          // src={`${IDENTITY_SERVER_URL}api/image/users/${profile}`}
          src={profile}
        />
      ) : (
        <Avatar
          sx={{
            height: "100%",
            width: "100%",
            color: PRIMARY_COLOR,
            backgroundColor: "rgb(216 242 255 / 54%)",
            ...sx,
          }}
          variant="square"
        >
          {name?.split("")[0]}
        </Avatar>
      )}
    </StyledBadge>
  );
}
