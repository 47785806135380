import { Box } from "@mui/system";
import Navbar from "pages/main/componnets/Navbar";
import Sidebar from "pages/main/componnets/Sidebar";
import { useEffect, createContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { ideaholderDetailRequest } from "redux/features/ideaholder/ideaholderSlice";
import { investorDetailRequest } from "redux/features/investor/investorSlice";
import { municipalityDataRequest } from "redux/features/location/muniSlice";

export type IBusinessTypeProps = {
  businessType: string | null,
  setBusinessType: (newValue: string) => void
}

export const BusinessTypeContext = createContext<IBusinessTypeProps>({
  businessType: null,
  setBusinessType: () => undefined
});

export default function Mainpage() {
  const [businessType, setBusinessType] = useState<string | null>('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(municipalityDataRequest());
    dispatch(investorDetailRequest());
    dispatch(ideaholderDetailRequest());
  }, [dispatch]);
  return (
    <div>
      <BusinessTypeContext.Provider value={{ businessType, setBusinessType }}>
        <Navbar />
        <Box className="page-body container-fluid custom-padding">
          <Box display={"flex"} flexDirection="column" width="100%">
            {/* <MyBreadcrumbs /> */}
            <Outlet />
          </Box>
          <Sidebar />
        </Box>
      </BusinessTypeContext.Provider>
    </div>
  );
}
