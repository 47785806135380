import { CircularProgress } from "@mui/material";
import { alpha, styled } from "@mui/system";
import { PRIMARY_COLOR } from "constants/color-constants";

const Button = styled("button")<{ color: string }>(({ color }) => ({
  border: "unset",
  padding: "7px 20px",
  textTransform: "capitalize",
  fontSize: "calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)))",
  fontWeight: 600,
  borderRadius: "5px",
  minWidth: "110px",
  transition: "all 0.5s ease",
  color: color,
  cursor: "pointer",

  "&.outlined": {
    border: `1px solid ${color}`,
    backgroundColor: "#ffffff",
  },
  "&.outlined:hover": {
    border: `1px solid ${color}`,
    backgroundColor: color,
    color: "#fff",
    boxShadow: `0px 9px 30px 0px ${alpha(color, 0.22)}`,
  },
  "&.filled": {
    border: `1px solid ${color}`,
    backgroundColor: color,
    color: "#fff",
  },
  "&.filled:hover": {
    border: `1px solid ${color}`,
    backgroundColor: "#fff",
    color: color,
    boxShadow: `0px 9px 30px 0px ${alpha(color, 0.22)}`,
  },
  "&.disabled": {
    border: `1px solid grey`,
    backgroundColor: "grey",
    cursor: "not-allowed",
  },
  "&.disabled:hover": {
    border: `1px solid grey`,
    backgroundColor: "grey",
    cursor: "not-allowed",
  },
}));

const ButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
});

export default function CustomButton({
  text,
  type,
  onClick,
  color,
  icon,
  isSubmit,
  loading,
  style,
}: {
  text: string;
  type: "filled" | "outlined";
  onClick?: () => void;
  color?: string;
  isSubmit?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <Button
      color={color ?? PRIMARY_COLOR}
      className={`${type} ${loading && "disabled"}`}
      onClick={() => !loading && onClick?.()}
      type={isSubmit && !loading ? "submit" : "button"}
      style={style}
    >
      <ButtonWrapper>
        {loading ? (
          <CircularProgress
            style={{
              width: "18px",
              height: "18px",
              color: "#fff",
            }}
          />
        ) : (
          <>
            {icon}
            {text}
          </>
        )}
      </ButtonWrapper>
    </Button>
  );
}
