import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { alpha } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import File from "assets/png/file.png";
import Pdf from "assets/png/pdf2.png";
import Image from "assets/png/resume.jpeg";
import { PRIMARY_COLOR } from "constants/color-constants";
import * as React from "react";
import { RxCross1 } from "react-icons/rx";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  margin: "40px 0 0 0 ",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  outline: "unset",
};

const Crossbutton = styled(Box)({
  position: "absolute",
  top: "2px",
  right: "2px",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
  color: "#fff",
  fontSize: "1.4rem",
  padding: "5px",
  transition: "0.3s ease-in-out",
  cursor: "pointer",
  borderRadius: "50%",
  "&:hover": {
    background: "rgba(0,0,0,0.1)",
  },
});

const blacklistFileExtensions = [
  "exe",
  "scr",
  "msi",
  "bat",
  "sh",
  "cmd",
  "com",
  "dll",
  "pif",
  "scr",
  "vb",
  "vbe",
  "vbs",
  "ws",
  "wsc",
  "wsf",
  "wsh",
];

const imageFormat = ["jpg", "jpeg", "png", "webp", "gif", "drawio"];

type Views = "image" | "object" | "unsupported";

const CenterFlex: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    display="flex"
    padding="10px"
    height="100%"
    justifyContent="center"
    alignItems="center"
    textAlign="justify"
  >
    {children}
  </Box>
);

const svgStyle: React.CSSProperties = {
  position: "relative",
  userSelect: "none",
  display: "inline-block",
  height: "42px",
  color: "#edf7fb",
  fill: "#edf7fb",
  paddingLeft: "35px",
};

const spanPreviewStyle: React.CSSProperties = {
  color: "#edf7fb",
  fontSize: "12px",
};

export default function ImageModal({
  fileName,
  fileSize,
  path,
  children,
}: {
  fileName: string;
  fileSize?: number;
  path: string;
  children?: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fileExtension = fileName?.split(".")?.slice(-1)?.pop();
  const isUnsuported =
    !fileExtension || blacklistFileExtensions.includes(fileExtension);

  const [tryView, setTryView] = React.useState<Views>(
    isUnsuported ? "unsupported" : "image"
  );
  React.useEffect(() => {
    setTryView(isUnsuported ? "unsupported" : "image");
  }, [isUnsuported]);

  const showFileIcon = () => {
    if (fileExtension === "pdf") {
      return (
        <Box>
          <img src={Pdf} height="40px" alt={fileName} />
        </Box>
      );
    } else if (fileExtension && imageFormat.includes(fileExtension)) {
      return (
        <Box>
          <img src={path} height="40px" alt={fileName} />
        </Box>
      );
    } else {
      return (
        <Box>
          <img src={File} height="40px" alt={fileName} />
        </Box>
      );
    }
  };

  const convertToMb = (val: number) => {
    const size = val / (1024 * 1024);
    return size?.toFixed(3);
  };

  return (
    <Box position="relative" display="flex" justifyContent="space-between">
      <Tooltip title={fileName}>
        <Box
          onClick={handleOpen}
          sx={{
            width: "100%",
            height: "56px",
            padding: "14px",
            display: "flex",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "#0389c9",
            fontSize: "0.9rem",
            cursor: "pointer",
            alignItems: "center",
            gap: "10px",
            transition: "0.2s ease-in-out",
            "&:hover": {
              background: alpha(PRIMARY_COLOR, 0.05),
            },
          }}
        >
          {showFileIcon()}
          <Box display="flex" flexDirection="column">
            {fileName?.split("-")?.[0]}.{fileExtension}
            {fileSize && fileSize > 0 && (
              <Typography variant="caption" color="grey">
                File size: {convertToMb(fileSize ?? 0)} MB
              </Typography>
            )}
          </Box>
        </Box>
      </Tooltip>
      {children}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          outline: "unset",
          width: "100%",
        }}
      >
        <>
          <Crossbutton onClick={handleClose}>
            <RxCross1 />
          </Crossbutton>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              ...style,
              bgcolor: "transparent",
            }}
            onClick={handleClose}
          >
            {tryView === "image" ? (
              <img
                onClickCapture={(e) => {
                  e.stopPropagation();
                }}
                onError={() => setTryView("object")}
                src={path ? path : Image}
                alt="resume"
                loading="lazy"
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  padding: "10px",
                }}
              />
            ) : tryView === "object" ? (
              <object
                onClickCapture={(e) => {
                  e.stopPropagation();
                }}
                data={path ?? ""}
                type="text/plain"
                style={{
                  height: "100%",
                  width: "100vw",
                  backgroundColor: "#edf7fb",
                }}
              >
                <CenterFlex>
                  <Box>
                    Your web browser could not preview this file. Instead you
                    can <a href={path}>click here to download the file.</a>
                  </Box>
                </CenterFlex>
              </object>
            ) : (
              <div style={{ display: "inline-grid" }}>
                <svg viewBox="0 0 24 24" style={svgStyle}>
                  <g>
                    <path d="M2.25 24A2.252 2.252 0 0 1 0 21.75V2.25A2.252 2.252 0 0 1 2.25 0h19.5A2.252 2.252 0 0 1 24 2.25v19.5A2.252 2.252 0 0 1 21.75 24H2.25zm0-22.5a.75.75 0 0 0-.75.75v19.5c0 .414.336.75.75.75h19.5a.75.75 0 0 0 .75-.75V2.25a.75.75 0 0 0-.75-.75H2.25z"></path>
                    <path d="M16.5 11.25c-2.068 0-3.75-1.682-3.75-3.75s1.682-3.75 3.75-3.75 3.75 1.682 3.75 3.75-1.682 3.75-3.75 3.75zm0-6c-1.241 0-2.25 1.009-2.25 2.25s1.009 2.25 2.25 2.25 2.25-1.009 2.25-2.25-1.009-2.25-2.25-2.25zM15.655 19.858a.746.746 0 0 1-.699-.478 7.507 7.507 0 0 0-1.132-1.96l-.021-.026a7.364 7.364 0 0 0-3.819-2.495 7.485 7.485 0 0 0-1.979-.268 7.442 7.442 0 0 0-3.674.98.754.754 0 0 1-1.023-.281.751.751 0 0 1 .28-1.023 8.936 8.936 0 0 1 4.415-1.177 8.97 8.97 0 0 1 2.376.322 8.892 8.892 0 0 1 4.1 2.441 5.243 5.243 0 0 1 2.902-.879c1.219 0 2.402.427 3.331 1.204a.743.743 0 0 1 .266.509.743.743 0 0 1-.172.548.747.747 0 0 1-1.056.094 3.699 3.699 0 0 0-2.369-.855c-.692 0-1.375.195-1.957.555a8.833 8.833 0 0 1 .928 1.769.747.747 0 0 1-.697 1.02z"></path>
                  </g>
                </svg>

                <span style={spanPreviewStyle}>No Preview Available</span>
              </div>
            )}
          </Box>
        </>
      </Modal>
    </Box>
  );
}
