import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import GoBackButton from "components/GoBackButton";
import { CountryCodeType, defaultCode } from "hooks/usePhoneValidator";
import { IFileType, IIdeaType, IIdeaTypeRequest } from "models/Idea";
import { IIdeaholderType } from "models/Ideaholder";
import { ISaleType } from "models/Sale";
import { IButtonStatus } from "pages/dashboard/investor/feed-details/InvestorFeedDetails";
import { useEffect, useState } from "react";
import { CiGlobe, CiLock } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createIdea, getIdea, updateIdea } from "services/ideaService";
import { countries } from "services/json/countries";
import { getDefaultCountryService } from "services/userService";
import Idea from "./IdeaForm";
import Sale from "./BusinessSaleForm";
import { AxiosError } from "axios";
import { IResponseMessageType } from "models/ResponseMessage";
import { toast } from "react-toastify";
import { IBusinessTypeResponse } from "models/IdeaResponse";

type IStatus = "unknown" | "success" | "error";

const BusinessTypes = [
  {
    label: "Idea" as BusinessType,
    value: "BusinessIdea" as BusinessType,
    icon: <CiLock />,
  },
  {
    label: "Sale" as BusinessType,
    value: "Sale" as BusinessType,
    icon: <CiGlobe />,
  },
];

const SaleTypes = [
  {
    label: "Company" as SaleType,
    value: "Company" as SaleType,
    // icon: <CiLock />,
  },
  {
    label: "Sole Proprietorship " as SaleType,
    value: "SoleProprietorship " as SaleType,
    // icon: <CiGlobe />,
  },
];

export type BusinessType = "BusinessIdea" | "Sale";

export type SaleType = "Company" | "SoleProprietorship";

export interface IBusinessCommonType {
  id?: number;
  view_count?: number;
  like_count?: number;
  favourite_count?: number;
  files?: IFileType[];
  cover_image_or_video: File | null;
  feasibility_study: File | null;
  swot_analysis: File | null;
  financial_projection: File | null;
  financial_analysis: File | null;

  business_type: BusinessType; // discriminator

  idea_holder: IIdeaholderType;
  ideaholder_id: string | number;
  status?: string;
  idea_request?: {
    status: IButtonStatus;
  }[];
  created_at: string;
  deleted_at: string | null;
  updated_at: string;
  has_favourite?: number;
  has_followed?: number;
  has_liked?: number;
  active?: string;
}

export type IBusinessType = IIdeaType | IIdeaTypeRequest | ISaleType;

export default function BusinessForm() {
  // Phone
  const [defaultCountryCode, setDefaultCountryCode] =
    useState<CountryCodeType>(defaultCode);
  // End Phone

  const [sending, setSending] = useState(false);
  const [select, setSelect] = useState<BusinessType>("BusinessIdea");
  const [saleTypeSelect, setSaleTypeSelect] = useState<SaleType>("Company");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const ideaData = useSelector((state: RootState) => state.idea.data);

  const [ideaData, setIdea] = useState<IBusinessTypeResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const params = useParams<{
    id: string | undefined;
  }>();

  const getIdeaDetail = async () => {
    if (params?.id) {
      setLoading(true);
      const res = await getIdea(+params?.id);
      if (res?.data) {
        setIdea(res?.data);
      }
    }
  };

  useEffect(() => {
    getIdeaDetail();
  }, [params?.id]);

  useEffect(() => {
    if (ideaData) setSelect(ideaData?.idea_type);
  }, [ideaData]);

  const onSubmit = async (data: IBusinessType) => {
    let mainData: IBusinessType;

    const fd = new FormData();
    if (data?.business_type === "BusinessIdea") {
      mainData = data as IIdeaType;
      fd.append("idea_type", mainData?.business_type?.toLowerCase());
      fd.append("idea[idea_name]", mainData?.idea_name);
      fd.append("idea[idea_description]", mainData?.idea_description);
      fd.append(
        "idea[ideaholder_investment]",
        mainData?.ideaholder_investment?.toString()
      );
      fd.append(
        "idea[expected_investment]",
        mainData?.expected_investment?.toString()
      );
      fd.append("idea[location]", mainData?.location);
      fd.append("idea[preferred_location]", mainData?.preferred_location);
      fd.append("idea[investment_type]", mainData?.investment_type ?? "");
      data?.idea_category?.forEach((category) => {
        fd.append("idea[sector][]", category?.id?.toString());
      });
      mainData?.tags.forEach((el) => {
        fd.append("idea[tags][]", el?.toString());
      });

      fd.append("debt_injection_plan", mainData?.debt_injection_plan as Blob);
      fd.append("exit_plan", mainData?.exit_plan as Blob);
    }

    if (data?.business_type === "Sale") {
      mainData = data as ISaleType;
      fd.append("idea_type", mainData?.business_type?.toLowerCase());
      fd.append("idea[company_name]", mainData?.company_name);
      fd.append("idea[address]", mainData?.address);
      fd.append("idea[email]", mainData?.email);
      fd.append("idea[mobile_no]", mainData?.phone);
      fd.append("idea[postal_code]", mainData?.postal_code?.toString());
      fd.append("idea[type_of_business]", mainData?.type_of_business);
      fd.append("idea[valuation]", mainData?.valuation?.toString());
      fd.append("idea[annual_turnover]", mainData?.annual_turnover?.toString());
      fd.append("idea[business_description]", mainData?.business_description);
      fd.append("idea[selling_reason]", mainData?.selling_reason);
      fd.append("latest_share_lagat", mainData?.latest_share_lagat as Blob);
      fd.append("moa", mainData?.moa as Blob);
      fd.append("aoa", mainData?.aoa as Blob);
      fd.append("pan", mainData?.pan as Blob);
      fd.append("business_profile", mainData?.business_profile as Blob);
      fd.append("tax_clearance", mainData?.tax_clearance as Blob);
      fd.append("ocr_update", mainData?.ocr_update as Blob);
      fd.append("audit_report", mainData?.audit_report as Blob);
      fd.append("expected_valuation", mainData?.expected_valuation as Blob);
    }

    fd.append("cover_image_or_video", data?.cover_image_or_video as Blob);
    fd.append("feasibility_study", data?.feasibility_study as Blob);
    fd.append("swot_analysis", data?.swot_analysis as Blob);
    fd.append("financial_projection", data?.financial_projection as Blob);
    fd.append("financial_analysis", data?.financial_analysis as Blob);

    setSending(true);

    try {
      if (ideaData && params?.id) {
        await updateIdea(params?.id, fd);
        toast("Business Proposition Updated Successfully!", {
          type: "success",
        });
      } else {
        await createIdea(fd);
        toast("Business Proposition Created Successfully!", {
          type: "success",
        });
      }
      navigate("/idea");

      setSending(false);
    } catch (e) {
      const error = e as AxiosError<IResponseMessageType>;
      let message = error.response?.data?.message || "Some error";
      if (Array.isArray(message)) {
        message = message.join(", ");
      }
      setSending(false);
      toast(message, {
        type: "error",
      });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as BusinessType);
  };

  const handleSaleTypeChange = (event: SelectChangeEvent) => {
    setSaleTypeSelect(event.target.value as SaleType);
  };

  // Phone
  const getDefaultCountry = async () => {
    const res = await getDefaultCountryService();
    if (res?.status === 200) {
      const country = countries.find((x) => x.code === res?.data?.country_code);
      setDefaultCountryCode({
        code: country?.code ?? "",
        label: country?.label ?? "",
        phone: country?.phone ?? "",
      });
    }
  };

  useEffect(() => {
    getDefaultCountry();
  }, []);

  // End Phone

  useEffect(() => {
    if (ideaData) setSelect(ideaData?.idea_type);
  }, [ideaData]);

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        {/* <CanEditAlert />
        <Reasons /> */}
        <div className="top-form">
          <GoBackButton text="Go Back" />
          <h3>{ideaData ? "Update" : "Create New"} business proposition</h3>
          {ideaData ? (
            <h6>Change details below to update your business proposition</h6>
          ) : (
            <h6>
              Fill in the details below to create a new business proposition
            </h6>
          )}
        </div>
        <div>
          <Grid container spacing={2} marginTop="20px">
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth sx={{ paddingRight: "8px" }}>
                <InputLabel id="select-business-type-label">
                  Business Proposition
                </InputLabel>
                <Select
                  labelId="select-business-type-label"
                  id="select-business-type"
                  value={select}
                  label="Business Proposition"
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    },
                  }}
                  disabled={!!ideaData}
                >
                  {BusinessTypes.map((el) => (
                    <MenuItem
                      key={el?.value}
                      value={el.value}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box component="span" display="flex">
                        {el?.icon}
                      </Box>
                      <span className="dropdown-text">{el?.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {select === "Sale" && (
              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth sx={{ paddingRight: "8px" }}>
                  <InputLabel id="select-sale-type-label">
                    Sale Type
                  </InputLabel>
                  <Select
                    labelId="select-sale-type-label"
                    id="select-sale-type"
                    value={saleTypeSelect}
                    label="Sale Type"
                    onChange={handleSaleTypeChange}
                    sx={{
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      },
                    }}
                    disabled={!!ideaData}
                  >
                    {SaleTypes.map((el) => (
                      <MenuItem
                        key={el?.value}
                        value={el.value}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box component="span" display="flex">
                          {/* {el?.icon} */}
                        </Box>
                        <span className="dropdown-text">{el?.label}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </div>

        {select === "BusinessIdea" && (
          <Idea
            data={ideaData as IBusinessTypeResponse}
            onSubmit={onSubmit}
            loading={sending}
          // viewFor="view"
          />
        )}
        {select === "Sale" && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox size="small" />
              <h3 style={{ fontSize: "14px", marginTop: "1px", marginLeft: "-5px" }}>Small Business</h3>
            </div>
            <Sale
              defaultCountryCode={defaultCountryCode}
              data={ideaData as IBusinessTypeResponse}
              onSubmit={onSubmit}
              loading={sending}
            // viewFor="view"
            />
          </>
        )}
      </div>
    </div>
  );
}
