import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Loader from "components/Loader";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "redux/app/store";

const columns: GridColDef<UserDetailRow>[] = [
  { field: "SN", headerName: "S.N", flex: 1, maxWidth: 60 },
  { field: "Full Name", flex: 1, minWidth: 200 },
  // { field: "Type", headerName: "Type", flex: 1, minWidth: 200 },
  {
    field: "Email",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "Address",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "Contact Number",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "Action",
    headerName: "Action",
    minWidth: 90,
    maxWidth: 90,
    flex: 1,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Link to={`/admin/user/${params.id}`}>
            <IconButton size="small">
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </Link>
        </Box>
      );
    },
  },
];

interface UserDetailRow {
  id: number;
  SN: number;
  "Full Name": string;
  Type: string;
  Email: string;
  Address: string;
  "Contact Number": string;
  Action: string;
}

export interface UserDetailTableProps {
  rows: UserDetailRow[];
}

export default function UserDetailTable() {
  const usersState = useSelector((state: RootState) => state?.userAll);

  switch (usersState.status) {
    case "error":
      return <>Error Loading...</>;
    case "loaded":
      const rows: UserDetailRow[] =
        usersState.data?.users?.map((u, i) => ({
          SN: i + 1,
          id: u.id,
          "Full Name": u.name,
          Email: u.email ?? "",
          Address: u.address ?? "",
          "Contact Number": u.phone_no ?? "",
          Type: "",
          Action: "View",
        })) ?? [];
      return (
        <div style={{ height: 631, width: "100%" }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                {
                  display: "none",
                },
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            // onRowClick={handleClick}
            // checkboxSelection
          />
        </div>
      );

    default:
      return <Loader />;
  }
}
