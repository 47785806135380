import React, { useEffect } from "react";

const useOutsideClick = (
  ref: { current: { contains: (arg0: any) => any } },
  callback: React.EventHandler<React.MouseEvent>
) => {
  const handleClick: EventListener = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback(e as any);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default useOutsideClick;
