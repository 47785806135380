import moment from "moment";

export default function dateFormatter(date: string | undefined) {
  const dateF = moment(date);
  const today = moment();
  const diffInDays = today.diff(date, "days");

  if (diffInDays === 0) {
    return dateF.format("MMM D, YYYY");
  } else if (diffInDays === 1) {
    return "Yesterday";
  } else if (diffInDays < 4) {
    return `${diffInDays} days ago`;
  } else {
    return dateF.format("MMM D, YYYY");
  }
}
