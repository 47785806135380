import ModalComponent from "components/ModalComponent";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { investorDeactivationStatus } from "services/InvestorService";
import { ideaholderDeactivationStatus } from "services/ideaholderService";

interface IProps {
  handleClose: () => void;
  open: boolean;
  onConfirm: () => void;
  data?: IInvestorType | IIdeaholderTypeResponse | null;
  businessOrInvestor: string;
}

export default function ProfileDeactivationModal(props: IProps) {
  const investorId = useSelector(
    (state: RootState) => state?.investor?.data?.id
  );
  const ideaholderId = useSelector(
    (state: RootState) => state?.ideaholder?.data?.id
  );
  const deactivationRequest = async () => {
    const isActive = props?.data?.active === "true";
    const status = isActive ? "deactivated" : "reactivated";
  
    let res;
    if (props?.businessOrInvestor === "Investor") {
      res = await investorDeactivationStatus(investorId ?? 0, { active: String(!isActive) });
    } else {
      res = await ideaholderDeactivationStatus(ideaholderId ?? 0, { active: String(!isActive) });
    }
  
    if (res.status === 201 || res.status === 200) {
      props?.onConfirm();
      toast(`Profile ${status} successfully`);
      props.handleClose();
    }
  };
  return (
    <ModalComponent
      title={`Profile ${props?.data?.active === "true" ? "deactivation" : "activation"} modal`}
      subTitle={`Are you sure you want to ${props?.data?.active === "true" ? "deactivate" : "reactivate"} your ${props?.businessOrInvestor} profile?`}
      {...props}
      onConfirm={deactivationRequest}
    />
  );
}
