import { styled } from "@mui/system";
import data from "data/InvestorFeedData";
import numberFormatter from "utils/numberFormatter";

const TitleSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
});

const ActiveBox = styled("div")({});
const Type = styled("div")({
  fontFamily: "roboto",
  fontSize: "0.8rem",
  color: "#4c4c4c",
});
const Title = styled("div")({
  fontWeight: "bold",
  fontSize: "0.9rem",
});

const Wrap = styled("div")({
  display: "flex",
  gap: "15px",
});

export default function LikedPages() {
  return (
    <div className="page-list section-t-space" style={{ marginTop: "10px" }}>
      <div className="card-title">
        <h3>Recently Added Investors</h3>
      </div>
      {data?.map((dl, index) => (
        <div key={index} className="list-content">
          <ul>
            <li>
              <TitleSection>
                <Wrap>
                  <ActiveBox>
                    <Title>{dl.title}</Title>
                    <Type>{dl.type}</Type>
                  </ActiveBox>
                </Wrap>
                <p>{numberFormatter(dl.views)}</p>

                {/* <Box>
                  {dl?.isFollowed ? (
                    <CustomButton2 text={dl.buttontext} type={dl.typeButton} />
                  ) : (
                    <CustomButton2 text={dl.buttontext} type={dl.typeButton} />
                  )}
                </Box> */}
              </TitleSection>
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
}
