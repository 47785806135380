import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "redux/app/genericState";

interface Role {
  id: number;
  name: string;
  display_name: string;
  description: string;
  tenant_id: string;
  status: number;
}

interface User {
  id: number;
  name: string;
  email: string;
  gender: string;
  district_id: string | null;
  municipality_id: string | null;
  area_id: string | null;
  street_id: string | null;
  nearest_landmark_id: string | null;
  address: string | null;
  phone_no: string | null;
  tenant_id: string | null;
  profile_pic: string | null;
  roles: Role[];
}

interface UserAllSuccessResponse {
  status: "success";
  users: User[];
}

interface UserAllState extends GenericState<UserAllSuccessResponse> {}

const initialState: UserAllState = {
  data: {status: "success", users: []},
  error: undefined,
  status: "none",
};

export const userAllDataRequest = createAction("GET_USERS_DATA");

const userAllSlice = createSlice({
  name: "user_all",
  initialState,
  reducers: {
    get_users: (state) => {
      state.status = "loading";
      state.data = initialState.data;
      state.error = undefined;
    },
    success_users: (state, action: PayloadAction<UserAllSuccessResponse>) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_users: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default userAllSlice;
