import httpClient from "./httpClient";

export const createConnect = async (payload: {
  ideaholder_id: string | number;
  investor_id: string | number;
  requested_by: string | number;
  requested_to: string | number;
}) => {
  return await httpClient.post("/connect", payload);
};

export const getAllConnects = async () => {
  return await httpClient.get("/connect");
};

export const getMyConnects = async () => {
  return await httpClient.get("/connect/me");
};

export const updateConnect = async (
  id: number | undefined,
  payload: {
    status: string;
  }
) => {
  return await httpClient.put(`/connect/${id}/update`, payload);
};
