import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Autocomplete,
  Chip,
  createFilterOptions,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomButton from "components/CustomButton";
import UploadFile from "components/UploadFile";
import { API_BASE_URL } from "constants/api-constants";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import { IIdeaTypeRequest } from "models/Idea";
import ScreeningIdeaModal from "pages/dashboard/idea/create-idea/modal/ScreeningIdeaModal";
import React, { useState } from "react";
import {
  Controller,
  FieldErrorsImpl,
  FormProvider,
  useForm,
} from "react-hook-form";
import { changeIdeaFile } from "services/ideaService";
import { capitalizeFirstChar } from "utils/stringUtils";
import * as yup from "yup";
import DisplayFile from "../../../../components/DisplayFile";
import { IBusinessType } from "./BusinessForm";
import { IBusinessTypeResponse } from "models/IdeaResponse";

const typeOfInvestment = ["equity", "preference"];

const DividerRoot = styled(Divider)({
  marginTop: "10px",
});

interface ICategoryList {
  created_at: string;
  deleted_at: null;
  description: string;
  id: number;
  name: string;
  status: number;
  updated_at: string;
}

type ErrorsType = Partial<FieldErrorsImpl<IIdeaTypeRequest>>;

const IdeaCategoriesList: ICategoryList[] = [
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "IT",
    id: 1,
    name: "IT",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Agriculture",
    id: 2,
    name: "Agriculture",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Building, Construction and Maintenance",
    id: 3,
    name: "Building, Construction and Maintenance",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Business Services",
    id: 4,
    name: "Business Service",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Education",
    id: 5,
    name: "Education",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Energy",
    id: 6,
    name: "Energy",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Finance",
    id: 7,
    name: "Finance",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Food & Beverage",
    id: 8,
    name: "Food & Beverage",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Healthcare",
    id: 9,
    name: "Healthcare",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Industrial",
    id: 10,
    name: "Industrial",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Logistics",
    id: 11,
    name: "Logistics",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Media",
    id: 12,
    name: "Media",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Retail Shops",
    id: 13,
    name: "Retail Shops",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Technology",
    id: 14,
    name: "Technology",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Textiles",
    id: 15,
    name: "Textiles",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
  {
    created_at: "2023-01-17T11:01:49.000Z",
    deleted_at: null,
    description: "Travel & Leisure",
    id: 16,
    name: "Travel & Leisure",
    status: 1,
    updated_at: "2023-01-17T11:01:49.000Z",
  },
];

const imageOnlyField = ["cover_image_or_video"];

const DESCRIPTION_LIMIT = 500;

const FormSchema = (update?: boolean) =>
  yup.object().shape({
    idea_name: yup
      .string()
      .required("Name is required")
      .max(70, "Text must be less than 70 characters"),
    idea_description: yup
      .string()
      .required("Description is required")
      .max(DESCRIPTION_LIMIT, "Text must be less than 500 characters"),
    ideaholder_investment: yup
      .number()
      .required("Your investment is required")
      .typeError("Your investment is required")
      .max(4000000000, "Amount cannot exceed 4000000000"),
    expected_investment: yup
      .number()
      .required("The amount of expected investment is required")
      .typeError("The amount of expected investment is required")
      .moreThan(
        yup.ref("ideaholder_investment"),
        "Expected investment cannot be less than investment amount"
      )
      .max(4000000000, "Amount cannot exceed 4000000000"),
    idea_category: yup
      .array()
      .of(
        yup.object().shape({
          created_at: yup.date().nullable(),
          deleted_at: yup.date().nullable(),
          description: yup.string().required("Required"),
          id: yup.number().required("Required"),
          name: yup.string().required("Required"),
          status: yup.number().required("Required"),
          updated_at: yup.date().nullable(),
        })
      )
      .min(1, "Provide at least 1 category")
      .required("Provide at least 1 category"),
    // tags: yup
    //   .array()
    //   .of(yup.string())
    //   .min(1, "Provide at least 1 tag")
    //   .required("Provide at least 1 tag"),
    location: yup.string().required("Location is required"),
    investment_type: yup
      .string()
      .nullable()
      .required("Choose the type of investment")
      .oneOf(typeOfInvestment.map((investment) => investment)),
    preferred_location: yup.string().required("Preferred location is required"),
    cover_image_or_video: yup
      .mixed()
      .test(
        "required",
        "You need to provide a cover image or video",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    debt_injection_plan: yup
      .mixed()
      .test(
        "required",
        "You need to provide a debt injection plan",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    feasibility_study: yup
      .mixed()
      .test(
        "required",
        "You need to provide a feasibility study file",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    swot_analysis: yup
      .mixed()
      .test(
        "required",
        "You need to provide a SWOT analysis file",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    financial_projection: yup
      .mixed()
      .test(
        "required",
        "You need to provide a financial projection file",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    financial_analysis: yup
      .mixed()
      .test(
        "required",
        "You need to provide a financial analysis file",
        (file: File) => {
          if (update || file) return true;
          return false;
        }
      )
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
    exit_plan: yup
      .mixed()
      .test("required", "You need to provide an exit plan", (file: File) => {
        if (update || file) return true;
        return false;
      })
      .test(
        "fileSize",
        "The file size must not be greater than 25MB",
        (file: File) => {
          return update || (file && file.size <= 25000000);
        }
      ),
  });

const filter = createFilterOptions<string>();

export default function Idea({
  onSubmit,
  data,
  isReadOnly = false,
  loading,
  viewFor,
  style1,
  style2,
}: {
  onSubmit: (e: IBusinessType) => void;
  data?: IBusinessTypeResponse | null;
  isReadOnly?: boolean;
  loading?: boolean;
  viewFor?: string | number;
  style1?: React.CSSProperties;
  style2?: React.CSSProperties;
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const hookForm = useForm<IIdeaTypeRequest>({
    resolver: yupResolver(FormSchema(!!data?.id)),
    defaultValues: {
      business_type: "BusinessIdea",
      idea_name: data?.business_idea.idea_name ?? "",
      idea_category: data?.business_idea.idea_sectors ?? [],
      idea_description: data?.business_idea.idea_description ?? "",
      ideaholder_investment: data?.business_idea.ideaholder_investment ?? "",
      expected_investment: data?.business_idea.expected_investment ?? "",
      location: data?.business_idea.location ?? "",
      preferred_location: data?.business_idea.preferred_location ?? "",
      investment_type: data?.business_idea.investment_type?.toLowerCase() ?? "",
      tags:
        (data?.business_idea.tags as { tag: string }[] | undefined)?.map(
          (x) => x.tag
        ) ?? [],
      cover_image_or_video: null,
      debt_injection_plan: null,
      feasibility_study: null,
      swot_analysis: null,
      financial_projection: null,
      financial_analysis: null,
      exit_plan: null,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = hookForm;

  const { ideaProfile } = useIdeaHolderState();

  const checkFile = (name: string) => {
    if ((data?.files ?? [])?.length > 0) {
      const exists = data?.files?.find(
        (el) => el?.filename?.split("-")?.[0] === name
      );
      if (exists) {
        return (
          <UploadFile
            profile={ideaProfile}
            name={name}
            imageOnly={imageOnlyField?.includes(name)}
            fileData={exists}
            changeService={changeIdeaFile}
            isEdit={!!data}
            title={exists?.filename?.split("-")?.[0]?.replaceAll("_", " ")}
          />
        );
      } else {
        return (
          <UploadFile
            profile={ideaProfile}
            imageOnly={imageOnlyField?.includes(name)}
            name={name}
            isEdit={!!data}
            title={`Upload your ${name?.replaceAll("_", " ")}`}
            changeService={changeIdeaFile}
            style={{ marginTop: "10px" }}
          />
        );
      }
    } else {
      return (
        <UploadFile
          imageOnly={imageOnlyField?.includes(name)}
          isEdit={!!data}
          name={name}
          title={`Upload your ${name?.replaceAll("_", " ")}`}
        />
      );
    }
  };

  const loggedInIdeaholderId = ideaProfile?.id;
  const ideaOwnerIdeaholderId = data?.ideaholder_id;

  const CanEditAlert: React.FC = () => {
    return isReadOnly && loggedInIdeaholderId === ideaOwnerIdeaholderId ? (
      <Alert severity="info" sx={{ marginBottom: "10px" }}>
        You cannot edit your idea until the admin has reviewed it.
      </Alert>
    ) : (
      <></>
    );
  };

  const Reasons: React.FC = () => {
    const _data = data as any;
    return _data?.status_reason &&
      _data?.status !== "Re-review" &&
      loggedInIdeaholderId === ideaOwnerIdeaholderId ? (
      <Alert severity="warning" sx={{ marginBottom: "10px" }}>
        {_data?.status} Reason: {_data?.status_reason}
      </Alert>
    ) : (
      <></>
    );
  };

  return (
    <>
      <CanEditAlert />
      <Reasons />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...hookForm}>
          <div className="form-wrap">
            <div className="form-group">
              <div className="title-main" style={style2}>
                <span>Idea Details</span>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Idea Name"
                    type="text"
                    autoComplete="Idea Name"
                    fullWidth
                    required
                    {...register("idea_name")}
                    error={!!errors?.idea_name}
                    helperText={errors?.idea_name?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="idea_category"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        multiple
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        onChange={(event, item) => {
                          onChange(item);
                        }}
                        value={value}
                        disablePortal
                        readOnly={isReadOnly}
                        options={IdeaCategoriesList}
                        getOptionLabel={(item) => (item ? item.name : "")}
                        // defaultValue={value ?? undefined}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            required
                            inputProps={{
                              ...params.inputProps,
                              required: value?.length === 0,
                            }}
                            label="Category"
                            error={!!errors?.idea_category}
                            helperText={errors?.idea_category?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="idea_description"
                    control={control}
                    render={({ field }) => {
                      const { value } = field;
                      return (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: isReadOnly,
                          }}
                          inputProps={{
                            maxlength: DESCRIPTION_LIMIT,
                          }}
                          fullWidth
                          required
                          multiline
                          rows={4}
                          label="Your Idea in brief"
                          {...register("idea_description")}
                          error={!!errors?.idea_description}
                          helperText={
                            value?.length +
                            "/" +
                            DESCRIPTION_LIMIT +
                            (errors?.idea_description?.message
                              ? ` ${errors?.idea_description?.message}`
                              : "")
                          }
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field: { value, ref } }) => (
                      <Autocomplete
                        sx={{
                          flex: 1,
                        }}
                        value={value}
                        onChange={(_e, newValue, reason) => {
                          if (reason === "selectOption") {
                            const lastValue = newValue?.pop();
                            const trimValue = lastValue
                              ?.replace(/^Add /i, "")
                              ?.replace(/^"/, "")
                              ?.replace(/"$/, "");
                            if (trimValue) newValue.push(trimValue);
                          }
                          setValue(
                            "tags",
                            newValue.filter(
                              (x): x is string => typeof x === "string"
                            )
                          );
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = [...options, ...value].some(
                            (option) => inputValue === option
                          );

                          if (inputValue !== "" && !isExisting) {
                            filtered.push(`Add "${inputValue}"`);
                          }

                          return filtered;
                        }}
                        multiple
                        readOnly={isReadOnly}
                        id="tags-filled"
                        options={[] as string[]}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Tags"
                            helperText={
                              viewFor === "create"
                                ? "Press enter or click on add option to create a new tag"
                                : undefined
                            }
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: isSmallScreen ? (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ) : null,
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {(data?.files ?? [])?.length > 0 && isReadOnly && (
                    <Grid container spacing={2} marginTop={1}>
                      {data?.files?.map((el, index) => {
                        if (el.filename?.startsWith("cover_image")) {
                          return (
                            <Grid item xs={12} sm={6} key={index}>
                              <DisplayFile
                                id={el.id}
                                readonly={isReadOnly}
                                title={el.filename
                                  ?.split("-")?.[0]
                                  ?.replaceAll("_", " ")}
                                fileName={el.filename}
                                path={`${API_BASE_URL}/${el.path}`}
                              />
                            </Grid>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  )}
                  {!isReadOnly && (
                    <Grid container spacing={2} marginTop={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        {checkFile("cover_image_or_video")}
                        {errors.cover_image_or_video && (
                          <FormHelperText error>
                            {errors.cover_image_or_video?.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* <Divider className="hrClass2" />npm */}
              <DividerRoot className="hrClass" />

              <div className="title-main">
                <span>Investment Requirements</span>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="investment_type"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel required id="select-investment-type-label">
                          Investment Type
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="select-investment-type-label"
                          id="select-investment-type"
                          value={field.value}
                          label="Investment Type"
                          onChange={field.onChange}
                        >
                          {typeOfInvestment.map((t) => (
                            <MenuItem value={t}>
                              {capitalizeFirstChar(t)}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          id="component-helper-text"
                          error={!!errors?.investment_type}
                        >
                          {errors?.investment_type?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Your Investment Amount (NPR)"
                    type="number"
                    min="1"
                    autoComplete="your-investment"
                    fullWidth
                    required
                    {...register("ideaholder_investment")}
                    error={!!errors?.ideaholder_investment}
                    helperText={errors?.ideaholder_investment?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Expected Investment (NPR)"
                    type="number"
                    min="1"
                    autoComplete="expected-investment"
                    fullWidth
                    required
                    {...register("expected_investment")}
                    error={!!errors?.expected_investment}
                    helperText={errors?.expected_investment?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Idea Location"
                    type="text"
                    autoComplete="Idea Location"
                    fullWidth
                    required
                    {...register("location")}
                    error={!!errors?.location}
                    helperText={errors?.location?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Preferred Location"
                    type="text"
                    autoComplete="Preferred Location"
                    fullWidth
                    required
                    {...register("preferred_location")}
                    error={!!errors?.preferred_location}
                    helperText={errors?.preferred_location?.message}
                  />
                </Grid>
              </Grid>

              {/* Displaying all of the below files */}
              <DividerRoot className="hrClass" />

              <Grid container spacing={2} marginTop={1}>
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("debt_injection_plan")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("feasibility_study")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("swot_analysis")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("financial_projection")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("financial_analysis")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("exit_plan")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
              </Grid>

              {/* Displaying all of the below files */}

              {!isReadOnly && (
                <>
                  <div className="title-main">
                    <span>Upload the given entitled files</span>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6}>
                      {checkFile("debt_injection_plan")}
                      {errors.debt_injection_plan && (
                        <FormHelperText error>
                          {errors.debt_injection_plan?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("feasibility_study")}
                      {errors.feasibility_study && (
                        <FormHelperText error>
                          {errors.feasibility_study?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("swot_analysis")}
                      {errors.swot_analysis && (
                        <FormHelperText error>
                          {errors.swot_analysis?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("financial_projection")}
                      {errors.financial_projection && (
                        <FormHelperText error>
                          {errors.financial_projection?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("financial_analysis")}
                      {errors.financial_analysis && (
                        <FormHelperText error>
                          {errors.financial_analysis?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("exit_plan")}
                      {errors.exit_plan && (
                        <FormHelperText error>
                          {errors.exit_plan?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              <div style={{ marginTop: "10px" }}>
                <i style={{ color: "gray" }}>*Please wait 24 hours for approval.</i>
              </div>

              {!isReadOnly && (
                <div
                  className="box-gap flex-center"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <CustomButton
                    loading={loading}
                    isSubmit={true}
                    text={data ? "Update Idea" : "+ Create Idea"}
                    type="filled"
                  // onClick={handleOpen}
                  />
                </div>
              )}
              <ScreeningIdeaModal open={open} handleClose={handleClose} />
            </div>
          </div>
        </FormProvider>
      </form>
    </>
  );
}
