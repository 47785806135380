import { IIdeaholderType, IIdeaholderTypeResponse } from "models/Ideaholder";
import httpClient from "./httpClient";

export const createIdeaholder = async (data: FormData) => {
  return await httpClient.post("/ideaholder/create", data);
};

export const getAllIdeaholders = async () => {
  return await httpClient.get("/ideaholder");
};

export const getIdeaholderDetail = async () => {
  return await httpClient.get<IIdeaholderType>(`/ideaholder/me`);
};

export const getIdeaholder = async (id: number) => {
  return await httpClient.get<IIdeaholderTypeResponse>(`/ideaholder/${id}`);
};

export const ideaholderDeactivationStatus = async (
  id: number,
  payload: {
    active: string;
  }
) => {
  return await httpClient.put(`/ideaholder/${id}/update-active`, payload);
};

export const createProposal = async (payload: {
  idea_id: string | number;
  ideaholder_id: string | number;
  investor_id: string | number;
}) => {
  return await httpClient.post("/proposal", payload);
};

export const getIdeaholderProposalRequest = async (
  id: number | undefined,
  status: string | null
) => {
  let url = `/proposal/ideaholder_id/${id}`;
  if (status) {
    url += `?status=${status}`;
  }
  return await httpClient.get(url);
};

export const getIdeaHolderAnalytics = async () => {
  return await httpClient.get("/ideaholder/analytics");
};
