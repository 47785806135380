import CreateProfileMessage from "components/CreateProfileMessage";
import useInvestorState from "hooks/useInvestorState";
import React, { Suspense } from "react";
import Loader from "../components/Loader";

const InvestorGuardComponent = ({
  component,
}: {
  component: React.ReactNode;
}) => {
  const { hasInvestorProfile, status } = useInvestorState();

  if (status === "loading") {
    return <Loader />;
  }

  if (!hasInvestorProfile) {
    return (
      <CreateProfileMessage
        id="center-content"
        description="Hey! are you sure you have a investor profile? Make sure you have a investor profile to view the details."
        path="/investor/profile"
        buttonTitle="Create Investor Profile"
        message="*Click here to create a investor profile now."
      />
    );
  }

  return <Suspense fallback={<Loader />}>{component}</Suspense>;
};

export default InvestorGuardComponent;
