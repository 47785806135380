import { Box, Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { useState, useEffect, useContext } from "react";
import { BusinessTypeContext } from "pages/main/MainPage";
import { IBusinessTypeResponse } from "models/IdeaResponse";

export default function AccessRequestTable() {

  const { businessType, setBusinessType } = useContext(BusinessTypeContext);

  const columns: GridColDef[] = [
    { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
    {
      field: "IdeaHolder",
      headerName: "Business Name",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (params.row.idea.idea_holder.full_name) {
          result.push(params.row.idea.idea_holder.full_name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "idea_name",
      headerName: "Business Proposition",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (params.row.idea.idea_type === "BusinessIdea") {
          result.push(`${params.row.idea.business_idea?.idea_name}` + ' (Idea)');
        } else if (params.row.idea.idea_type === "Sale") {
          result.push(`${params.row.idea.idea_sale?.company_name}` + ' (Sale)');
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "full_name",
      headerName: "Requested By",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (params.row.profile.full_name) {
          result.push(params.row.profile.full_name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            <Chip
              label={params?.row?.status}
              variant="filled"
              sx={{
                color: "#fff",
                bgcolor:
                  STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
              }}
            />
          </Box>
        );
      },
    },
  ];

  const accessRequest = useSelector(
    (state: RootState) => state?.accessRequestAll
  );

  const ideas = accessRequest.data.map(data => data)

  useEffect(() => {
    setBusinessType("");
  }, [])

  const [filteredIdeas, setFilteredIdeas] = useState<any>([])

  useEffect(() => {
    if (businessType === "BusinessIdea") {
      const filteredIdeasArray = ideas.filter((idea) => idea?.idea?.idea_type === "BusinessIdea")
      setFilteredIdeas(filteredIdeasArray);
    } else if (businessType === "Sale") {
      const filteredIdeasArray = ideas.filter((idea) => idea?.idea?.idea_type === "Sale")
      setFilteredIdeas(filteredIdeasArray);
    }
  }, [businessType])

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={businessType === ""
          ? accessRequest?.data?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          }) ?? []
          : filteredIdeas.map((el: IBusinessTypeResponse, index: number) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
        }
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      // onRowClick={handleClick}
      // checkboxSelection
      />
    </div>
  );
}
