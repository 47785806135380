import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAccessRequestType } from "models/AccessRequest";
import { GenericState } from "redux/app/genericState";

interface AccessRequestState extends GenericState<IAccessRequestType[]> {}

const initialState: AccessRequestState = {
  data: [],
  error: undefined,
  status: "none",
};

export const ideaAccessAllDataRequest = createAction<{
  status: "accepted" | "rejected" | "pending" | null;
}>("IDEA_ACCESS_ALL_DATA_REQUEST");

// export const ideaAccessIdeaholderDataRequest = createAction<{
//   id: string | number;
//   status: "accepted" | "rejected" | "pending" | null;
// }>("IDEA_ACCESS_IDEAHOLDER_DATA_REQUEST");

// export const ideaAccessInvestorDataRequest = createAction<{
//   id: string | number;
//   status: "accepted" | "rejected" | "pending" | null;
// }>("IDEA_ACCESS_INVESTOR_DATA_REQUEST");

const accessRequestAllSlice = createSlice({
  name: "access-request",
  initialState,
  reducers: {
    get_access_request_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_access_request_all_data: (
      state,
      action: PayloadAction<IAccessRequestType[]>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_access_request_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default accessRequestAllSlice;
