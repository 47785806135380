import { Box, Divider, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Img2 from "assets/png/for.png";
import Img from "assets/png/pdf2.png";
import CustomButton from "components/CustomButton";
import CustomButton2 from "components/CustomButton2";
import FullButton from "components/FullButton";
import GoBackButton from "components/GoBackButton";
import InvestorModal from "components/InvestorModal";
import PostActions from "components/PostActions";
import { API_BASE_URL } from "constants/api-constants";
import { PRIMARY_COLOR } from "constants/color-constants";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import useInvestorState from "hooks/useInvestorState";
import moment from "moment";
import ConnectModal from "pages/dashboard/admin/connect/modal/AccessConnectModal";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { AiFillLock } from "react-icons/ai";
import { MdFileDownload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/app/store";
import {
  followIdea,
  getIdea,
  postDisLiked,
  postFavourited,
  postLiked,
  postUnfavouited,
  unfollowIdea,
} from "services/ideaService";
import dateFormatter from "utils/dateFormatter";
import AccessRequestModal from "../modal/AccessRequestModal";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { IBusinessTypeResponse } from "models/IdeaResponse";

const LastSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
});

const TopWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
});

type IButtonStatus = "uninitialized" | "pending" | "accepted" | "rejected";

export default function SaleFeedDetail() {
  const [show, setShow] = useState(true);
  const [idea, setIdea] = useState<IBusinessTypeResponse | null>(null);

  const params = useParams<{
    id: string | undefined;
  }>();

  const { ideaProfile } = useIdeaHolderState();
  const dateString = idea?.created_at;

  const formattedTime = moment(dateString).calendar();
  const [hasFollowed, setHasFollowed] = useState(idea?.has_followed);
  const [like, setLike] = useState(idea?.like_count ?? 0);
  const [favourites, setFavourites] = useState(idea?.favourite_count ?? 0);
  const [hasLikedPost, setHasLikedPost] = useState(idea?.has_liked);
  const [hasFav, setHasFav] = useState(idea?.has_favourite);

  useEffect(() => {
    setLike(idea?.like_count ?? 0);
    setFavourites(idea?.favourite_count ?? 0);
    setHasLikedPost(idea?.has_liked);
    setHasFav(idea?.has_favourite);
    setHasFollowed(idea?.has_followed);
  }, [idea]);

  const investorId = useSelector(
    (state: RootState) => state?.investor?.data?.id
  );
  const { hasInvestorProfile } = useInvestorState();

  useEffect(() => {
    if (ideaProfile?.id === idea?.ideaholder_id) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [ideaProfile, idea]);

  const getIdeaDetail = async () => {
    if (params?.id) {
      const res = await getIdea(+params?.id);
      if (res?.status === 200 || res?.status === 201) {
        setIdea(res?.data);
      }
    }
  };
  useEffect(() => {
    getIdeaDetail();
  }, [params?.id]);

  const [requestStatus, setRequestStatus] =
    useState<IButtonStatus>("uninitialized");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [investorModal, setInvestorModal] = useState(false);

  const handleOpen = () => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }
    setOpen(true);
  };

  const handleCloseInvestor = () => {
    setInvestorModal(false);
  };
  const handleOpen2 = () => setOpen2(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  useEffect(() => {
    if (hasInvestorProfile) {
      setRequestStatus(
        (
          idea?.idea_request?.[0]?.status ?? "uninitialized"
        )?.toLowerCase() as IButtonStatus
      );
    }
  }, [hasInvestorProfile, idea?.idea_request]);

  const sendRequest = () => {
    // your api goes here
    setRequestStatus("pending");
    handleClose();
  };

  const handleButton = (status: IButtonStatus) => {
    switch (status) {
      case "uninitialized": {
        return (
          <FullButton
            onClick={handleOpen}
            text="Send Access Request"
            type="filled"
            isLocked={!hasInvestorProfile}
          // smStyle={{ width: "100%" }}s
          />
        );
      }
      case "pending": {
        return (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Access request has been sent."
            secondaryText="Please wait for ideaholder for approval."
          />
        );
      }
      case "rejected": {
        return (
          <StatusMessageTemplate
            icon={
              <Icon.Slash
                stroke="#d72222"
                className="icon stroke-width-3 iw-12 ih-12"
              />
            }
            primaryText="Access request has been rejected."
            secondaryText="Please try again after 2 days."
            secondaryTextColor="#d72222"
          />
        );
      }
      case "accepted": {
        return idea?.connect_status?.toLowerCase() === "pending" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been sent."
            secondaryText="Please wait for approval."
          />
        ) : idea?.connect_status?.toLowerCase() === "accepted" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been accepted."
            secondaryText="You are now connected."
          />
        ) : idea?.connect_status?.toLowerCase() === "rejected" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been rejected."
            secondaryText="Please try again."
          />
        ) : (
          <CustomButton
            onClick={handleOpen2}
            text="Connect Now"
            type="filled"
            style={{ width: "100%" }}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const onLikeByInvestor = async (id: number | undefined) => {
    if (hasInvestorProfile) {
      const res = await postLiked({
        idea_id: id,
        liked_by: investorId,
      });
      if (res?.status === 200 || res?.status === 201) {
        setHasLikedPost(1);
        setLike(like + 1);
      }
    } else {
      setInvestorModal(true);
    }
  };

  const onDisLikeByInvestor = async (id: number | undefined) => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }

    const res = await postDisLiked({
      idea_id: id,
      liked_by: investorId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(0);
      setLike(like - 1);
    }
  };

  const onFavByInvestor = async (id: number | undefined) => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }
    const res = await postFavourited({
      idea_id: id,
      favourite_by: investorId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(1);
      setFavourites(favourites + 1);
    }
  };

  const onUnfavByInvestor = async (id: number | undefined) => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }
    const res = await postUnfavouited({
      idea_id: id,
      favourite_by: investorId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(0);
      setFavourites(favourites - 1);
    }
  };

  const onFollowByInvestor = async (id: number | undefined) => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }
    const res = await followIdea({
      idea_id: id,
      followed_by: investorId,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(1);
    }
  };
  const onUnFollowByInvestor = async (id: number | undefined) => {
    if (!hasInvestorProfile) {
      setInvestorModal(true);
      return;
    }
    const res = await unfollowIdea({
      idea_id: id,
      followed_by: investorId,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(0);
    }
  };
  return (
    <div className="content-center">
      <div className="post-panel section-t-space" style={{ marginTop: 0 }}>
        <div className="post-wrapper col-grid-box">
          <TopWrap>
            <GoBackButton text="Go Back" />
            <div className="follow-button-all">
              {+(hasFollowed ?? "") === 1 ? (
                <CustomButton2
                  onClick={() => onUnFollowByInvestor(idea?.id)}
                  text="Followed"
                  type="outlined"
                />
              ) : (
                <CustomButton2
                  onClick={() => onFollowByInvestor(idea?.id)}
                  text="+ Follow"
                  type="filled"
                />
              )}
            </div>
          </TopWrap>

          <div className="idea-title-detail">
            <div className="media">
              <h5>{idea?.idea_sale.type_of_business}</h5>
              <h6 className="light-color">{dateFormatter(idea?.created_at)}</h6>
            </div>
          </div>

          <div className="post-details">
            <div className="detail-box">
              <p>{idea?.idea_sale.business_description}</p>
              {/* <h5 className="tag">
                {idea?.business_idea.tags?.map((item, index) => (
                  <span key={index}>#{item.tag} </span>
                ))}
              </h5> */}
            </div>
            <div className="img-wrapper">
              {idea?.files
                ?.find((obj) => obj?.filename?.startsWith("cover_image"))
                ?.path.endsWith(".mp4") ? (
                <Plyr
                  source={{
                    type: "video",
                    sources: [
                      {
                        src: `${API_BASE_URL}/${idea?.files?.find((obj) =>
                          obj?.filename?.startsWith("cover_image")
                        )?.path
                          }`,
                      },
                    ],
                  }}
                  options={{
                    ratio: "16:9",
                    keyboard: { global: true },
                  }}
                />
              ) : (
                <img
                  loading="lazy"
                  src={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                    obj?.filename?.startsWith("cover_image")
                  )?.path
                    }`}
                  className="img-fluid lazyload"
                  alt=""
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                  }}
                />
              )}
            </div>

            <Box className="likes-section">
              {/* <Box className="people-likes flex-space-between m-bottom-5">
                <Box className="flex-g-5">
                  <IoEarth />
                  {idea?.business_idea.preferred_location}
                </Box>
              </Box> */}
              <Box>
                <div className="title-main m-top m-bottom-10 ">
                  <span>Company Valuation</span>
                </div>
                <ul className="feed-detail-list seperate-line stack-item">
                  <li>
                    <h5>
                      Valuation :
                      <span className="remove-bold">
                        {idea?.idea_sale.valuation}
                      </span>{" "}
                    </h5>
                    <h5>
                      Annual Turnover :{" "}
                      <span className="remove-bold">
                        {idea?.idea_sale.annual_turnover}
                      </span>
                    </h5>
                  </li>

                  <li>
                    <h5>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ flexShrink: 0 }}>Reason for Selling:</div>
                        <span
                          className="remove-bold"
                          style={{ marginLeft: "20px" }}
                        >
                          {idea?.idea_sale.selling_reason}
                        </span>
                      </div>
                    </h5>
                  </li>
                </ul>
                <div className="title-main  m-top m-bottom-10">
                  <span>Documents</span>
                </div>
                <ul className="feed-detail-list stack-item">
                  <li>
                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("business")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith("business")
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                              {/* <DisplayFile
                              title="debt_injection_plan"
                              fileName="Debt Injection plan"
                              path={`${API_BASE_URL}/${
                                idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith(
                                    "debt_injection_plan"
                                  )
                                )?.path
                              }`}
                            /> */}
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        Business Profile{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        Business Profile
                      </h5>
                    )}

                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("feasibility")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith(
                                    "feasibility_study"
                                  )
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        Feasibility Analysis{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        Feasibility Analysis
                      </h5>
                    )}
                  </li>
                  <li>
                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("swot")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith("swot_analysis")
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        SWOT Analysis{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        SWOT Analysis
                      </h5>
                    )}
                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("financial_projection")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith(
                                    "financial_projection"
                                  )
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        Financial Projection{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        Financial Projection
                      </h5>
                    )}
                  </li>

                  <li>
                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("financial_analysis")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith(
                                    "financial_analysis"
                                  )
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        Financial Analysis{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        Financial Analysis
                      </h5>
                    )}
                    {idea?.files?.find((obj) =>
                      obj?.filename?.startsWith("expected")
                    ) ? (
                      <h5 className="remove-bold">
                        <img src={Img} width="16px" alt="pdf" />
                        {idea?.idea_request?.[0]?.status.toLowerCase() ===
                          "accepted" ? (
                          <span className="flex-align-center">
                            <IconButton size="small">
                              <a
                                href={`${API_BASE_URL}/${idea?.files?.find((obj) =>
                                  obj?.filename?.startsWith("expected")
                                )?.path
                                  }`}
                                target="_blank"
                                download
                              >
                                <MdFileDownload />
                              </a>
                            </IconButton>
                          </span>
                        ) : (
                          <span className="flex-align-center">
                            <AiFillLock />
                          </span>
                        )}
                        Expected Valuation{" "}
                      </h5>
                    ) : (
                      <h5 className="remove-bold italic">
                        <img src={Img2} width="16px" alt="pdf" />
                        <span className="flex-align-center">
                          <AiFillLock style={{ fontSize: "0.85rem" }} />
                        </span>
                        Expected Valuation
                      </h5>
                    )}
                  </li>
                </ul>
              </Box>
            </Box>
            <Divider className="hrClass hrFeed" />

            <LastSection className="bottom-section">
              <PostActions
                likes={like ?? 0}
                favourite={favourites ?? 0}
                views={idea?.view_count ?? 0}
                onLiked={() => onLikeByInvestor(idea?.id)}
                onDisliked={() => onDisLikeByInvestor(idea?.id)}
                onFavorite={() => onFavByInvestor(idea?.id)}
                onUnfavourite={() => onUnfavByInvestor(idea?.id)}
                hasLiked={hasLikedPost}
                hasFavourite={hasFav}
                className="w-100"
              >
                {show && (
                  <Box paddingTop="10px">{handleButton(requestStatus)}</Box>
                )}
              </PostActions>
            </LastSection>
            <Divider className="hrClass hrFeed" />
          </div>
        </div>
      </div>
      <InvestorModal open={investorModal} handleClose={handleCloseInvestor} />
      <AccessRequestModal
        open={open}
        handleClose={handleClose}
        onConfirm={sendRequest}
        type='sale'
      />
      <ConnectModal
        open={open2}
        handleClose={handleClose2}
        onConfirm={sendRequest}
        type='sale'
      />
    </div>
  );
}

const StatusMessageTemplate = ({
  icon,
  primaryText,
  secondaryText,
  secondaryTextColor,
}: {
  icon: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  secondaryTextColor?: string;
}) => (
  <Box>
    <Typography variant="body2">
      {icon} {primaryText}
    </Typography>
    <Typography
      letterSpacing="0.03em"
      color={secondaryTextColor ?? PRIMARY_COLOR}
      textAlign="right"
      variant="caption"
    >
      {secondaryText}
    </Typography>
  </Box>
);
