import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, Drawer, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { IDENTITY_SERVER_URL } from "constants/api-constants";
import { INavContent } from "data/BottomNavContent";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import useInvestorState from "hooks/useInvestorState";
import useLoginState from "hooks/useLoginState";
import * as React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoBulbOutline } from "react-icons/io5";
import { RiBriefcase4Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logoutUser } from "redux/features/login/LoginSlice";

const IconText = styled("span")({
  fontSize: "10px",
  display: "block",
  fontWeight: 600,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  justifyContent: "flex-start",
}));

export default function SideDrawer({
  open,
  handleClose,
  list,
  hideIProfile,
}: {
  handleClose: () => void;
  open: boolean;
  list: INavContent[];
  hideIProfile?: boolean;
}) {
  const { isLoggedIn } = useLoginState();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
  };
  const loc = useLocation();
  const currentLoc = loc?.pathname?.split("/")[1];
  const { userDetails } = useLoginState();
  const { hasInvestorProfile } = useInvestorState();
  const { hasIdeaProfile } = useIdeaHolderState();

  return (
    <Drawer
      sx={{
        width: "100vw",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100vw",
        },
      }}
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <IconButton size="large" onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography>
          {currentLoc === "idea" ? "Business Menu" : "Investor Menu"}
        </Typography>
      </DrawerHeader>
      <Divider />

      <List>
        {!hideIProfile && (
          <>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    className="avatar-circle"
                    alt={userDetails?.name ?? ""}
                    src={`${IDENTITY_SERVER_URL}api/image/users/${userDetails?.profile_pic}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={userDetails?.name}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {userDetails?.email}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
            <div className="main-icon">
              <>
                {currentLoc === "idea" && (
                  <>
                    {hasIdeaProfile ? (
                      <ListItem
                        onClick={() => {
                          handleClose();
                        }}
                        disablePadding
                      >
                        <Link
                          style={{
                            color: "#000",
                          }}
                          to="/idea/profile/detail"
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:
                                  "/idea/create-idea" === loc?.pathname
                                    ? "#00acff30"
                                    : "rgba(3, 137, 201, 0.07)",
                                borderRadius: "10px",
                                minWidth: "unset",
                                marginRight: 2,
                                color: "#0389c9",
                              }}
                            >
                              <IoBulbOutline className="svg-font" />
                            </ListItemIcon>
                            <ListItemText primary="Business Profile" />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ) : (
                      <ListItem
                        onClick={() => {
                          handleClose();
                        }}
                        disablePadding
                      >
                        <Link
                          style={{
                            color: "#000",
                          }}
                          to="/idea/profile"
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:
                                  "/idea/create-idea" === loc?.pathname
                                    ? "#00acff30"
                                    : "rgba(3, 137, 201, 0.07)",
                                borderRadius: "10px",
                                minWidth: "unset",
                                marginRight: 2,
                                color: "#0389c9",
                              }}
                            >
                              <AiOutlinePlus className="svg-font" />
                            </ListItemIcon>
                            <ListItemText primary="Create Business Profile" />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    )}

                    {/* <ListItem
                  onClick={() => {
                    handleClose();
                  }}
                  disablePadding
                >
                  <Link to="/idea/profile">
                    <AiOutlinePlus className="svg-font" />
                    <IconText>Idea</IconText>
                  </Link>
                </ListItem> */}
                  </>
                )}
                {currentLoc === "investor" && (
                  <>
                    {hasInvestorProfile ? (
                      <ListItem
                        onClick={() => {
                          handleClose();
                        }}
                        disablePadding
                      >
                        <Link
                          style={{
                            color: "#000",
                          }}
                          to="/investor/detail"
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:
                                  "/idea/create-idea" === loc?.pathname
                                    ? "#00acff30"
                                    : "rgba(3, 137, 201, 0.07)",
                                borderRadius: "10px",
                                minWidth: "unset",
                                marginRight: 2,
                                color: "#0389c9",
                              }}
                            >
                              <RiBriefcase4Line className="svg-font" />
                            </ListItemIcon>
                            <ListItemText primary="View Investor Profile" />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    ) : (
                      <ListItem
                        onClick={() => {
                          handleClose();
                        }}
                        disablePadding
                      >
                        <Link
                          style={{
                            color: "#000",
                          }}
                          to="/investor/profile"
                        >
                          <ListItemButton>
                            <ListItemIcon
                              sx={{
                                width: "45px",
                                height: "45px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:
                                  "/idea/create-idea" === loc?.pathname
                                    ? "#00acff30"
                                    : "rgba(3, 137, 201, 0.07)",
                                borderRadius: "10px",
                                minWidth: "unset",
                                marginRight: 2,
                                color: "#0389c9",
                              }}
                            >
                              <AiOutlinePlus className="svg-font" />
                            </ListItemIcon>
                            <ListItemText primary="Create Investor Profile" />
                          </ListItemButton>
                        </Link>
                      </ListItem>
                    )}

                    {/* <ListItem
                  onClick={() => {
                    handleClose();
                  }}
                  disablePadding
                >
                  <Link to="/idea/profile">
                    <AiOutlinePlus className="svg-font" />
                    <IconText>Idea</IconText>
                  </Link>
                </ListItem> */}
                  </>
                )}
                {/* {currentLoc === "admin" && (
                  <>
                    <ListItem
                      onClick={() => {
                        handleClose();
                      }}
                      disablePadding
                    >
                      <Link
                        style={{
                          color: "#000",
                        }}
                        to="/admin"
                      >
                        <ListItemButton>
                          <ListItemIcon
                            sx={{
                              width: "45px",
                              height: "45px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor:
                                "/idea/create-idea" === loc?.pathname
                                  ? "#00acff30"
                                  : "rgba(3, 137, 201, 0.07)",
                              borderRadius: "10px",
                              minWidth: "unset",
                              marginRight: 2,
                              color: "#0389c9",
                            }}
                          >
                            <AiOutlinePlus className="svg-font" />
                          </ListItemIcon>
                          <ListItemText primary="Idea" />
                        </ListItemButton>
                      </Link>
                    </ListItem>

                    <ListItem
                      onClick={() => {
                        handleClose();
                      }}
                      disablePadding
                    >
                      <Link to="/idea/profile">
                        <AiOutlinePlus className="svg-font" />
                        <IconText>Idea</IconText>
                      </Link>
                    </ListItem>
                  </>
                )} */}
              </>
            </div>
          </>
        )}

        {list?.map((el, index) => (
          <>
            {(() => {
              /* eslint-disable no-fallthrough */
              switch (el?.label) {
                case "divider":
                  return <Divider />;
                // @ts-ignore
                case "Logout":
                  if (!isLoggedIn) {
                    return <></>;
                  }
                default:
                  return (
                    <ListItem
                      onClick={() => {
                        handleClose();
                        if (el?.label === "Logout") handleLogout();
                      }}
                      key={index}
                      disablePadding
                    >
                      <Link
                        style={{
                          color: "#000",
                        }}
                        to={el?.route ?? "#"}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            sx={{
                              width: "45px",
                              height: "45px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor:
                                el?.route === loc?.pathname
                                  ? "#00acff30"
                                  : "rgba(3, 137, 201, 0.07)",
                              borderRadius: "10px",
                              minWidth: "unset",
                              marginRight: 2,
                              color: "#0389c9",
                            }}
                          >
                            {el?.icon}
                          </ListItemIcon>
                          <ListItemText primary={el?.label} />
                        </ListItemButton>
                      </Link>
                    </ListItem>
                  );
              }
              /* eslint-enable no-fallthrough */
            })()}
          </>
        ))}
      </List>
    </Drawer>
  );
}
