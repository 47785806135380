import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import UserDetailRoot from "./UserDetailRoot";
import UserDetailTable from "./UserDetailTable";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export default function UserDetailTabs() {
  return (
    <UserDetailRoot>
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="content-center"
        id="center-content"
      >
        <TabCover className="idea-form">
          <span className="tab-title m-bottom-10">User Details</span>
          <UserDetailTable />
        </TabCover>
      </Box>
    </UserDetailRoot>
  );
}
