import CustomButton from "components/CustomButton";
import Idea from "pages/dashboard/idea/business/IdeaForm";
import { IIdeaType, IIdeaTypeRequest } from "models/Idea";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "redux/app/store";
import { ideaDataRequest } from "redux/features/idea/ideaSlice";
import ApproveModal from "./modal/ApproveModal";
import RejectModal from "./modal/RejectModal";
import ReviewModal from "./modal/ReviewModal";
import GoBackButton from "components/GoBackButton";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { BusinessType } from "pages/dashboard/idea/business/BusinessForm";
import { CiGlobe, CiLock } from "react-icons/ci";
import { getIdea } from "services/ideaService";
import Loader from "components/Loader";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Sale from "pages/dashboard/idea/business/BusinessSaleForm";

const BusinessTypes = [
  {
    label: "Idea" as BusinessType,
    value: "BusinessIdea" as BusinessType,
    icon: <CiLock />,
  },
  {
    label: "Sale" as BusinessType,
    value: "Sale" as BusinessType,
    icon: <CiGlobe />,
  },
];

export default function Detail() {
  const [open, setOpen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [showStatus, setShowStatus] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleShowStatus = () => {
    setShowStatus(!showStatus);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenReview = () => setOpenReview(true);
  const handleCloseReview = () => setOpenReview(false);

  const handleOpenReject = () => setOpenReject(true);
  const handleCloseReject = () => setOpenReject(false);

  const params = useParams<{
    id: string;
  }>();

  useEffect(() => {
    if (params?.id) dispatch(ideaDataRequest({ id: +params?.id }));
  }, [dispatch, params]);

  const [select, setSelect] = useState<BusinessType>("BusinessIdea");
  const [loading, setLoading] = useState(false);
  const [screeningData, setScreeningData] =
    useState<IBusinessTypeResponse | null>(null);

  const getIdeaDetails = async () => {
    if (params?.id) {
      setLoading(true);
      const res = await getIdea(+params?.id);
      if (res?.status === 200 && res?.data) {
        const data: IBusinessTypeResponse = { ...res?.data };
        setSelect(data?.idea_type);
        setScreeningData(data);
        setLoading(false);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as BusinessType);
  };

  useEffect(() => {
    getIdeaDetails();
  }, [params]);

  const location = useLocation();

  const isAdmin = location?.pathname?.split("/")?.includes("admin");

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="title-main flex-space-between m-top-0 m-bottom-0">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GoBackButton text="Go Back" />
            <span>Idea Details</span>
          </div>
          {/* <span>
            {showStatus && (
              <span className="status-ongoing">
                <GiSandsOfTime />
                Ongoing Screening
              </span>
            )}
          </span> */}
        </div>
        <div>
          <FormControl
            sx={{
              margin: "25px 0 15px 0",
              minWidth: "50%",
              paddingRight: "8px",
            }}
          >
            <InputLabel id="select-investor-type-label">
              Business Proposition
            </InputLabel>
            <Select
              labelId="select-investor-type-label"
              id="select-investor-type"
              value={select}
              label="Business Proposition"
              onChange={handleChange}
              style={{ width: "100%" }}
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                },
              }}
              disabled={!!screeningData}
            >
              {BusinessTypes.map((el) => (
                <MenuItem
                  value={el.value}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box display="flex">{el?.icon}</Box>
                  <span className="dropdown-text">{el?.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {screeningData &&
          (screeningData.idea_type === "BusinessIdea" ? (
            <Idea
              data={screeningData as IBusinessTypeResponse}
              isReadOnly={true}
              style1={{ width: "auto", padding: "10px 0px" }}
              style2={{ display: "none" }}
              onSubmit={() => null}
            />
          ) : (
            screeningData.idea_type === "Sale" && (
              <Sale
                data={screeningData as IBusinessTypeResponse}
                isReadOnly={true}
                style1={{ width: "auto", padding: "10px 0px" }}
                style2={{ display: "none" }}
                style3={{ marginTop: "16px" }}
                onSubmit={() => null}
              />
            )
          ))}
        {isAdmin && (
          <div className="box-gap flex-center" style={{ marginTop: "20px" }}>
            {(screeningData?.status !== "Accepted" && screeningData?.status !== "Rejected") && (
              !showStatus ? (
                <CustomButton
                  text="Start Screening"
                  type="filled"
                  onClick={handleShowStatus}
                />
              ) : (
                <>
                  <CustomButton
                    text="Reject"
                    type="filled"
                    color="#e84118"
                    onClick={handleOpenReject}
                  />
                  <RejectModal
                    open={openReject}
                    handleClose={handleCloseReject}
                  />
                  <CustomButton
                    text="Resubmit"
                    type="filled"
                    onClick={handleOpenReview}
                  />
                  <ReviewModal
                    open={openReview}
                    handleClose={handleCloseReview}
                  />

                  <CustomButton
                    text="Approve"
                    type="filled"
                    color="#4cd137"
                    onClick={handleOpen}
                  />
                  <ApproveModal open={open} handleClose={handleClose} />
                </>
              )
            )
            }
          </div>
        )}
      </div>
    </div>
  );
}
