import { takeLatest, put, call } from "redux-saga/effects";
import {
  getIdeaholder,
  getIdeaholderDetail,
} from "../../../services/ideaholderService";
import ideaholderSlice, {
  ideaholderDataRequest,
  ideaholderDetailRequest,
} from "./ideaholderSlice";

function* fetchSaga(action: ReturnType<typeof ideaholderDataRequest>): object {
  yield put(ideaholderSlice.actions.get_ideaholder_data());

  try {
    const data = yield call(getIdeaholder, action.payload.id);
    yield put(ideaholderSlice.actions.success_ideaholder_data(data));
  } catch (e) {
    yield put(ideaholderSlice.actions.error_ideaholder_data(e as Error));
  }
}

function* fetchDetailSaga(
  action: ReturnType<typeof ideaholderDetailRequest>
): object {
  yield put(ideaholderSlice.actions.get_ideaholder_data());

  try {
    const response = yield call(getIdeaholderDetail);
    yield put(ideaholderSlice.actions.success_ideaholder_data(response.data));
  } catch (e) {
    yield put(ideaholderSlice.actions.error_ideaholder_data(e as Error));
  }
}

function* ideaholderSaga() {
  yield takeLatest(ideaholderDataRequest.type, fetchSaga);
  yield takeLatest(ideaholderDetailRequest.type, fetchDetailSaga);
}

export default ideaholderSaga;
