import { Box, Divider, styled } from "@mui/material";
import useLoginState from "hooks/useLoginState";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import {
  followByIdeaHolder,
  postDisLikeByIdeaHolder,
  postFavouriteByIdeaHolder,
  postLikeByIdeaHolder,
  postUnfavouiteByIdeaHolder,
  unfollowByIdeaHolder,
} from "services/InvestorService";
import currencyFormatter from "utils/currencyFormatter";
import CustomButton2 from "./CustomButton2";
import IdeaHolderModal from "./IdeaHolderModal";
import PostActions from "./PostActions";

export const PostWrapper = styled("div")({
  minHeight: "240px",
  width: "100%",
  padding: "25px 35px",
  marginBottom: "15px",
});

export const TitleSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
});

export const DetailBox = styled("div")({
  fontSize: "0.8rem",
  margin: "10px 0",
  "& span": {
    fontFamily: "Roboto",
    color: "#5e5e5e",
  },
  "& .title": {
    fontWeight: "bold",
  },
});
export const ImgBox = styled("div")({
  border: "1px solid #dddd",
  borderRadius: "50%",
  padding: "10px",
  position: "relative",
  width: "45px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ActiveBox = styled("div")({});
export const Type = styled("div")({
  fontFamily: "roboto",
  fontSize: "0.8rem",
  color: "#4c4c4c",
});
export const Title = styled("div")({
  fontWeight: "bold",
  fontSize: "1rem",
});
const Dot = styled("span")({
  fontSize: "10px",
  position: "absolute",
  right: "-5px",
  bottom: "2px",
});
export const Wrap = styled("div")({
  display: "flex",
  gap: "15px",
});

export default function PostComponent({ dl }: { dl: IInvestorType }) {
  const [hasFollowed, setHasFollowed] = useState(dl?.has_followed);
  const [like, setLike] = useState(dl?.like_count ?? 0);
  const [favourites, setFavourites] = useState(dl?.favourite_count ?? 0);
  const [hasLikedPost, setHasLikedPost] = useState(dl?.has_liked);
  const [hasFav, setHasFav] = useState(dl?.has_favourite);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const [likes, setLikes] = useState(dl?.like_count);
  const { userDetails } = useLoginState();

  const onLikeByIdeaHolder = async (id: number | undefined) => {
    const res = await postLikeByIdeaHolder({
      investor_id: id,
      liked_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(1);
      setLike(like + 1);
    }
  };

  const onDisLikeByIdeaHolder = async (id: number | undefined) => {
    const res = await postDisLikeByIdeaHolder({
      investor_id: id,
      liked_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(0);
      setLike(like - 1);
    }
  };

  const onFavByIdeaHolder = async (id: number | undefined) => {
    const res = await postFavouriteByIdeaHolder({
      investor_id: id,
      favorite_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(1);
      setFavourites(favourites + 1);
    }
  };

  const onUnfavByIdeaHolder = async (id: number | undefined) => {
    const res = await postUnfavouiteByIdeaHolder({
      investor_id: id,
      favorite_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(0);
      setFavourites(favourites - 1);
    }
  };

  //follow

  const onFollowByIdeaHolder = async (id: number | undefined) => {
    const res = await followByIdeaHolder({
      investor_id: id,
      follower_user_id: userDetails?.id,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(1);
    }
  };
  const onUnFollowByIdeaHolder = async (id: number | undefined) => {
    const res = await unfollowByIdeaHolder({
      investor_id: id,
      follower_user_id: userDetails?.id,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(0);
    }
  };

  //end followe
  return (
    <PostWrapper className="post-wrapper">
      <TitleSection>
        <Wrap>
          <ImgBox>
            <FaRegUser style={{ fontSize: "1.2rem" }} />
            <Dot>&#128994;</Dot>
          </ImgBox>
          <ActiveBox>
            <Title>{dl.title_designation}</Title>
            <Type>{dl.investor_profile} Investor</Type>
            {dl.interest_in_buying_business === "1" ? (
              <Type>Interested in Buying Businesses</Type>
            ) : (
              <></>
            )}
          </ActiveBox>
        </Wrap>

        <Box>
          {/* {dl?.isFollowed ? (
                <CustomButton2 text="Followed" type="outlined" />
              ) : ( */}
          {hasFollowed === 1 ? (
            <CustomButton2
              onClick={() => onUnFollowByIdeaHolder(dl.id)}
              text="Followed"
              type="outlined"
            />
          ) : (
            <CustomButton2
              onClick={() => onFollowByIdeaHolder(dl.id)}
              text="+ Follow"
              type="filled"
            />
          )}
          {/* )} */}
        </Box>
      </TitleSection>

      <Divider className="hrClass" />
      <DetailBox className="fixed-width-text">
        <span className="title">Investor Details: </span>
        <span>{dl?.investor_detail}</span>
      </DetailBox>
      <DetailBox className="fixed-width-text">
        <span className="title">Interests: </span>
        <span>{dl?.investor_interests ?? <em>Not specified</em>}</span>
      </DetailBox>

      <DetailBox>
        <span className="title">Interested Sectors: </span>
        <span>
          {(dl?.investment_sectors ?? [])?.length < 1 && <em>Not specified</em>}
          {(dl?.investment_sectors ?? [])?.map((sector, index) => (
            <span>
              <>
                {sector?.name}
                {(dl?.investment_sectors ?? [])?.length > index + 1 && ","}{" "}
              </>
            </span>
          ))}
        </span>
      </DetailBox>
      <DetailBox className="d-flex gap-sm  m-top-0">
        {" "}
        <span className="earth">
          <IoEarth />
        </span>
        <span className="title">Preferred Location: </span>{" "}
        <span className="ellipse-box">
          <>{dl?.investment_preferred_location}</>
        </span>
      </DetailBox>
      <DetailBox className="m-top-0">
        {/* <span className="title">Investment Size: </span>
        <span>
          {currencyFormatter(+dl?.min_amount ?? 0)} -{" "}
          {currencyFormatter(+dl?.max_amount ?? 0)}
        </span> */}
        <span className="title">Minimum Investment Amount: </span>
        <span>{`${currencyFormatter(+(dl?.min_amount ?? 0))}`} </span>
      </DetailBox>

      <DetailBox className="m-top-0">
        <span className="title">Maximum Investment Amount: </span>
        <span>{`${currencyFormatter(+(dl?.max_amount ?? 0))}`} </span>
      </DetailBox>

      <Divider className="hrClass hrFeed" />
      <IdeaHolderModal open={open} handleClose={handleClose} />

      <PostActions
        path={`/investor/detail/${dl?.id}`}
        likes={like ?? 0}
        favourite={favourites ?? 0}
        views={dl?.view_count ?? 0}
        onLiked={() => onLikeByIdeaHolder(dl?.id)}
        onDisliked={() => onDisLikeByIdeaHolder(dl?.id)}
        onFavorite={() => onFavByIdeaHolder(dl?.id)}
        onUnfavourite={() => onUnfavByIdeaHolder(dl?.id)}
        hasLiked={hasLikedPost}
        hasFavourite={hasFav}
      />
    </PostWrapper>
  );
}
