import CreateProfileMessage from "components/CreateProfileMessage";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import React, { Suspense } from "react";
import Loader from "../components/Loader";

const IdeaGuardComponent = ({ component }: { component: React.ReactNode }) => {
  const { hasIdeaProfile, status } = useIdeaHolderState();

  if (status === "loading") {
    return <Loader />;
  }

  if (!hasIdeaProfile) {
    return (
      <CreateProfileMessage
        id="center-content"
        description="Hey! Are you sure you have a business profile? Make sure you have a business profile to view the details."
        path="/idea/profile"
        buttonTitle="Create Business Profile"
        message="*Click here to create a business profile now"
      />
    );
  }

  return <Suspense fallback={<Loader />}>{component}</Suspense>;
};

export default IdeaGuardComponent;
