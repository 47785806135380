import { IconButton, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { API_BASE_URL } from "constants/api-constants";
import { IFileType } from "models/Idea";
import { IIdeaholderType, IIdeaholderTypeResponse } from "models/Ideaholder";
import {
  IInvestorFileType,
  IInvestorType,
} from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BsArrowRepeat } from "react-icons/bs";
import CustomButton from "./CustomButton";
import DisplayFile from "./DisplayFile";
import ImageModal from "./ImageModal";
import NotchedOutlineRoot from "./NotchedOutlineRoot";

export default function UploadFile({
  name,
  title,
  isEdit = false,
  fileData,
  changeService,
  imageOnly,
  profile,
  style,
}: {
  name: string;
  title: string;
  isEdit?: boolean;
  fileData?: IFileType | IInvestorFileType;
  imageOnly?: boolean;
  style?: React.CSSProperties;
  profile?: IInvestorType | IIdeaholderType | IIdeaholderTypeResponse | null;
  changeService?: (
    id: number | null,
    e: FormData,
    ideaId?: number
  ) => Promise<AxiosResponse>;
}) {
  const [file, setFile] = useState<File | null>(null);
  const { setValue, getValues, trigger, setError } = useFormContext();
  const btnRef = useRef<HTMLInputElement | null>(null);

  const openPrompt = () => {
    btnRef?.current?.click();
  };

  useEffect(() => {
    setFile(getValues(name));
  }, []);

  useEffect(() => {
    setValue(name, file as File);
    if (file) {
      trigger(name);
    }
  }, [file, name, setValue, trigger]);

  const label = name?.replaceAll("_", " ");

  return (
    <Box style={style}>
      {isEdit ? (
        <DisplayFile
          profile={profile}
          title={name}
          id={fileData?.id}
          fileName={fileData?.filename ?? ""}
          path={`${API_BASE_URL}/${fileData?.path}`}
          changeService={changeService}
        />
      ) : (
        <>
          <input
            ref={btnRef}
            style={{
              display: "none",
            }}
            accept={
              imageOnly
                ? "image/jpeg,image/gif,image/png,image/x-eps,image/webp,image/jpg,video/mp4,video/avi"
                : "*"
            }
            type="file"
            onChange={(e) => {
              if (e?.target?.files?.[0]) {
                if (
                  imageOnly &&
                  !e?.target?.files?.[0]?.type?.startsWith("image/") &&
                  !e?.target?.files?.[0]?.type?.startsWith("video/")
                ) {
                  setError(name, {
                    message: "Invalid file provided. Only images are allowed.",
                  });
                  return;
                }
                setFile(e?.target?.files?.[0]);
                trigger(name);
              }
            }}
            alt="file"
          />
          <FormControl fullWidth sx={{ fontSize: "12px" }}>
            <InputLabel
              required
              shrink={true}
              sx={{ textTransform: "capitalize" }}
            >
              {label}
            </InputLabel>
            <NotchedOutlineRoot label={label + "_"} notched={true} />

            {!file?.name ? (
              <Box
                sx={{
                  height: "56px",
                  padding: "11px 14px",
                }}
              >
                <CustomButton
                  onClick={openPrompt}
                  text={title ?? "Upload"}
                  type="outlined"
                />
              </Box>
            ) : (
              <ImageModal
                fileSize={file?.size}
                fileName={`${name}-abc.${file?.name?.split(".")[1]}`}
                path={URL.createObjectURL(file)}
              >
                <IconButton onClick={openPrompt}>
                  <BsArrowRepeat />
                </IconButton>
              </ImageModal>
            )}
          </FormControl>
        </>
      )}
    </Box>
  );
}
