import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Chip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import EmptyTable from "components/EmptyTable";
import { STATUS_COLOR } from "constants/color-constants";
import { IAccessRequestType } from "models/AccessRequest";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAccessRequest } from "services/accessRequestService";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { useState, useEffect, useContext } from "react";
import { BusinessTypeContext } from "pages/main/MainPage";
import { IBusinessTypeResponse } from "models/IdeaResponse";

export default function InvestorAccessRequestTable({
  data,
}: {
  data: IAccessRequestType[];
}) {

  const { businessType, setBusinessType } = useContext(BusinessTypeContext);

  useEffect(() => {
    setBusinessType("");
  }, [])

  const [filteredIdeas, setFilteredIdeas] = useState<Array<IBusinessTypeResponse | undefined>>([])

  const ideas = data.map(data => data.idea);

  useEffect(() => {
    if (businessType === "BusinessIdea") {
      const filteredIdeasArray = ideas.filter((inData) => inData != undefined ? inData.idea_type === "BusinessIdea" : "")
      setFilteredIdeas(filteredIdeasArray);
    } else if (businessType === "Sale") {
      const filteredIdeasArray = ideas.filter((inData) => inData != undefined ? inData.idea_type === "Sale" : "")
      setFilteredIdeas(filteredIdeasArray);
    }
  }, [businessType])

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <EmptyTable />
      </GridOverlay>
    );
  }

  const deleteAccessRequestService = async (id: number) => {
    const res = await deleteAccessRequest(id);
    if (res.status === 200) {
      toast("Deleted successfully");
    }
  };

  const columns: GridColDef[] = [
    { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
    {
      field: "idea_name",
      headerName: "Business Proposition",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (businessType === "") {
          if (params.row.idea.idea_type === "BusinessIdea") {
            result.push(`${params.row.idea.business_idea?.idea_name}` + ' (Idea)');
          } else if (params.row.idea.idea_type === "Sale") {
            result.push(`${params.row.idea.idea_sale?.company_name}` + ' (Sale)');
          } else {
            result = ["Unknown"];
          }
        } else if (businessType === "BusinessIdea") {
          result.push(`${params.row.business_idea?.idea_name}` + ' (Idea)');
        } else {
          result.push(`${params.row.idea_sale?.company_name}` + ' (Sale)');
        }
        return result.join(", ");
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            <Chip
              label={params?.row?.status}
              variant="filled"
              sx={{
                color: "#fff",
                bgcolor:
                  STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box className="flex-g-10 svg-size">
            {/* <IconButton
              size="small"
              onClick={() => deleteAccessRequestService(params?.row?.id)}
            >
              <AiOutlineDelete />
            </IconButton> */}

            <Link
              to={`/investor/idea-holder/${businessType !== "" ? params?.row?.ideaholder_id : params?.row?.idea?.ideaholder_id}`}
            >
              <IconButton size="small">
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Link>

            <Link to={`/idea/view-detail/${businessType === "" ? params.row.idea_id : params.row.business_idea.idea_id}`}>
              <IconButton size="small">
                <LightbulbIcon />
              </IconButton>
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={businessType === ""
          ? data?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          }) ?? []
          : filteredIdeas?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
        }
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
      />
    </div>
  );
}
