import { IIdeaStatusType } from "pages/dashboard/admin/screening-request/ScreeningReqTabs";
import httpClient from "./httpClient";
import { IBusinessTypeResponse } from "models/IdeaResponse";

export const createIdea = async (data: FormData) => {
  return await httpClient.post("/idea/create", data);
};

export const getAllIdeas = async (status: IIdeaStatusType | null, active?: string) => {
  return await httpClient.get("/idea", {
    params: {
      status,
      active
    },
  });
};

export const getIdea = async (id: number) => {
  return await httpClient.get<IBusinessTypeResponse>(`/idea/${id}`);
};

export const getMyIdea = async () => {
  return await httpClient.get<IBusinessTypeResponse>("/idea/me");
};

export const updateIdea = async (id: string | undefined, data: FormData) => {
  return await httpClient.put(`/idea/${id}/update`, data);
};

export const deleteIdea = async (id: string | undefined) => {
  return await httpClient.delete(`/idea/${id}/delete`);
};

export const updateStatus = async (
  id: number,
  payload: {
    status: string;
    resubmit_reason: string;
  }
) => {
  return await httpClient.put(`/idea/${id}/update-status`, payload);
};

export const rejectStatus = async (payload: {
  idea_id: number;
  resubmit_reason: string | null;
}) => {
  return await httpClient.post("/idea/reject_idea", payload);
};

export const postLiked = async (payload: {
  idea_id: number | undefined;
  liked_by: number | undefined;
}) => {
  return await httpClient.post("/idea-like/like", payload);
};

export const postDisLiked = async (payload: {
  idea_id: number | undefined;
  liked_by: number | undefined;
}) => {
  return await httpClient.post("/idea-like/unlike", payload as any);
};

export const postFavourited = async (payload: {
  idea_id: number | undefined;
  favourite_by: number | undefined;
}) => {
  return await httpClient.post("/idea-favourites/favourite", payload);
};

export const postUnfavouited = async (payload: {
  idea_id: number | undefined;
  favourite_by: number | undefined;
}) => {
  return await httpClient.post("/idea-favourites/unfavorite", payload as any);
};

export const followIdea = async (payload: {
  idea_id: number | undefined;
  followed_by: number | undefined;
}) => {
  return await httpClient.post("/idea-follow/follow", payload);
};

export const unfollowIdea = async (payload: {
  idea_id: number | undefined;
  followed_by: number | undefined;
}) => {
  return await httpClient.post("/idea-follow/unfollow", payload);
};

// export const changeIdeaFile = async (
//   id: number | null,
//   file: FormData,
//   ideaId: number | undefined
// ) => {
//   return await httpClient.put(
//     `/idea/${ideaId}/update-file?file_id=${id}`,
//     file
//   );
// };

export const changeIdeaFile = async (id: number | null, file: FormData) => {
  return await httpClient.put(`/idea/${id}/update-file`, file);
};
