import { call, put, takeLatest } from "redux-saga/effects";
import { getAllInvestor } from "services/InvestorService";
import investorSlice, { investorAllDataRequest } from "./investorAllSlice";

function* fetchSaga(action: ReturnType<typeof investorAllDataRequest>): object {
  yield put(investorSlice.actions.get_investor_all_data());

  try {
    const response = yield call(getAllInvestor, action?.payload?.status, action?.payload?.active);
    yield put(investorSlice.actions.success_investor_all_data(response.data));
  } catch (e) {
    yield put(investorSlice.actions.error_investor_all_data(e as Error));
  }
}

function* investorAllSaga() {
  yield takeLatest(investorAllDataRequest.type, fetchSaga);
}

export default investorAllSaga;
