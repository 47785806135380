import { Badge } from "@mui/material";
import { NovuProvider, useNotifications } from "@novu/notification-center";
import { NOVU_APP_IDENTIFIER, NOVU_URL } from "constants/novu-constants";
import useLoginState from "hooks/useLoginState";
import * as Icon from "react-feather";
import { RiBriefcase4Line } from "react-icons/ri";
import { TbFileInvoice, TbReportSearch } from "react-icons/tb";

const NotiIcon = () => {
  const { unseenCount } = useNotifications();
  return (
    <Badge
      badgeContent={unseenCount}
      sx={{
        ".MuiBadge-badge": {
          color: "#fff",
          bgcolor: "#ff5e57",
        },
      }}
    >
      <Icon.Bell />
    </Badge>
  );
};

const NotificationIcon = ({ subId }: { subId: string }) => {
  return (
    <NovuProvider
      backendUrl={NOVU_URL}
      // socketUrl="wss://novu.scieverinc.com/ws"
      subscriberId={subId}
      applicationIdentifier={NOVU_APP_IDENTIFIER}
      initialFetchingStrategy={{
        fetchUnseenCount: true,
      }}
    >
      <NotiIcon />
    </NovuProvider>
  );
};

const IdeaNotification = () => {
  const loginState = useLoginState();
  // const subId = useSelector((state: RootState) => state.ideaholder?.data?.id);
  return (
    <NotificationIcon subId={loginState.userDetails?.id?.toString() ?? "-1"} />
  );
};

const InvestorNotification = () => {
  const loginState = useLoginState();
  // const subId = useSelector((state: RootState) => state.investor?.data?.id);
  return (
    <NotificationIcon subId={loginState.userDetails?.id?.toString() ?? "-1"} />
  );
};

const AdminNotification = () => {
  const loginState = useLoginState();
  // const subId = useSelector((state: RootState) => state.investor?.data?.id);
  return (
    <NotificationIcon subId={loginState.userDetails?.id?.toString() ?? "-1"} />
  );
};

const commonContent = [
  {
    label: "Logout",
    icon: <Icon.Power />,
    route: "/",
  },
];

const ideaContent = [
  {
    label: "My Businesses",
    icon: <Icon.Sun />,
    route: "/idea/my-ideas",
  },
  {
    label: "Ideas Access Request",
    icon: <Icon.Link2 />,
    route: "/idea/access-request",
  },
  {
    label: "Ideas Proposals",
    icon: <TbFileInvoice className="svg-font" />,
    route: "/idea/proposals",
  },
  {
    label: "Connect",
    icon: <Icon.Link />,
    route: "/idea/connect",
  },
  {
    label: "divider",
    icon: "",
    route: "",
  },
  {
    label: "Account Dashboard",
    icon: <Icon.Settings />,
    route: "/idea/user-profile",
  },

  {
    label: "Notifications",
    icon: <IdeaNotification />,
    route: "/idea/notification",
  },

  ...commonContent,
];

const adminContent = [
  {
    label: "User Details",
    icon: <Icon.User />,
    route: "/admin/user",
  },
  // {
  //   label: "Status",
  //   icon: <Icon.Info />,
  //   route: "/admin/status",
  // },
  {
    label: "Screening Request",
    icon: <TbReportSearch className="svg-font" />,
    route: "/admin/screening-request",
  },
  {
    label: "Access Requests",
    icon: <Icon.Link2 />,
    route: "/admin/access-request",
  },

  // {
  //   label: "divider",
  //   icon: "",
  //   route: "",
  // },
  {
    label: "Investors",
    icon: <RiBriefcase4Line className="svg-font" />,
    route: "/admin/investor-screening",
  },
  {
    label: "Proposals",
    icon: <TbFileInvoice className="svg-font" />,
    route: "/admin/proposals",
  },
  {
    label: "Connect",
    icon: <Icon.Link />,
    route: "/admin/connect",
  },
  // {
  //   label: "Meeting Schedular",
  //   icon: <Icon.Calendar />,
  //   route: "/admin/meeting-scheduler",
  // },
  // {
  //   label: "Agreements",
  //   icon: <FaRegHandshake className="svg-font" />,
  //   route: "/admin/agreements",
  // },
  {
    label: "divider",
    icon: "",
    route: "",
  },
  {
    label: "Account Dashboard",
    icon: <Icon.Settings />,
    route: "/admin/user-profile",
  },
  {
    label: "Notifications",
    icon: <AdminNotification />,
    route: "/admin/notification",
  },

  ...commonContent,
];
const investorContent = [
  {
    label: "Investor Access Request",
    icon: <Icon.Check />,
    route: "/investor/access-request",
  },
  {
    label: "Investor Proposals",
    icon: <TbFileInvoice className="svg-font" />,
    route: "/investor/proposals",
  },
  {
    label: "Connect",
    icon: <Icon.Link />,
    route: "/investor/connect",
  },
  {
    label: "divider",
    icon: "",
    route: "",
  },

  {
    label: "Account Dashboard",
    icon: <Icon.Settings />,
    route: "/investor/user-profile",
  },
  {
    label: "Notifications",
    icon: <InvestorNotification />,
    route: "/investor/notification",
  },
  ...commonContent,
];

const sidebarContent: {
  [key: string]: any[];
} = {
  idea: ideaContent,
  investor: investorContent,
  admin: adminContent,
};

export default sidebarContent;
