import { call, put, takeLatest } from "redux-saga/effects";
import { getAllIdeas, getMyIdea } from "../../../services/ideaService";
import ideaAllSlice, {
  ideaAllDataRequest,
  ideaMyDataRequest,
} from "./ideaAllSlice";

function* fetchSaga(action: ReturnType<typeof ideaAllDataRequest>): object {
  yield put(ideaAllSlice.actions.get_idea_all_data());

  try {
    const response = yield call(getAllIdeas, action?.payload?.status, action?.payload?.active);
    yield put(ideaAllSlice.actions.success_idea_all_data(response.data));
  } catch (e) {
    yield put(ideaAllSlice.actions.error_idea_all_data(e as Error));
  }
}

function* fetchMyIdeaSaga(
  action: ReturnType<typeof ideaMyDataRequest>
): object {
  yield put(ideaAllSlice.actions.get_idea_all_data());

  try {
    const response = yield call(getMyIdea);
    yield put(ideaAllSlice.actions.success_idea_all_data(response.data));
  } catch (e) {
    yield put(ideaAllSlice.actions.error_idea_all_data(e as Error));
  }
}

function* ideaAllSaga() {
  yield takeLatest(ideaAllDataRequest.type, fetchSaga);
  yield takeLatest(ideaMyDataRequest.type, fetchMyIdeaSaga);
}

export default ideaAllSaga;
