import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { updateConnect } from "services/connectService";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

export default function UserConnectReceivedTable({
  data,
  getConnectRequestService,
}: {
  data: any;
  getConnectRequestService: () => void;
}) {
  const updateConnectService = async (
    id: any,
    newStatus: "accepted" | "rejected"
  ) => {
    const res = await updateConnect(id, {
      status: newStatus,
    });
    if (res.status === 200) {
      toast("Connected successfully");
      getConnectRequestService();
    }
  };

  const columns: GridColDef[] = [
    { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
    {
      field: "ideaholder_full_name",
      headerName: "Business Name",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (params.row.idea_holder.full_name) {
          result.push(params.row.idea_holder.full_name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "investor_full_name",
      headerName: "Investor Name",
      flex: 1,
      minWidth: 200,
      valueGetter: (params) => {
        let result = [];
        if (params.row.investor.full_name) {
          result.push(params.row.investor.full_name);
        } else {
          result = ["Unknown"];
        }
        return result.join(", ");
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            <Chip
              label={params?.row?.status}
              variant="filled"
              sx={{
                color: "#fff",
                bgcolor:
                  STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      maxWidth: 180,
      minWidth: 180,
      renderCell: (params) => {
        if (params?.row.status.toLowerCase() === "pending") {
          return (
            <Box className="flex-g-10 svg-size">
              <IconButton
                size="small"
                onClick={() =>
                  updateConnectService(params?.row?.id, "accepted")
                }
              >
                <CheckIcon />
              </IconButton>

              <IconButton
                size="small"
                onClick={() =>
                  updateConnectService(params?.row?.id, "rejected")
                }
              >
                <CloseIcon />
              </IconButton>
            </Box>
          );
        } else {
          const isInvestor =
            params?.row?.requested_by === params?.row?.investor.user_id;

          return (
            <Box className="flex-g-10 svg-size">
              {isInvestor ? (
                <Link to={`/idea/investor/details/${params?.row?.investor.id}`}>
                  <IconButton size="small">
                    <RemoveRedEyeOutlinedIcon />
                  </IconButton>
                </Link>
              ) : (
                <Link
                  to={`/investor/idea-holder/${params?.row?.idea_holder.id}`}
                >
                  <IconButton size="small">
                    <RemoveRedEyeOutlinedIcon />
                  </IconButton>
                </Link>
              )}
            </Box>
          );
        }
        // return (
        //   <Box className="flex-g-10 svg-size">
        //     <Link to={`/idea/investor/details/${params?.row?.investor.id}`}>
        //       <IconButton size="small">
        //         <RemoveRedEyeOutlinedIcon />
        //       </IconButton>
        //     </Link>

        //     <Link to={`/investor/idea-holder/${params?.row?.idea_holder.id}`}>
        //       <IconButton size="small">
        //         <RemoveRedEyeOutlinedIcon />
        //       </IconButton>
        //     </Link>
        //   </Box>
        // );
      },
    },
  ];

  const connectRequest = useSelector((state: RootState) => state?.connectMy);

  return (
    <div style={{ height: 390, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={
          connectRequest?.data?.received.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          }) ?? []
        }
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
}
