import ModalComponent from "components/ModalComponent";
import useLoginState from "hooks/useLoginState";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { getInvestor } from "services/InvestorService";
import { createConnect } from "services/connectService";

interface IProps {
  handleClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

export default function ProposalConnectModal(props: IProps) {
  const { userDetails } = useLoginState();

  const refresh = () => window.location.reload();

  const params = useParams<{
    id: string | undefined;
  }>();

  const ideaholderId = useSelector(
    (state: RootState) => state?.ideaholder?.data?.id
  );

  const [investor, setInvestor] = useState<IInvestorType | null>(null);

  const getInvestorDetails = async () => {
    if (params?.id) {
      const res = await getInvestor(+params?.id);
      if (res?.status === 200 && res?.data) {
        const data: IInvestorType = { ...res?.data };
        setInvestor(data);
      }
    }
  };

  useEffect(() => {
    getInvestorDetails();
  }, [params]);

  const requestedById = userDetails?.id;

  const requestedToId = investor?.user_id;

  const sendConnectRequest = async () => {
    const res = await createConnect({
      investor_id: params?.id ?? 0,
      ideaholder_id: ideaholderId ?? 0,
      requested_by: requestedById ?? 0,
      requested_to: requestedToId ?? 0,
    });
    if (res.status === 201 || res.status === 200) {
      toast("Connect request sent successfully. Please wait until approval.");
      props.handleClose();
    }
  };

  return (
    <ModalComponent
      title="Connect Now!"
      subTitle="Are you sure you want to connect with this investor?"
      {...props}
      onRefresh={refresh}
      onConfirm={sendConnectRequest}
    />
  );
}
