import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Box } from "@mui/material";
import data from "data/SuggestedInvestors";
import { Autoplay } from "swiper";
import "swiper/css";

export default function SuggestionBox() {
  return (
    <div
      className="suggestion-box section-t-space"
      style={{ marginTop: "10px", width: "100%" }}
    >
      <div className="card-title">
        <h3>Suggested Investors</h3>
      </div>
      <div className="suggestion-content ratio_115">
        <div className="slide-2 no-arrow default-space">
          <Swiper
            modules={[Autoplay]}
            autoplay={{ delay: 4000 }}
            spaceBetween={10}
            slidesPerView={2.2}
            style={{
              height: 150,
            }}
          >
            {data?.map((dl: any) => (
              <>
                <SwiperSlide>
                  <Box height="100%">
                    <Box className="story-box" height="100%">
                      <div className={`adaptive-overlay ${dl.class}`}></div>
                      <div className="story-bg">
                        <img
                          src={dl.src}
                          className="img-fluid bg-img"
                          data-adaptive-background="1"
                          alt=""
                        />
                      </div>
                      <div className="story-content">
                        <h6>{dl.name}</h6>
                        <span>active now</span>
                      </div>
                    </Box>
                  </Box>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
