import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdeaholderType } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { GenericState } from "redux/app/genericState";

export type IDataType = {
  investor: IInvestorType;
  idea_holder: IIdeaholderType;
  created_at: string;
  deleted_at: string;
  id: number;
  status: string;
  updated_at: string;
  ideaholder_id: number | undefined;
  investor_id: number;
};

interface ConnectState extends GenericState<IDataType[]> {}

const initialState: ConnectState = {
  data: [],
  error: undefined,
  status: "none",
};

export const connectAllDataRequest = createAction("CONNECT_ALL_DATA_REQUEST");

const connectAllSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    get_connect_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_connect_all_data: (state, action: PayloadAction<IDataType[]>) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_connect_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default connectAllSlice;
