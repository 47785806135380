import { AxiosRequestConfig } from "axios";

interface AxiosHeaders {
  [key: string]: string;
}

export const authInterceptor =
  (localstorageKey: string) =>
  async <T extends object>(config: AxiosRequestConfig<T>) => {
    const token = await localStorage.getItem(localstorageKey);

    const header = config.headers as AxiosHeaders;

    if (!header?.Authorization) {
      Object.assign(config, {
        headers: {
          Authorization: `Bearer ${token ?? null}`,
        },
      });
    }

    return config;
  };
