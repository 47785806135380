import {
  Autocomplete,
  Box,
  createFilterOptions,
  Grid,
  TextField,
} from "@mui/material";
import { CountryCodeType } from "hooks/usePhoneValidator";
import { useState } from "react";
import { countries } from "services/json/countries";

// const useStyles = makeStyles({
//   root: {
//     paddingRight: 10,
//   },
//   option: {
//     fontSize: 15,
//     "& > span": {
//       fontSize: 18,
//     },
//   },
// });

function PhoneCountry({
  countryCode,
  setCountryCode,
}: {
  countryCode: CountryCodeType;
  setCountryCode: (e: CountryCodeType) => void;
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const filterOptions = createFilterOptions<CountryCodeType>({
    stringify: (option) => {
      return `${option.code} +${option.phone}`;
    },
  });

  return (
    <Grid container>
      <Grid flexGrow={1}>
        <Box position="relative">
          <Box
            position="absolute"
            left="10px"
            top="50%"
            style={{
              transform: "translateY(-50%)",
            }}
          >
            <img
              loading="lazy"
              width={20}
              src={`https://flagcdn.com/w20/${countryCode?.code?.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${countryCode?.code?.toLowerCase()}.png 2x`}
              alt={`Map of ${countryCode.code}`}
            />
          </Box>
          <Autocomplete
            open={open}
            onOpen={() => {
              // only open when in focus and inputValue is not empty
              if (inputValue) {
                setOpen(true);
              }
            }}
            onClose={() => setOpen(false)}
            options={countries as unknown as CountryCodeType[]}
            autoHighlight
            disableClearable={true}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            getOptionLabel={(option) => {
              return `${option?.code} +${option?.phone}`;
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                paddingRight: "20px !important",
              },
              "& input": {
                marginLeft: "20px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Country Code"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option?.code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option?.code?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.code} +{option.phone}
              </Box>
            )}
            value={countryCode}
            onChange={(e, value) => {
              value?.code && setCountryCode(value);
            }}
            inputValue={inputValue}
            onInputChange={(e, value, reason) => {
              setInputValue(value);

              // only open when inputValue is not empty after the user typed something
              if (!value) {
                setOpen(false);
              }
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const PhoneCountryInput = ({
  countryCode,
  setCountryCode,
  children,
}: {
  countryCode: CountryCodeType;
  setCountryCode: (e: CountryCodeType) => void;
  children: React.ReactNode;
}) => (
  <Grid container spacing={1}>
    <Grid item xs={5}>
      <PhoneCountry countryCode={countryCode} setCountryCode={setCountryCode} />
    </Grid>
    <Grid item xs={7}>
      {children}
    </Grid>
  </Grid>
);

export default PhoneCountryInput;
