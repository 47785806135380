import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

export default function useLoginState() {
  const login = useSelector((state: RootState) => state?.login);

  const isLoggedIn =
    login?.status === "loaded" &&
    login?.data?.userDetails &&
    login?.data?.tokens;

  const isAdmin =
    login?.status === "loaded" &&
    login?.data?.userDetails &&
    login?.data?.userDetails?.roles?.some((el) => el?.name === "ad" || el?.name === "sa");

  return {
    status: login?.status ?? "none",
    userDetails: login?.data?.userDetails ?? null,
    tokens: login?.data?.tokens ?? null,
    isLoggedIn: !!isLoggedIn,
    isAdmin,
  };
}
