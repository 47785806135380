import { Box } from "@mui/material";
import { styled } from "@mui/system";
import CustomButton from "components/CustomButton";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ideaMyDataRequest } from "redux/features/idea/ideaAllSlice";
import MyIdeasTable from "./MyIdeasTable";
import IdeaSaleFilter from "components/IdeaSaleFilter";

const Title = styled("div")({
  fontWeight: "bold",
  fontSize: "1.1rem",
  marginBottom: "20px",
});
const TableCover = styled("div")({
  height: "100%",
  width: "100%",
});

export default function MyIdeasTabs() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ideaMyDataRequest());
  }, [dispatch]);

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TableCover className="idea-form">
        <Box className="flex-space-between">
          <Title>My Businesses</Title>

          <Box display="flex" gap="5px" alignItems="flex-start">
            <IdeaSaleFilter />

            <Link to="/idea/business">
              <CustomButton text="+ Create Business Proposition" type="filled" />
            </Link>
          </Box>
        </Box>
        <MyIdeasTable />
      </TableCover>
    </Box>
  );
}
