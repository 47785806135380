import { API_BASE_URL } from "constants/api-constants";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import ImageModal from "./ImageModal";
import CustomButton from "./CustomButton";
import { useState } from "react";
import ProfileDeactivationModal from "components/ProfileDeactivationModal";
import { useDispatch } from "react-redux";
import { ideaholderDetailRequest } from "redux/features/ideaholder/ideaholderSlice";

export default function MoreIdeaDetailCard({
  data,
  hideDeactivate
}: {
  data?: IIdeaholderTypeResponse | null;
  hideDeactivate?: boolean
}) {
  const [showDeactivate, setShowDeactivate] = useState(false);

  const dispatch = useDispatch();

  const deactivateProfile = () => {
    setShowDeactivate(false)
    dispatch(ideaholderDetailRequest());
  };

  return (
    <div className="about-profile section-b-space flex-2">
      <div className="card-title" style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Other Informations</h3>
        {
          !hideDeactivate && data && (
            <CustomButton text={data.active === "true" ? "Deactivate" : "Activate" } type="filled" color={data.active === "true" ? "#e84118" : "#4cd137"} onClick={() => setShowDeactivate(true)} />
          )
        }
      </div>

      {/* <ModalComponent
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        title="Deactivate"
        subTitle="Are you sure you want to deactivate your business profile?"
      /> */}

      <ProfileDeactivationModal
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        onConfirm={deactivateProfile}
        data={data}
        businessOrInvestor="Business"
      />

      <ul className="about-list">
        {data?.connect_status === "Accepted" || data?.connect_status === undefined ?
          <>
            <li>
              <span className="m-d">Uploaded Documents</span>
            </li>
            {data?.files
              ?.filter((x) => x?.filename?.split("-")?.[0] !== "profile_pic")
              .map((el) => (
                <li>
                  <h5 className="title">{el?.filename?.split("-")?.[0]}</h5>
                  <h6 className="content">
                    <ImageModal
                      fileName={el?.filename}
                      path={`${API_BASE_URL}/${el?.path}`}
                    />
                  </h6>
                </li>
              ))}
          </>
          :
          ""
        }
        <li>
          <span className="m-d">Permanent Location</span>
        </li>
        <li>
          <h5 className="title"> Municipality</h5>
          <h6 className="content">{data?.permanent_location_municipality}</h6>
        </li>
        <li>
          <h5 className="title"> Area Name</h5>
          <h6 className="content">{data?.permanent_location_area}</h6>
        </li>
        <li>
          <h5 className="title">Street Name</h5>
          <h6 className="content">{data?.permanent_location_street}</h6>
        </li>
        <li>
          <span className="m-d">Temporary Location</span>
        </li>
        <li>
          <h5 className="title"> Municipality</h5>
          <h6 className="content">
            {data?.temporary_location_municipality === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_municipality
            )}
          </h6>
        </li>
        <li>
          <h5 className="title"> Area Name</h5>
          <h6 className="content">
            {data?.temporary_location_area === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_area
            )}
          </h6>
        </li>
        <li>
          <h5 className="title">Street Name</h5>
          <h6 className="content">
            {data?.temporary_location_street === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_street
            )}
          </h6>
        </li>
      </ul>
    </div>
  );
}

const Details = ({ data }: { data?: string }) => (
  <>{data ?? <em>Not specified</em>}</>
);
