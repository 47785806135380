import { styled } from "@mui/system";
import ProfileIcon from "components/ProfileIcon";
import sidebarContent from "data/SidebarContent";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import useInvestorState from "hooks/useInvestorState";
import useLoginState from "hooks/useLoginState";
import { Fragment } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoBulbOutline } from "react-icons/io5";
import { RiBriefcase4Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link, NavLink as NavLinkBase, useLocation } from "react-router-dom";
import { logoutUser } from "redux/features/login/LoginSlice";

const NavLink = styled(NavLinkBase)({
  display: "inline-block",
  padding: "5px 0",
});

const IconText = styled("span")({
  fontSize: "10px",
  display: "block",
  fontWeight: 600,
});
export default function Sidebar() {
  const dispatch = useDispatch();
  const loc = useLocation();
  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const { userDetails, isLoggedIn } = useLoginState();
  const { hasInvestorProfile } = useInvestorState();
  const { hasIdeaProfile } = useIdeaHolderState();

  const currentLoc = loc?.pathname?.split("/")[1];
  return (
    <div className="sidebar-panel sidebar-white">
      <div className="main-icon">
        <>
          {currentLoc === "idea" && (
            <>
              {hasIdeaProfile ? (
                <NavLink
                  to="/idea/profile/detail"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <IoBulbOutline className="svg-font" />
                  <IconText>Business Profile</IconText>
                </NavLink>
              ) : (
                <NavLink
                  to="/idea/profile"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <AiOutlinePlus className="svg-font" />
                  <IconText>Business Profile</IconText>
                </NavLink>
              )}
            </>
          )}
          {currentLoc === "investor" && (
            <>
              {hasInvestorProfile ? (
                <NavLink
                  to="/investor/detail"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <RiBriefcase4Line fontSize={"1.6rem"} />
                  <IconText>Investor Profile</IconText>
                </NavLink>
              ) : (
                <NavLink
                  to="/investor/profile"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <AiOutlinePlus className="svg-font" />
                  <IconText>Investor Profile</IconText>
                </NavLink>
              )}
            </>
          )}
          {currentLoc === "admin" && (
            <Link to="/admin">
              <RiBriefcase4Line className="svg-font" />
              <IconText>Admin</IconText>
            </Link>
          )}
        </>
      </div>
      <ul className="sidebar-icon">
        {sidebarContent[currentLoc]?.map((el, index) => (
          <Fragment key={index}>
            {el?.label === "divider" ? (
              <div
                key={el}
                style={{
                  borderBottom: "1px solid rgba(3, 137, 201, 0.2)",
                  marginBlock: "10px",
                }}
              ></div>
            ) : (
              <>
                {el?.label === "Logout" && !isLoggedIn ? (
                  <></>
                ) : (
                  <li
                    key={index}
                    className={el?.route === loc?.pathname ? "active" : ""}
                    onClick={() => el?.label === "Logout" && handleLogout()}
                  >
                    <Link to={el?.route}>
                      {el?.label === "Settings" ? (
                        <>
                          {isLoggedIn ? (
                            <ProfileIcon
                              name={userDetails?.name ?? ""}
                              profile={userDetails?.profile_pic}
                              sx={{
                                backgroundColor: "transparent",
                              }}
                            />
                          ) : (
                            <>{el?.icon}</>
                          )}
                        </>
                      ) : (
                        <>{el?.icon}</>
                      )}
                      <div className="tooltip-cls">
                        <span>{el?.label}</span>
                      </div>
                    </Link>
                  </li>
                )}
              </>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
}
