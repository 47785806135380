import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { GenericState } from "redux/app/genericState";

interface IdeaState extends GenericState<IBusinessTypeResponse | null> {}

const initialState: IdeaState = {
  data: null,
  error: undefined,
  status: "none",
};

export const ideaDataRequest = createAction<{
  id: number;
}>("IDEA_DATA_REQUEST");

const ideaSlice = createSlice({
  name: "idea",
  initialState,
  reducers: {
    get_idea_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_idea_data: (
      state,
      action: PayloadAction<IBusinessTypeResponse>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_idea_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default ideaSlice;
