import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineLike,
  AiTwotoneLike,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import numberFormatter from "utils/numberFormatter";
import FullButton from "./FullButton";

const LastSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
});

interface IPostActions {
  children?: React.ReactNode;
  path?: string;
  likes: number;
  onLiked: () => void;
  favourite: number;
  onFavorite: () => void;
  onUnfavourite: () => void;
  views: number;
  className?: string;
  hasLiked?: number;
  hasFavourite?: number;
  onDisliked: () => void;
}

export default function PostActions({
  path,
  likes,
  onLiked,
  favourite,
  onFavorite,
  onUnfavourite,
  views,
  hasLiked,
  className,
  hasFavourite,
  onDisliked,
  children,
}: IPostActions) {
  return (
    <LastSection
      className={className}
      sx={{
        gap: 1,
        flexDirection: "row",
        "@media all and (max-width: 428px)": {
          "> div": {
            width: "100%",
          },
          flexDirection: "column-reverse",
          gap: "0px",
          marginTop: "0px",
        },
      }}
    >
      <Box>
        {children}
        {path && (
          <Link to={path ?? ""}>
            <FullButton text="View Detail" type="filled" />
          </Link>
        )}
      </Box>
      <Box className="reacts" justifyContent="space-around">
        <Box className="reacts-box">
          {hasLiked === 1 ? (
            <IconButton onClick={onDisliked}>
              <AiTwotoneLike style={{ color: "#0389c9" }} />
            </IconButton>
          ) : (
            <IconButton onClick={onLiked}>
              <AiOutlineLike />
            </IconButton>
          )}

          <span>{numberFormatter(likes)}</span>
        </Box>
        <Box className="reacts-box">
          {hasFavourite === 1 ? (
            <IconButton onClick={onUnfavourite}>
              <AiFillHeart style={{ color: "#eb2f06" }} />
            </IconButton>
          ) : (
            <IconButton onClick={onFavorite}>
              <AiOutlineHeart />
            </IconButton>
          )}
          <span>{numberFormatter(favourite)}</span>
        </Box>
        <Box className="reacts-box">
          <Box className="view-svg">
            <AiOutlineEye className="svg-size" />
          </Box>
          <span>{numberFormatter(views)}</span>
        </Box>
      </Box>
    </LastSection>
  );
}
