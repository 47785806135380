import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import useInvestorState from "hooks/useInvestorState";
import { IAccessRequestStatusType } from "pages/dashboard/admin/access-request/AccessRequestTabs";
import * as React from "react";
import IdeaSaleFilter from "components/IdeaSaleFilter";
import { toast } from "react-toastify";
import { getInvestorAccessRequests } from "services/accessRequestService";
// import { ideaAccessInvestorDataRequest } from "redux/features/access-request/accessRequestAllSlice";
import AccessRequestTable from "./InvestorAccessRequestTable";
import { IDataType } from "redux/features/connect/connectAllSlice";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});
export default function InvestorAccessRequestTabs() {
  const [value, setValue] = React.useState<IAccessRequestStatusType>("all");
  const [isFetched, setIsFetched] = React.useState(false);
  const [display, setDisplay] = React.useState([]);

  const { investorProfile } = useInvestorState();

  // const dispatch = useDispatch();

  const getAccessRequestService = async () => {
    if (investorProfile?.id) {
      const res = await getInvestorAccessRequests(
        investorProfile?.id,
        value === "all" ? null : value
      );
      if (res?.status === 200 || res.status === 201) {
        setDisplay(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  };

  React.useEffect(() => {
    getAccessRequestService();
  }, [investorProfile?.id, value]);

  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    if (investorProfile?.id) {
      const res = await getInvestorAccessRequests(investorProfile?.id, null)
      if (res?.status === 200 || res.status === 201) {
        setAllData(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [investorProfile?.id, value]);

  const acceptedCount = allData.filter((data: IDataType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IDataType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IDataType) => data.status === "Pending").length

  // React.useEffect(() => {
  //   if (investorProfile?.id)
  //     dispatch(
  //       ideaAccessInvestorDataRequest({
  //         id: investorProfile?.id,
  //         status: value === "all" ? null : value,
  //       })
  //     );
  // }, [dispatch, investorProfile?.id, isFetched, value]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IAccessRequestStatusType
  ) => {
    setIsFetched(false);
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title"> Investor Access Request</span>
        <TabContext value={value}>
          <Box display='flex' justifyContent='space-between'>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"All" + ` (${allData.length})`}
                  value="all"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Pending" + ` (${pendingCount})`}
                  value="pending"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Accepted" + ` (${acceptedCount})`}
                  value="accepted"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Rejected" + ` (${rejectedCount})`}
                  value="rejected"
                />
              </TabList>
            </Box>
            <Box mt="30px">
              <IdeaSaleFilter />
            </Box>
          </Box>

          <Box mt="-16px">
            <TabPanel value="all">
              <AccessRequestTable data={display} />
            </TabPanel>
            <TabPanel value="pending">
              <AccessRequestTable data={display} />
            </TabPanel>
            <TabPanel value="accepted">
              <AccessRequestTable data={display} />
            </TabPanel>
            <TabPanel value="rejected">
              <AccessRequestTable data={display} />
            </TabPanel>
          </Box>
        </TabContext>
      </TabCover>
    </Box>
  );
}
