import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import RightContent from "../right-content/RightContent";
import ProfileBox from "./components/ProfileBox";
import SuggestionBox from "./components/SuggestionBox";

export default function LeftContent() {
  return (
    <>
      <div className="content-left">
        <div className="sticky-top">
          <ProfileBox />
          <Box className="suggestion-display2">
            <SuggestionBox />
          </Box>
        </div>
        {/* <div className="sticky-top">
          <LikedPages />
        </div> */}
      </div>
      <Outlet />
      <RightContent />
    </>
  );
}
