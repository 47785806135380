import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { userAllDataRequest } from "redux/features/users/userAllSlice";
import userSlice, { userDataRequest } from "redux/features/users/userSlice";
import WithChildren from "utils/WithChildren";

const UserDetailRoot: React.FC<WithChildren> = ({ children }) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(userAllDataRequest());
  }, [dispatch]);

  React.useEffect(() => {
    if (typeof id === "string" && !isNaN(parseInt(id))) {
      dispatch(userDataRequest({ id }));
    } else {
      dispatch(userSlice.actions.error_user(Error("User ID not Valid")));
    }
  }, [dispatch, id]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default UserDetailRoot;
