import { takeLatest, put, call } from "redux-saga/effects";
import { getAllIdeaholders } from "../../../services/ideaholderService";
import ideaholderAllSlice, {
  ideaholderAllDataRequest,
} from "./ideaholderAllSlice";

function* fetchSaga(action: ReturnType<typeof ideaholderAllDataRequest>): object {
  yield put(ideaholderAllSlice.actions.get_ideaholder_all_data());

  try {
    const response = yield call(getAllIdeaholders);
    yield put(
      ideaholderAllSlice.actions.success_ideaholder_all_data(response.data)
    );
  } catch (e) {
    yield put(ideaholderAllSlice.actions.error_ideaholder_all_data(e as Error));
  }
}

function* ideaholderAllSaga() {
  yield takeLatest(ideaholderAllDataRequest.type, fetchSaga);
}

export default ideaholderAllSaga;
