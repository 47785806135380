import axios from "axios";
import httpClient from "./httpClient";
import identityClient from "./identityClient";

export const getUserData = async (token?: string) => {
  try {
    return await identityClient.get("/api/user", {
      headers: {
        Authorization: token ? `Bearer ${token}` : null,
      },
    });
  } catch (e) {
    console.error({ e });
  }
};

export const getDefaultCountryService = async () => {
  return await axios.get("https://ipapi.co/json");
};

export const getAllUsers = async () => {
  return await httpClient.get("/authentication/users");
}

export const getSingleUser = async (id: string) => {
  return await httpClient.get(`/authentication/user/${id}`);
}
