import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { GenericState } from "redux/app/genericState";

interface InvestorState extends GenericState<IInvestorType | null> {}

const initialState: InvestorState = {
  data: null,
  error: undefined,
  status: "none",
};

export const investorDataRequest = createAction<{
  id: number;
}>("INVESTOR_DATA_REQUEST");

export const investorDetailRequest = createAction("INVESTOR_DETAIL_REQUEST");

const investorSlice = createSlice({
  name: "investor",
  initialState,
  reducers: {
    get_investor_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_investor_data: (
      state,
      action: PayloadAction<IInvestorType | null>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_investor_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default investorSlice;
