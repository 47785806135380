import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { GenericState } from "redux/app/genericState";

interface InvestorState extends GenericState<IInvestorType[]> {}

const initialState: InvestorState = {
  data: [],
  error: undefined,
  status: "none",
};

export const investorAllDataRequest = createAction<{
  status: "accepted" | "rejected" | "resubmit" | "pending" | "re-review" | null;
  active?: "true" | "false";
}>("INVESTOR_ALL_DATA_REQUEST");

const investorAllSlice = createSlice({
  name: "investor",
  initialState,
  reducers: {
    get_investor_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_investor_all_data: (
      state,
      action: PayloadAction<IInvestorType[]>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_investor_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default investorAllSlice;
