import styled from "@emotion/styled";
import {
  Alert,
  Autocomplete,
  createFilterOptions,
  Divider,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ISaleType } from "models/Sale";
import * as yup from "yup";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import UploadFile from "components/UploadFile";
import DisplayFile from "components/DisplayFile";
import { API_BASE_URL } from "constants/api-constants";
import GoogleLibNumber from "google-libphonenumber";
import CustomButton from "components/CustomButton";
import usePhoneValidator, {
  CountryCodeType,
  defaultCode,
} from "hooks/usePhoneValidator";
import { useMemo, useState } from "react";
import { IBusinessType } from "./BusinessForm";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { changeIdeaFile } from "services/ideaService";
import PhoneCountryInput from "components/PhoneCountry";
import useLoginState from "hooks/useLoginState";

const DividerRoot = styled(Divider)({
  marginTop: "10px",
});

const phoneUtil = GoogleLibNumber.PhoneNumberUtil.getInstance();

const typeOfBusiness: readonly BusinessOptionType[] = [
  { inputValue: "Manufacturing", title: "Manufacturing" },
  { inputValue: "Trading", title: "Trading" },
  { inputValue: "IT", title: "IT" },
];

interface BusinessOptionType {
  inputValue?: string;
  title: string;
}

const filter = createFilterOptions<BusinessOptionType>();

const imageOnlyField = ["cover_image_or_video"];

const DESCRIPTION_LIMIT = 500;

const validationResolver = (phoneCode: string, update?: boolean) => {
  return {
    schema: yup.object().shape({
      company_name: yup
        .string()
        .required("Company Name is required")
        .max(70, "Text must be less than 70 characters"),
      latest_share_lagat: yup
        .mixed()
        .test(
          "required",
          "You need to provide the latest share lagat",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      address: yup.string().required("Address is required"),
      email: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
      phone: yup
        .string()
        .trim()
        .required("Phone is  required")
        .test(
          "is_phone_valid",
          "Phone number needs to be valid",
          function (value) {
            if (!value?.length) {
              return this.createError({
                message: "Phone number not provided.",
              });
            }
            try {
              const number = phoneUtil.parse(value, phoneCode);

              return phoneUtil.isValidNumber(number);
            } catch (err) {
              return this.createError({
                message: "Phone number is not correct",
              });
            }
          }
        ),
      postal_code: yup.string().required("Postal Code is required"),
      cover_image_or_video: yup
        .mixed()
        .test(
          "required",
          "You need to provide a cover image or video",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      moa: yup
        .mixed()
        .test("required", "You need to provide a MOA", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      aoa: yup
        .mixed()
        .test("required", "You need to provide a AOA", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      pan: yup
        .mixed()
        .test("required", "You need to provide a PAN", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      tax_clearance: yup
        .mixed()
        .test(
          "required",
          "You need to provide a tax clearance document",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      ocr_update: yup
        .mixed()
        .test("required", "You need to provide an OCR update", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      audit_report: yup
        .mixed()
        .test(
          "required",
          "You need to provide an audit report",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      type_of_business: yup.string().required("Type of business is required"),
      valuation: yup
        .number()
        .typeError("Valuation must be a number")
        .required("Valuation is required")
        .max(400000000, "Amount cannot exceed 400000000"),
      annual_turnover: yup
        .number()
        .typeError("Annual Turnover must be a number")
        .required("Annual Turnover is required")
        .max(400000000, "Amount cannot exceed 400000000"),
      business_description: yup
        .string()
        .required("Description is required")
        .max(DESCRIPTION_LIMIT, "Text must be less than 500 characters"),
      business_profile: yup
        .mixed()
        .test(
          "required",
          "You need to provide a business profile",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      feasibility_study: yup
        .mixed()
        .test(
          "required",
          "You need to provide a feasibility study",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      swot_analysis: yup
        .mixed()
        .test(
          "required",
          "You need to provide a SWOT analysis",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      financial_projection: yup
        .mixed()
        .test(
          "required",
          "You need to provide a financial projection",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      financial_analysis: yup
        .mixed()
        .test(
          "required",
          "You need to provide a financial analysis",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      expected_valuation: yup
        .mixed()
        .test(
          "required",
          "You need to provide a expected valuation",
          (file: File) => {
            if (update || file) return true;
            return false;
          }
        )
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      selling_reason: yup
        .string()
        .required("Description is required")
        .max(DESCRIPTION_LIMIT, "Text must be less than 500 characters"),
    }),
  };
};

// const filter = createFilterOptions<string>();

export default function Sale({
  onSubmit,
  data,
  isReadOnly = false,
  loading,
  viewFor,
  defaultCountryCode,
  style1,
  style2,
  style3,
}: {
  onSubmit: (e: IBusinessType) => void;
  data?: IBusinessTypeResponse | null;
  isReadOnly?: boolean;
  loading?: boolean;
  viewFor?: string | number;
  defaultCountryCode?: CountryCodeType;
  style1?: React.CSSProperties;
  style2?: React.CSSProperties;
  style3?: React.CSSProperties;
}) {
  // phone
  const [trackCountryCode, setTrackCountryCode] = useState(
    defaultCountryCode ?? defaultCode
  );
  // end phone

  const registerSchema = useMemo(() => {
    const { schema } = validationResolver(
      trackCountryCode?.code ?? "np",
      !!data?.id
    );
    return schema;
  }, [trackCountryCode]);

  const { userDetails, isLoggedIn } = useLoginState();

  const phoneNo = userDetails?.phone_no?.toString().split(" ");

  const default_phone_no = phoneNo !== undefined ? phoneNo[1] : "";

  const default_address = userDetails?.location !== null ? `${userDetails?.location?.street?.street_name}, ${userDetails?.location?.area?.area_name}, ${userDetails?.location?.municipality?.name}` : ""

  const hookForm = useForm<ISaleType>({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      business_type: "Sale",
      company_name: data?.idea_sale.company_name ?? "",
      latest_share_lagat: null,
      address: data?.idea_sale.address ?? default_address,
      email: data?.idea_sale.email ?? userDetails?.email,
      phone: data?.idea_sale.mobile_no ?? default_phone_no,
      postal_code: data?.idea_sale.postal_code ?? "",
      cover_image_or_video: null,
      moa: null,
      aoa: null,
      pan: null,
      tax_clearance: null,
      ocr_update: null,
      audit_report: null,
      type_of_business: data?.idea_sale.type_of_business ?? "",
      valuation: data?.idea_sale.valuation ?? "",
      annual_turnover: data?.idea_sale.annual_turnover ?? "",
      business_description: data?.idea_sale.business_description ?? "",
      business_profile: null,
      feasibility_study: null,
      swot_analysis: null,
      financial_projection: null,
      financial_analysis: null,
      expected_valuation: null,
      selling_reason: data?.idea_sale.selling_reason ?? "",
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = hookForm;

  const [text, setText] = useState<BusinessOptionType | null>(null);

  const { ideaProfile } = useIdeaHolderState();

  //for phone validation

  const {
    watch: watchPhone,
    setValue: setPhoneValue,
    control: controlPhone,
  } = usePhoneValidator({
    useRegisterForm: hookForm,
    defaultCountryCode: defaultCountryCode ?? defaultCode,
  });

  const code = watchPhone("code");
  const setCountry = (code: CountryCodeType) => {
    setTrackCountryCode(code);
    setPhoneValue("code", code);
  };

  // end phone validation

  const checkFile = (name: string) => {
    if ((data?.files ?? [])?.length > 0) {
      const exists = data?.files?.find(
        (el) => el?.filename?.split("-")?.[0] === name
      );
      if (exists) {
        return (
          <UploadFile
            name={name}
            profile={ideaProfile}
            imageOnly={imageOnlyField?.includes(name)}
            fileData={exists}
            changeService={changeIdeaFile}
            isEdit={!!data}
            title={exists?.filename?.split("-")?.[0]?.replaceAll("_", " ")}
          />
        );
      } else {
        return (
          <UploadFile
            profile={ideaProfile}
            imageOnly={imageOnlyField?.includes(name)}
            name={name}
            isEdit={!!data}
            title={`Upload your ${name?.replaceAll("_", " ")}`}
            changeService={changeIdeaFile}
          // style={{ marginTop: "10px" }}
          />
        );
      }
    } else {
      return (
        <UploadFile
          imageOnly={imageOnlyField?.includes(name)}
          isEdit={!!data}
          name={name}
          title={`Upload your ${name?.replaceAll("_", " ")}`}
        />
      );
    }
  };

  const loggedInIdeaholderId = ideaProfile?.id;
  const ideaOwnerIdeaholderId = data?.ideaholder_id;

  const CanEditAlert: React.FC = () => {
    return isReadOnly && loggedInIdeaholderId === ideaOwnerIdeaholderId ? (
      <Alert severity="info" sx={{ marginBottom: "10px" }}>
        You cannot edit your idea until the admin has reviewed it.
      </Alert>
    ) : (
      <></>
    );
  };

  const Reasons: React.FC = () => {
    const _data = data as any;
    return _data?.status_reason &&
      loggedInIdeaholderId === ideaOwnerIdeaholderId ? (
      <Alert severity="warning" sx={{ marginBottom: "10px" }}>
        {_data?.status} Reason: {_data?.status_reason}
      </Alert>
    ) : (
      <></>
    );
  };

  return (
    <>
      <CanEditAlert />
      <Reasons />

      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...hookForm}>
          <div className="form-wrap">
            <div className="form-group">
              <div className="title-main" style={style2}>
                <span>Company Details</span>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} style={style3}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Company Name"
                    type="text"
                    autoComplete="Company Name"
                    fullWidth
                    required
                    {...register("company_name")}
                    error={!!errors?.company_name}
                    helperText={errors?.company_name?.message}
                  />
                </Grid>
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("latest_share_lagat")) {
                      return (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {!isReadOnly && (
                  <Grid item xs={12} sm={12} md={6}>
                    {checkFile("latest_share_lagat")}
                    {errors.latest_share_lagat && (
                      <FormHelperText error>
                        {errors.latest_share_lagat?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Address"
                    type="text"
                    autoComplete="Address"
                    fullWidth
                    required
                    {...register("address")}
                    error={!!errors?.address}
                    helperText={errors?.address?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Email"
                    type="text"
                    autoComplete="Email"
                    fullWidth
                    required
                    {...register("email")}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <PhoneCountryInput
                    countryCode={code}
                    setCountryCode={setCountry}
                  >
                    <Controller
                      name="num"
                      control={controlPhone}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: isReadOnly,
                          }}
                          label="Phone Number"
                          type="tel"
                          autoComplete="current-phone"
                          fullWidth
                          required
                          error={!!errors?.phone}
                          helperText={errors?.phone?.message}
                        />
                      )}
                    />
                  </PhoneCountryInput>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Postal Code"
                    type="text"
                    autoComplete="Postal Code"
                    fullWidth
                    required
                    {...register("postal_code")}
                    error={!!errors?.postal_code}
                    helperText={errors?.postal_code?.message}
                  />
                </Grid>
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("cover_image")) {
                      return (
                        <Grid item xs={12} sm={12} md={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {!isReadOnly && (
                  <Grid item xs={12} sm={12} md={6}>
                    {checkFile("cover_image_or_video")}
                    {errors.cover_image_or_video && (
                      <FormHelperText error>
                        {errors.cover_image_or_video?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>

              <DividerRoot className="hrClass" />

              <Grid container spacing={2}>
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("moa")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("aoa")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("pan")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("tax_clearance")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("ocr")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("audit_report")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
              </Grid>

              {/* Displaying all of the below files */}

              {!isReadOnly && (
                <>
                  <div className="title-main">
                    <span>Company Documents</span>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("moa")}
                      {errors.moa && (
                        <FormHelperText error>
                          {errors.moa?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("aoa")}
                      {errors.aoa && (
                        <FormHelperText error>
                          {errors.aoa?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("pan")}
                      {errors.pan && (
                        <FormHelperText error>
                          {errors.pan?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("tax_clearance")}
                      {errors.tax_clearance && (
                        <FormHelperText error>
                          {errors.tax_clearance?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("ocr_update")}
                      {errors.ocr_update && (
                        <FormHelperText error>
                          {errors.ocr_update?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("audit_report")}
                      {errors.audit_report && (
                        <FormHelperText error>
                          {errors.audit_report?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Displaying all of the below files */}
              <DividerRoot className="hrClass" />

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="type_of_business"
                    control={control}
                    defaultValue={data?.idea_sale.type_of_business ?? ""}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onChange={(event, newValue) => {
                          if (typeof newValue === "string") {
                            setText({
                              title: newValue,
                            });
                            field.onChange(newValue);
                          } else if (newValue && newValue.inputValue) {
                            setText({
                              title: newValue.inputValue,
                            });
                            field.onChange(newValue.inputValue);
                          } else {
                            setText(newValue);
                            field.onChange("");
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          const isExisting = options.some(
                            (option) => inputValue === option.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        fullWidth
                        handleHomeEndKeys
                        id="free-solo-with-text-demo"
                        options={typeOfBusiness}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.title;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.title}</li>
                        )}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Type new or select"
                            label="Type of Business"
                            error={!!errors?.type_of_business}
                            helperText={errors?.type_of_business?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Valuation"
                    type="number"
                    min="1"
                    autoComplete="Valuation"
                    fullWidth
                    required
                    {...register("valuation")}
                    error={!!errors?.valuation}
                    helperText={errors?.valuation?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    id="outlined-password-input"
                    label="Annual Turnover"
                    type="number"
                    min="1"
                    autoComplete="Annual Turnover"
                    fullWidth
                    required
                    {...register("annual_turnover")}
                    error={!!errors?.annual_turnover}
                    helperText={errors?.annual_turnover?.message}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Controller
                    name="business_description"
                    control={control}
                    render={({ field }) => {
                      const { value } = field;
                      return (
                        <TextField
                          {...field}
                          InputProps={{
                            readOnly: isReadOnly,
                          }}
                          inputProps={{
                            maxlength: DESCRIPTION_LIMIT,
                          }}
                          fullWidth
                          required
                          multiline
                          rows={4}
                          label="Business Description"
                          {...register("business_description")}
                          error={!!errors?.business_description}
                          helperText={
                            value?.length +
                            "/" +
                            DESCRIPTION_LIMIT +
                            (errors?.business_description?.message
                              ? ` ${errors?.business_description?.message}`
                              : "")
                          }
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("business_profile")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("feasibility_study")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("swot_analysis")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("financial_projection")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("financial_analysis")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
                {(data?.files ?? [])?.length > 0 &&
                  isReadOnly &&
                  data?.files?.map((el, index) => {
                    if (el.filename?.startsWith("expected_valuation")) {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <DisplayFile
                            id={el.id}
                            readonly={isReadOnly}
                            title={el.filename
                              ?.split("-")?.[0]
                              ?.replaceAll("_", " ")}
                            fileName={el.filename}
                            path={`${API_BASE_URL}/${el.path}`}
                          />
                        </Grid>
                      );
                    }
                    return null;
                  })}
              </Grid>

              {/* Displaying all of the below files */}

              {!isReadOnly && (
                <>
                  <div className="title-main">
                    <span>Upload the given entitled files</span>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("business_profile")}
                      {errors.business_profile && (
                        <FormHelperText error>
                          {errors.business_profile?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("feasibility_study")}
                      {errors.feasibility_study && (
                        <FormHelperText error>
                          {errors.feasibility_study?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("swot_analysis")}
                      {errors.swot_analysis && (
                        <FormHelperText error>
                          {errors.swot_analysis?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("financial_projection")}
                      {errors.financial_projection && (
                        <FormHelperText error>
                          {errors.financial_projection?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("financial_analysis")}
                      {errors.financial_analysis && (
                        <FormHelperText error>
                          {errors.financial_analysis?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {checkFile("expected_valuation")}
                      {errors.expected_valuation && (
                        <FormHelperText error>
                          {errors.expected_valuation?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={12} md={12} marginTop={2}>
                <Controller
                  name="selling_reason"
                  control={control}
                  render={({ field }) => {
                    const { value } = field;
                    return (
                      <TextField
                        {...field}
                        InputProps={{
                          readOnly: isReadOnly,
                        }}
                        inputProps={{
                          maxlength: DESCRIPTION_LIMIT,
                        }}
                        fullWidth
                        required
                        multiline
                        rows={4}
                        label="Reason for Selling"
                        {...register("selling_reason")}
                        error={!!errors?.selling_reason}
                        helperText={
                          value?.length +
                          "/" +
                          DESCRIPTION_LIMIT +
                          (errors?.selling_reason?.message
                            ? ` ${errors?.selling_reason?.message}`
                            : "")
                        }
                      />
                    );
                  }}
                />
              </Grid>

              <i style={{ color: "gray" }}>*Please wait 24 hours for approval.</i>

              {!isReadOnly && (
                <div
                  className="box-gap flex-center"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <CustomButton
                    isSubmit={true}
                    loading={loading}
                    text={data ? "Update Proposition" : "+ Create Proposition"}
                    type="filled"
                  // onClick={handleOpen}
                  />
                </div>
              )}
              {/* <ScreeningIdeaModal open={open} handleClose={handleClose} /> */}
            </div>
          </div>
        </FormProvider>
      </form>
    </>
  );
}
