import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  RadioGroup,
  Radio
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IUserPreferenceSettings } from "@novu/notification-center";
import CustomButton from "components/CustomButton";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { ReactNode, useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "redux/app/store";
import { ideaMyDataRequest } from "redux/features/idea/ideaAllSlice";
import { getMyIdea } from "services/ideaService";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  maxHeight: "500px",
  overflow: "scroll",
  "::-webkit-scrollbar": {
    width: "0.5em",
    height: "0.5em",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "1em",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: "transparent",
  },
};

interface IProps {
  handleClose: () => void;
  open: boolean;
  title: string;
  subTitle: string;
  children?: React.ReactNode;
  onConfirm?: () => void;
  onRefresh?: () => void;
  onMenuItemSelect?: (selectedItemId: number) => void;
  path?: string;
  userPreferences?: IUserPreferenceSettings[];
  onCheckboxChange?: (
    templateId: string,
    channelType: string,
    checked: boolean
  ) => void;
}

export default function ModalComponent({
  title,
  subTitle,
  open,
  handleClose,
  children,
  path,
  onConfirm,
  onRefresh,
  onMenuItemSelect,
  userPreferences,
  onCheckboxChange,
}: IProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ideaMyDataRequest());
  }, [dispatch]);

  const ideas = useSelector((state: RootState) => state?.ideaAll);

  // const [ideas, setIdeas] = useState<IBusinessTypeResponse | null>(null);

  // const getIdeaDetails = async () => {
  //   const res = await getMyIdea();
  //   if (res?.status === 200 && res?.data) {
  //     const data: IBusinessTypeResponse = { ...res?.data };
  //     setIdeas(data);
  //   }
  // };

  // useEffect(() => {
  //   getIdeaDetails();
  // }, []);

  const handleMenuItemChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const selectedItemId = event.target.value as number;
    if (onMenuItemSelect !== undefined) onMenuItemSelect(selectedItemId);
  };

  const [propositionType, setPropositionType] = useState<string>("idea");

  const handlePropositionTypeChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    setPropositionType(event.target.value as string)
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} className="modal-cross">
            <RxCross1 />
          </IconButton>
          <Box className="modal-text">
            <h5>{title}</h5>
            {children && children}
            <h6>{subTitle}</h6>
            {title === "Send Proposal" ? (
              <>
                <h6>
                  Select one of your propositions to send to the investor (only
                  your accepted ideas can be sent)
                </h6>

                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">Select Proposition Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={propositionType}
                    onChange={handlePropositionTypeChange}
                  >
                    <FormControlLabel value="idea" control={<Radio />} label="Business Idea" />
                    <FormControlLabel value="sale" control={<Radio />} label="Business Sale" />
                  </RadioGroup>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "20px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Select {propositionType === "idea" ? 'Business Idea' : propositionType === "sale" ? 'Business Sale' : 'Proposition'}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select a Proposition"
                    onChange={handleMenuItemChange}
                  >

                    {propositionType === "idea"
                      ? ideas &&
                      ideas.data
                        .filter((idea) => idea.status === "Accepted")
                        .filter((idea) => idea.idea_type === "BusinessIdea")
                        .map((idea) => {
                          return (
                            <MenuItem key={idea.id} value={idea.id}>
                              {idea.business_idea?.idea_name} - {idea.business_idea?.idea_description.length > 30
                                ? idea.business_idea?.idea_description.slice(
                                  0,
                                  30
                                ) + "..."
                                : idea.business_idea?.idea_description}
                            </MenuItem>
                          )
                        })
                      : propositionType === "sale"
                        ? ideas &&
                        ideas.data
                          .filter((idea) => idea.status === "Accepted")
                          .filter((idea) => idea.idea_type === "Sale")
                          .map((idea) => {
                            return (
                              <MenuItem key={idea.id} value={idea.id}>
                                {idea.idea_sale?.company_name} - {idea.idea_sale?.business_description.length > 30
                                  ? idea.idea_sale?.business_description.slice(
                                    0,
                                    30
                                  ) + "..."
                                  : idea.idea_sale?.business_description}
                              </MenuItem>
                            )
                          })
                        :
                        <MenuItem disabled>Please Select a Proposition Type</MenuItem>
                    }

                    {/* {ideas &&
                      ideas.data
                        .filter((idea) => idea.status === "Accepted")
                        .map((idea) => {
                          console.log(idea)
                          return (
                            <MenuItem key={idea.id} value={idea.id}>
                              {propositionType === 'idea'
                                ? `${idea.business_idea?.idea_name} - ${idea.business_idea?.idea_description.length > 30
                                  ? idea.business_idea?.idea_description.slice(
                                    0,
                                    30
                                  ) + "..."
                                  : idea.business_idea?.idea_description}`
                                : `${idea.idea_sale?.company_name} - ${idea.idea_sale?.business_description.length > 30
                                  ? idea.idea_sale?.business_description.slice(
                                    0,
                                    30
                                  ) + "..."
                                  : idea.idea_sale?.business_description}`
                              } */}

                    {/* {idea.idea_type === "BusinessIdea"
                                ? idea.business_idea?.idea_name
                                : idea.idea_sale?.company_name}{" "}
                              -{" "}
                              {idea.idea_type === "BusinessIdea"
                                ? idea.business_idea?.idea_description.length > 30
                                  ? idea.business_idea?.idea_description.slice(
                                    0,
                                    30
                                  ) + "..."
                                  : idea.business_idea?.idea_description
                                : idea.idea_sale?.business_description.length > 30
                                  ? idea.idea_sale?.business_description.slice(
                                    0,
                                    30
                                  ) + "..."
                                  : idea.idea_sale?.business_description} */}

                    {/* {idea.idea_type === "BusinessIdea"
                                ? <Typography color="gray">&nbsp;(Idea)</Typography>
                                : <Typography color="gray">&nbsp;(Business)</Typography>
                              } */}

                    {/* </MenuItem>
                          )
                        })} */}
                  </Select>
                </FormControl>
              </>
            ) : null}
            {title === "Notification Settings" ? (
              <>
                {userPreferences &&
                  userPreferences.map((userPreference) => {
                    return (
                      <div key={userPreference.template._id}>
                        <h5>{userPreference.template.name}</h5>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={userPreference.preference.channels.email}
                              onChange={(event) => {
                                if (onCheckboxChange) {
                                  onCheckboxChange(
                                    userPreference.template._id,
                                    "email",
                                    event.target.checked
                                  );
                                }
                              }}
                            />
                          }
                          label="Email"
                        />
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={userPreference.preference.channels.sms}
                              onChange={(event) => {
                                if (onCheckboxChange) {
                                  onCheckboxChange(
                                    userPreference.template._id,
                                    "sms",
                                    event.target.checked
                                  );
                                }
                              }}
                            />
                          }
                          label="SMS"
                        /> */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                userPreference.preference.channels.in_app
                              }
                              onChange={(event) => {
                                if (onCheckboxChange) {
                                  onCheckboxChange(
                                    userPreference.template._id,
                                    "in_app",
                                    event.target.checked
                                  );
                                }
                              }}
                            />
                          }
                          label="In-App"
                        />
                        {/* <FormControlLabel
                          control={
                            <Checkbox
                              checked={userPreference.preference.channels.chat}
                              onChange={(event) => {
                                if (onCheckboxChange) {
                                  onCheckboxChange(
                                    userPreference.template._id,
                                    "chat",
                                    event.target.checked
                                  );
                                }
                              }}
                            />
                          }
                          label="Chat"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={userPreference.preference.channels.push}
                              onChange={(event) => {
                                if (onCheckboxChange) {
                                  onCheckboxChange(
                                    userPreference.template._id,
                                    "push",
                                    event.target.checked
                                  );
                                }
                              }}
                            />
                          }
                          label="Push Notification"
                        /> */}
                      </div>
                    );
                  })}
              </>
            ) : (
              <Box className="flex-end">
                <CustomButton
                  onClick={handleClose}
                  text="Cancel"
                  type="outlined"
                />

                {onConfirm ? (
                  <CustomButton
                    onClick={() => {
                      onConfirm();
                      if (onRefresh) {
                        setTimeout(onRefresh, 5000);
                      }
                    }}
                    text="Confirm"
                    type="filled"
                  />
                ) : (
                  <Link to={path ?? "#"}>
                    <CustomButton text="Confirm" type="filled" />
                  </Link>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
