import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Chip, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import EmptyTable from "components/EmptyTable";
import { STATUS_COLOR } from "constants/color-constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "redux/app/store";
import { useContext, useEffect, useState } from "react";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { BusinessTypeContext } from "pages/main/MainPage";

const columns: GridColDef[] = [
  { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
  {
    field: "idea_name",
    headerName: "Business Proposition",
    flex: 1,
    minWidth: 250,
    valueGetter: (params) => {
      let result = [];
      if (params.row.idea_type === "BusinessIdea") {
        result.push(`${params.row.business_idea?.idea_name}` + ' (Idea)');
      } else if (params.row.idea_type === "Sale") {
        result.push(`${params.row.idea_sale?.company_name}` + ' (Sale)');
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  { field: "view_count", headerName: "Views", flex: 1, minWidth: 100 },
  {
    field: "like_count",
    headerName: "Likes",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "favourite_count",
    headerName: "Favorites",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "follow_count",
    headerName: "Follows",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Chip
            label={params?.row?.status}
            variant="filled"
            sx={{
              color: "#fff",
              bgcolor:
                STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
            }}
          />
        </Box>
      );
    },
  },
  {
    field: "status_reason",
    headerName: "Reason",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "Action",
    headerName: "Action",
    width: 150,
    renderCell: (params) => {
      return (
        <Box className="flex-g-10 svg-size">
          {params.row.status === "Resubmit" && (
            <Box>
              <Link to={`/idea/business/${params?.row?.id}`}>
                <IconButton size="small">
                  <EditIcon />
                </IconButton>
              </Link>
            </Box>
          )}

          <Box>
            <Link to={`/idea/view-detail/${params?.row?.id}`}>
              <IconButton size="small">
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Link>
          </Box>

          {/* <IconButton size="small" onClick={() => deleteIdea(params?.row?.id)}>
            <MdDeleteOutline />
          </IconButton> */}
        </Box>
      );
    },
  },
];

export default function MyIdeasTable() {

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <EmptyTable />
      </GridOverlay>
    );
  }
  const ideas = useSelector((state: RootState) => state?.ideaAll);

  const { businessType, setBusinessType } = useContext(BusinessTypeContext);

  useEffect(() => {
    setBusinessType("");
  }, [])

  const [filteredIdeas, setFilteredIdeas] = useState<Array<IBusinessTypeResponse>>([])

  useEffect(() => {
    if (businessType === "BusinessIdea") {
      const filteredIdeasArray = ideas?.data.filter((inData) => inData.idea_type === "BusinessIdea")
      setFilteredIdeas(filteredIdeasArray);
    } else if (businessType === "Sale") {
      const filteredIdeasArray = ideas?.data.filter((inData) => inData.idea_type === "Sale")
      setFilteredIdeas(filteredIdeasArray);
    }
  }, [businessType])

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        disableColumnSelector
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={businessType === ""
          ? ideas?.data?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
          : filteredIdeas?.map((el: IBusinessTypeResponse, index: number) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
        }
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
      />
    </div>
  );
}
