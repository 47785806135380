import { call, put, takeLatest } from "redux-saga/effects";
import { getAllConnects } from "services/connectService";
import connectAllSlice, { connectAllDataRequest } from "./connectAllSlice";

function* fetchSaga(action: ReturnType<typeof connectAllDataRequest>): object {
  yield put(connectAllSlice.actions.get_connect_all_data());

  try {
    const response = yield call(getAllConnects);
    yield put(connectAllSlice.actions.success_connect_all_data(response.data));
  } catch (e) {
    yield put(connectAllSlice.actions.error_connect_all_data(e as Error));
  }
}

function* connectAllSaga() {
  yield takeLatest(connectAllDataRequest.type, fetchSaga);
}

export default connectAllSaga;
