import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  "@media (max-width: 768px)": {
    width: 445,
  },
};

interface IProps {
  handleClose: () => void;
  open: boolean;
  children?: React.ReactNode;
}

export default function MessageModal({ open, handleClose, children }: IProps) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} className="modal-cross">
            <RxCross1 />
          </IconButton>
          {children}
        </Box>
      </Modal>
    </div>
  );
}
