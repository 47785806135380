import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "redux/app/genericState";

export type IAnalyticsData = {
  access_request_count: number;
  idea_count: number | null | undefined;
  proposal_count: number;
  top_idea: ITopIdeas[];
};

export type ITopIdeas = {
  business_idea: {
    idea_name: string;
  };

  idea_sale: string | number | null;
  raise_investment: string | number | null;
  view_count: number | null | undefined;
};

interface AnalyticsState extends GenericState<IAnalyticsData | null> {}

const initialState: AnalyticsState = {
  data: null,
  error: undefined,
  status: "none",
};

export const analyticsIdeaData = createAction("ANALYTICS_IDEA_DATA");

const analyticsSlice = createSlice({
  name: "analytics-idea",
  initialState,
  reducers: {
    get_analytics_idea_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_analytics_idea_data: (
      state,
      action: PayloadAction<IAnalyticsData>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_analytics_idea_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default analyticsSlice;
