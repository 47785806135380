import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "redux/app/store";
import { getInvestor, getMyInvestorProfile } from "services/InvestorService";
import { IMunicipality } from "../location/muniSlice";
import investorSlice, {
  investorDataRequest,
  investorDetailRequest,
} from "./investorSlice";

function* fetchSaga(action: ReturnType<typeof investorDataRequest>): object {
  yield put(investorSlice.actions.get_investor_data());

  try {
    const response = yield call(getInvestor, action.payload.id);
    yield put(investorSlice.actions.success_investor_data(response.data));
  } catch (e) {
    yield put(investorSlice.actions.error_investor_data(e as Error));
  }
}

function* fetchDetailSaga(
  action: ReturnType<typeof investorDetailRequest>
): object {
  yield put(investorSlice.actions.get_investor_data());

  try {
    const response = yield call(getMyInvestorProfile);
    let data;
    const muniData: IMunicipality[] = yield select(
      (state: RootState) => state?.municipalities?.data
    );

    if (response?.data) {
      data = {
        ...(response?.data ?? {}),
        permanent_location_municipality: {
          id: null,
          name: response.data?.permanent_location_municipality,
        },
        permanent_location_street: {
          id: null,
          street_name: response.data?.permanent_location_street,
        },
        permanent_location_area: {
          id: null,
          area_name: response.data?.permanent_location_area,
        },
        temporary_location_municipality: {
          id: null,
          name: response.data?.temporary_location_municipality,
        },
        temporary_location_street: {
          id: null,
          street_name: response.data?.temporary_location_street,
        },
        temporary_location_area: {
          id: null,
          area_name: response.data?.temporary_location_area,
        },
      };
    } else {
      data = null;
    }

    yield put(investorSlice.actions.success_investor_data(data));
  } catch (e) {
    yield put(investorSlice.actions.error_investor_data(e as Error));
  }
}

function* investorSaga() {
  yield takeLatest(investorDataRequest.type, fetchSaga);
  yield takeLatest(investorDetailRequest.type, fetchDetailSaga);
}

export default investorSaga;
