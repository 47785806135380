import { Box } from "@mui/material";
import AccordionComponent from "components/AccordionComponent";
import GoBackButton from "components/GoBackButton";
import IdeaDetailCard from "components/IdeaDetailCard";
import UserDetailCard from "components/InvestorDetailCard";
import Loader from "components/Loader";
import MoreIdeaDetailCard from "components/MoreIdeaDetailCard";
import MoreUserDetails from "components/MoreInvestorDetails";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/app/store";
import UserDetailRoot from "./UserDetailRoot";

function UserTypeView() {
  const userSingleState = useSelector((state: RootState) => state?.userSingle);

  switch (userSingleState.status) {
    case "error":
      return <>Error Loading...</>;
    case "loaded":
      const data = userSingleState.data;
      if (!Array.isArray(data)) {
        return <>Received Unexpected Response from server</>;
      }
      const investorData = data.length > 0 ? (data[0] as IInvestorType) : null;

      const investor: IInvestorType = {
        ...investorData,
        permanent_location_municipality: {
          id: null,
          //@ts-ignore
          name: investorData?.permanent_location_municipality,
        },
        permanent_location_street: {
          id: null,
          //@ts-ignore
          street_name: investorData?.permanent_location_street,
        },
        permanent_location_area: {
          id: null,
          //@ts-ignore
          area_name: investorData?.permanent_location_area,
        },
        temporary_location_municipality: {
          id: null,
          //@ts-ignore
          name: investorData?.temporary_location_municipality,
        },
        temporary_location_street: {
          id: null,
          //@ts-ignore
          street_name: investorData?.temporary_location_street,
        },
        temporary_location_area: {
          id: null,
          //@ts-ignore
          area_name: investorData?.temporary_location_area,
        },
      };

      const ideaData =
        data.length > 1 ? (data[1] as IIdeaholderTypeResponse) : null;
      return (
        <>
          {ideaData && (
            <AccordionComponent title="View Business Details">
              <Box className="page-content" display="flex">
                <Box className="profile-about flex-1">
                  <IdeaDetailCard details={ideaData} />
                </Box>
                <MoreIdeaDetailCard data={ideaData} />
              </Box>
            </AccordionComponent>
          )}
          {investorData && (
            <AccordionComponent title="View Investor Details">
              <Box className="page-content" display="flex">
                <Box className="profile-about flex-1">
                  <UserDetailCard details={investor} />
                </Box>
                <MoreUserDetails data={investor} />
              </Box>
            </AccordionComponent>
          )}
        </>
      );
    default:
      return <Loader />;
  }
}

function UserDetailView() {
  const userAllState = useSelector((state: RootState) => state?.userAll);
  const userSingleState = useSelector((state: RootState) => state?.userSingle);
  const { id } = useParams();

  switch (userAllState.status) {
    case "error":
      return <>Error Getting Users</>;
    case "loaded":
      const data = userAllState.data?.users?.find(
        (u) => u?.id?.toString() === id
      );

      if (!data) {
        return <>User with the id not found.</>;
      }

      const types = ["User"];
      if (userSingleState?.data) {
        const singleData = userSingleState.data;
        if (singleData.length > 0 && singleData[0]) {
          types.push("Investor");
        }

        if (singleData.length > 1 && singleData[1]) {
          types.push("Idea Holder");
        }
      }

      return (
        <Box
          sx={{ width: "100%", typography: "body1", pb: 4 }}
          className="content-center"
          id="center-content"
        >
          <Box className="idea-form" minHeight={"300px"}>
            <Box>
              <Box className="top-form about-profile remove-bg-and-box-shadow ">
                <GoBackButton text="Go Back" />
                <h3>Basic Details</h3>
                {/* start */}
                <ul className="about-list p-10-0">
                  <li>
                    <h5 className="title">Name</h5>
                    <h6 className="content">{data.name}</h6>
                  </li>

                  <li>
                    <h5 className="title">Email</h5>
                    <h6 className="content">{data.email}</h6>
                  </li>

                  <li>
                    <h5 className="title">Phone</h5>
                    <h6 className="content">{data.phone_no}</h6>
                  </li>
                  <li>
                    <h5 className="title">Type</h5>
                    <h6 className="content">{types.join(", ")}</h6>
                  </li>
                  {/* <li>
                    <h5 className="title">Description</h5>
                    <h6 className="content">{data?.}</h6>
                  </li> */}
                </ul>
                {/* end */}
              </Box>
            </Box>
          </Box>
          <UserTypeView />
        </Box>
      );
    default:
      return <Loader />;
  }
}

export default function _UserDetailView() {
  return (
    <UserDetailRoot>
      <UserDetailView />
    </UserDetailRoot>
  );
}
