import { Box } from "@mui/material";
import NoInvestor from "assets/svg/no-investor.svg";
import NoPost from "components/NoPost";
import PostComponent from "components/PostComponent";
import InvestorPostSkeleton from "components/skeleton/InvestorPostSkeleton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { investorAllDataRequest } from "redux/features/investor/investorAllSlice";

export default function InvestorFeed() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      investorAllDataRequest({
        status: "accepted",
        active: "true"
      })
    );
  }, [dispatch]);

  const acceptedInvestors =
    useSelector((state: RootState) => state?.investorAll?.data) ?? [];

  const fetchStatus = useSelector(
    (state: RootState) => state?.investorAll?.status
  );

  return (
    <Box className="post-panel section-t-space" style={{ marginTop: 0 }}>
      {fetchStatus === "loading" ? (
        <>
          {[1, 2, 3, 4, 5]?.map((el) => (
            <InvestorPostSkeleton key={el} />
          ))}
        </>
      ) : (
        <>
          {acceptedInvestors?.length < 1 && (
            <NoPost section="Investor" image={NoInvestor} />
          )}
          {acceptedInvestors?.map((dl, index) => (
            <PostComponent key={index} dl={dl} />
          ))}
        </>
      )}
    </Box>
  );
}
