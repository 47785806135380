import axios, { AxiosInstance } from "axios";
import { IDENTITY_SERVER_URL } from "constants/api-constants";
import { authInterceptor } from "./authInterceptor";

const identityClient: AxiosInstance = axios.create({
  baseURL: IDENTITY_SERVER_URL,
});

identityClient.interceptors.request.use(
  authInterceptor("syi:access-token"),
  (er) => {
    return Promise.reject(er);
  }
);

export default identityClient;
