import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import { API_BASE_URL } from "constants/api-constants";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { Link } from "react-router-dom";
import currencyFormatter from "utils/currencyFormatter";
import ImageModal from "./ImageModal";
import CustomButton from "./CustomButton";
import { useState } from "react";
import { investorDetailRequest } from "redux/features/investor/investorSlice";
import ProfileDeactivationModal from "components/ProfileDeactivationModal";
import { useDispatch } from "react-redux";

export default function MoreUserDetails({
  data,
  hideStatus,
  hideDeactivate
}: {
  data?: IInvestorType | null;
  hideStatus?: boolean;
  hideDeactivate?: boolean
}) {
  const panfile = data?.files?.filter((el) => el?.filename?.startsWith("pan"));
  const ocrfile = data?.files?.filter((el) => el?.filename?.startsWith("ocr"));
  const companyseal = data?.files?.filter((el) =>
    el?.filename?.startsWith("company")
  );

  const dispatch = useDispatch();

  const deactivateProfile = () => {
    setShowDeactivate(false)
    dispatch(investorDetailRequest());
  };

  const [showDeactivate, setShowDeactivate] = useState(false);

  return (
    <div className="about-profile section-b-space flex-2">
      <div className="card-title" style={{ justifyContent: "space-between" }}>
        <h3>Other Informations</h3>
        <Box display="flex" justifyContent="center" alignItems="center">
          {!hideStatus && (
            <>
              <span
                className="m-d"
                style={{ fontSize: "0.8rem", marginTop: "5px" }}
              >
                Status:
              </span>
              <Typography
                variant="caption"
                component="span"
                className="content"
                padding="0 8px"
                fontWeight={600}
                textTransform="uppercase"
                marginTop="5px"
                sx={{
                  color:
                    data?.status === "Pending"
                      ? "#e9af27"
                      : data?.status === "Rejected"
                        ? "red"
                        : "#35d735",
                }}
              >
                {data?.status === "Resubmit" ? (
                  <>
                    Please make the requested changes to your profile for
                    resubmission.
                    <Link to={"/investor/profile"}>
                      <IconButton size="small">
                        <RemoveRedEyeOutlinedIcon />
                      </IconButton>
                    </Link>
                  </>
                ) : (
                  <>{data?.status}</>
                )}
              </Typography>
            </>
          )
          }

          {
            !hideDeactivate && data && (
              <CustomButton text={data.active === "true" ? "Deactivate" : "Activate" } type="filled" color={data.active === "true" ? "#e84118" : "#4cd137"} onClick={() => setShowDeactivate(true)} />
            )
          }

          {/* <span
            className="m-d"
            style={{ fontSize: "0.8rem", marginTop: "5px" }}
          >
            Status:
          </span>
          <Typography
            variant="caption"
            component="span"
            className="content"
            padding="0 8px"
            fontWeight={600}
            textTransform="uppercase"
            marginTop="5px"
            sx={{
              color:
                data?.status === "Pending"
                  ? "#e9af27"
                  : data?.status === "Rejected"
                    ? "red"
                    : "#35d735",
            }}
          >
            {data?.status === "Resubmit" ? (
              <>
                Please make the requested changes to your profile for
                resubmission.
                <Link to={"/investor/profile"}>
                  <IconButton size="small">
                    <RemoveRedEyeOutlinedIcon />
                  </IconButton>
                </Link>
              </>
            ) : (
              <>{data?.status}</>
            )}
          </Typography> */}
        </Box>
      </div>

      {/* <ModalComponent
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        title="Deactivate"
        subTitle="Are you sure you want to deactivate your investor profile?"
      /> */}

      <ProfileDeactivationModal
        open={showDeactivate}
        handleClose={() => setShowDeactivate(false)}
        onConfirm={deactivateProfile}
        data={data}
        businessOrInvestor="Investor"
      />

      <ul className="about-list">
        {data?.status !== "accepted" &&
          data?.status !== "Re-review" &&
          data?.status_reason && (
            <Alert severity="warning" sx={{ marginBottom: "10px" }}>
              Reason: {data?.status_reason}
            </Alert>
          )}
        {data?.connect_status === "Accepted" || data?.connect_status === undefined ?
          <>
            <li>
              <span className="m-d">Uploaded Documents</span>
            </li>
            {data?.files
              ?.filter((x) => x?.filename?.split("-")?.[0] !== "profile_pic")
              .map((el) => (
                <li>
                  <h5 className="title">
                    {el?.filename?.split("-")?.[0].replaceAll("_", " ")}
                  </h5>
                  <h6 className="content">
                    <ImageModal
                      fileName={el?.filename}
                      path={`${API_BASE_URL}/${el?.path}`}
                    />
                  </h6>
                </li>
              ))}
          </>
          :
          ""
        }

        {/* {panfile && panfile.length > 0 && (
          <li>
            <h5 className="title">{panfile[0]?.filename?.split("-")?.[0]}</h5>
            <h6 className="content">
              <ImageModal
                fileName={panfile[0]?.filename}
                path={`${API_BASE_URL}/${panfile[0]?.path}`}
              />
            </h6>
          </li>
        )} */}

        <li>
          <span className="m-d">Permanent Location</span>
        </li>
        <li>
          <h5 className="title"> Municipality</h5>
          <h6 className="content">
            <Details data={data?.permanent_location_municipality?.name} />
          </h6>
        </li>
        <li>
          <h5 className="title">Area Name</h5>
          <h6 className="content">
            <Details data={data?.permanent_location_area?.area_name} />
          </h6>
        </li>
        <li>
          <h5 className="title">Street Name</h5>
          <h6 className="content">
            <Details data={data?.permanent_location_street?.street_name} />
          </h6>
        </li>
        <li>
          <span className="m-d">Temporary Location</span>
        </li>
        <li>
          <h5 className="title"> Municipality</h5>
          <h6 className="content">
            {" "}
            {data?.temporary_location_municipality?.name === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_municipality?.name
            )}
          </h6>
        </li>
        <li>
          <h5 className="title">Area Name</h5>
          <h6 className="content">
            {data?.temporary_location_area?.area_name === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_area?.area_name
            )}
          </h6>
        </li>
        <li>
          <h5 className="title">Street Name</h5>
          <h6 className="content">
            {data?.temporary_location_street?.street_name === "" ? (
              <em>Not specified</em>
            ) : (
              data?.temporary_location_street?.street_name
            )}
          </h6>
        </li>
        <li>
          <span className="m-d">Investment Details</span>
        </li>
        <li>
          <h5 className="title">Investment Sectors</h5>
          <h6 className="content">
            {data?.investment_sectors?.map((el) => (
              <Chip label={el?.name} />
            ))}
            {(data?.investment_sectors ?? [])?.length < 1 && (
              <em>No sectors</em>
            )}
          </h6>
        </li>
        <li>
          <h5 className="title">Investment Preferred Location</h5>
          <h6 className="content">
            <Details data={data?.investment_preferred_location} />
          </h6>
        </li>
        <li>
          <h5 className="title">Investor Details</h5>
          <h6 className="content">
            <Details data={data?.investor_detail} />
          </h6>
        </li>
        <li>
          <h5 className="title">Interests</h5>
          <h6 className="content">
            <Details data={data?.investor_interests} />
          </h6>
        </li>
        {/* <li>
          <h5 className="title">Investment Range</h5>
          <h6 className="content">
            <>
              {currencyFormatter(+(data?.min_amount ?? 0))} -{" "}
              {currencyFormatter(+(data?.max_amount ?? 0))}
            </>
          </h6>
        </li> */}
        <li>
          <h5 className="title">Min Investment Amount</h5>
          <h6 className="content">
            <>{currencyFormatter(+(data?.min_amount ?? 0))}</>
          </h6>
        </li>
        <li>
          <h5 className="title">Max Investment Amount</h5>
          <h6 className="content">
            <>{currencyFormatter(+(data?.max_amount ?? 0))}</>
          </h6>
        </li>
        <li>
          <h5 className="title">Remarks</h5>
          <h6 className="content">
            {data?.remarks === "" ? <em>Not specified</em> : data?.remarks}
          </h6>
        </li>
      </ul>
    </div >
  );
}

const Details = ({ data }: { data?: string }) => (
  <>{data ?? <em>Not specified</em>}</>
);
