import { call, put, takeLatest } from "redux-saga/effects";
import { getSingleUser } from "services/userService";
import userSlice, { userDataRequest } from "./userSlice";

function* fetchSaga(
  action: ReturnType<typeof userDataRequest>
): object {
  yield put(userSlice.actions.get_user(action.payload));

  try {
    const response = yield call(getSingleUser, action.payload.id);
    yield put(userSlice.actions.success_user(response.data));
  } catch (e) {
    yield put(userSlice.actions.error_user(e as Error));
  }
}

function* usersSaga() {
  yield takeLatest(userDataRequest.type, fetchSaga);
}

export default usersSaga;
