import { GenericState } from "redux/app/genericState";
import { IDataType } from "./connectAllSlice";
import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";

type IMyConnectType = {
  sent: IDataType[];
  received: IDataType[];
};

interface ConnectMyState extends GenericState<IMyConnectType | null> {}

const initialState: ConnectMyState = {
  data: null,
  error: undefined,
  status: "none",
};

export const connectMyDataRequest = createAction("CONNECT_MY_DATA_REQUEST");

const connectMySlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    get_connect_all_data: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = undefined;
    },
    success_connect_all_data: (
      state,
      action: PayloadAction<IMyConnectType>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_connect_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default connectMySlice;
