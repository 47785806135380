import GoogleLibNumber, { PhoneNumberFormat } from "google-libphonenumber";
import { IIdeaType } from "models/Idea";
import { IIdeaholderType } from "models/Ideaholder";
import { ISaleType } from "models/Sale";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useMemo } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { countries } from "services/json/countries";

export const defaultCode = countries.find((x) => x.code === "NP") ?? {
  code: "NP",
  label: "Nepal",
  phone: "977",
};

export type CountryCodeType = typeof defaultCode;
type PhoneType = { code: CountryCodeType; num: string };

const phoneUtil = GoogleLibNumber.PhoneNumberUtil.getInstance();

type AnyUserRole = IInvestorType | IIdeaholderType | IIdeaType | ISaleType;

const usePhoneValidator = <T extends AnyUserRole = AnyUserRole>({
  useRegisterForm,
  defaultCountryCode,
}: {
  useRegisterForm: UseFormReturn<T>;
  defaultCountryCode: CountryCodeType;
}) => {
  const { watch, setValue, trigger } =
    useRegisterForm as unknown as UseFormReturn<AnyUserRole>;
  const phoneString = watch("phone")?.toString();

  const splitPhone = (): [PhoneType["code"], PhoneType["num"] | undefined] => {
    try {
      const phoneNumber = phoneUtil.parse(phoneString, defaultCountryCode.code);
      const c = countries.find(
        (x) => x.phone === phoneNumber.getCountryCodeOrDefault()?.toString()
      );
      if (phoneUtil.isValidNumber(phoneNumber)) {
        return [
          c ?? defaultCountryCode,
          phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL),
        ];
      }
      return [
        c ?? defaultCountryCode,
        phoneNumber.getNationalNumberOrDefault()?.toString(),
      ];
    } catch (err) {
      const split = phoneString?.split(" ");
      if (split) {
        if (split?.length > 1) {
          const c = countries.find((x) => `+${x.phone}` === split[0]);

          return [c ?? defaultCountryCode, split[1]];
        }

        if (split?.length === 1) {
          return [defaultCountryCode, split[0]];
        }
      }
    }

    return [defaultCountryCode, ""];
  };

  const [code_part, phone_part] = useMemo(
    () => splitPhone(),
    [defaultCountryCode]
  );

  const phoneForm = useForm({
    defaultValues: { code: code_part, num: phone_part },
  });
  const code = phoneForm.watch("code");
  const num = phoneForm.watch("num");

  useEffect(() => {
    const [code_part, phone_part] = splitPhone();

    (code_part !== code || phone_part !== num) &&
      phoneForm.reset({
        code: code_part,
        num: phone_part ?? "",
      });
  }, [defaultCountryCode, phoneString]);

  useEffect(() => {
    if (!num) {
      return;
    }

    if (num?.length < 7) {
      return;
    }

    const p = num?.startsWith("+") ? num : `+${code.phone} ${num}`;

    p !== phoneString && setValue("phone", p);
  }, [code, num]);

  useEffect(() => {
    if (num) {
      trigger("phone");
    }
  }, [num]);

  return phoneForm;
};

export default usePhoneValidator;
