import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  IMessage,
  IUserPreferenceSettings,
  NovuProvider,
  useFetchUserPreferences,
  useNotifications,
  useUpdateUserPreferences,
} from "@novu/notification-center";
import { NOVU_APP_IDENTIFIER, NOVU_URL } from "constants/novu-constants";

import SettingsIcon from "@mui/icons-material/Settings";
import Loader from "components/Loader";
import ModalComponent from "components/ModalComponent";
import useLoginState from "hooks/useLoginState";
import React, { useState } from "react";
import dateFormatter from "utils/dateFormatter";

// const ImgBox = styled("div")({
//   border: "1px solid #414141dd",
//   borderRadius: "50%",
//   padding: "8px",
//   position: "relative",
//   width: "30px",
//   height: "30px",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// });
// const NotiBox = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   gap: "10px",
// });
// const Cards = styled("div")({
//   padding: "8px",
//   backgroundColor: "#edf7fb",
//   borderRadius: "5px",
//   marginBottom: "5px",
// });
/**
 * @deprecated
 */
export default function Notification() {
  const loginState = useLoginState();

  if (
    !loginState?.userDetails ||
    typeof loginState.userDetails.id !== "number"
  ) {
    return <>Wait</>;
  }

  return (
    <NovuProvider
      backendUrl={NOVU_URL}
      // socketUrl="wss://novu.scieverinc.com/ws"
      subscriberId={loginState.userDetails.id.toString()}
      applicationIdentifier={NOVU_APP_IDENTIFIER}
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
      }}
    >
      <NotificationsComponent />
    </NovuProvider>
  );
}

const NotificationsComponent = () => {
  const {
    notifications,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    // unseenCount,
    isFetchingNextPage,
  } = useNotifications();

  // Preference
  const [userPreferences, setUserPreferences] = useState<
    IUserPreferenceSettings[]
  >([]);
  const {
    data: fetchedUserPreferences,
    isError,
    error,
  } = useFetchUserPreferences({
    onSuccess: (data) => setUserPreferences(data),
  });

  const { updateUserPreferences } = useUpdateUserPreferences();

  const handleCheckboxChange = (
    templateId: string,
    channelType: string,
    checked: boolean
  ) => {
    updateUserPreferences({ templateId, channelType, checked });
    const updatedUserPreferences = userPreferences.map((userPreference) => {
      if (userPreference.template._id === templateId) {
        const updatedChannels = {
          ...userPreference.preference.channels,
          [channelType]: checked,
        };
        return {
          ...userPreference,
          preference: {
            ...userPreference.preference,
            channels: updatedChannels,
          },
        };
      }
      return userPreference;
    });
    setUserPreferences(updatedUserPreferences);
  };

  // Modal stuff
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Modal stuff

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="setting-wrapper">
          <div className="top-form" style={{ marginBottom: "10px" }}>
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              Notifications{" "}
              <Button variant="text" color="inherit" onClick={handleModalOpen}>
                <SettingsIcon sx={{ marginRight: "10px" }} fontSize="small" />{" "}
                Settings
              </Button>
            </h3>
            <ModalComponent
              open={isModalOpen}
              handleClose={handleModalClose}
              title="Notification Settings"
              subTitle="Select the events for which you want email notifications enabled."
              userPreferences={userPreferences}
              onCheckboxChange={handleCheckboxChange}
            />
          </div>
          {(isLoading || isFetching) && !isFetchingNextPage ? (
            <Loader />
          ) : (
            <>
              {!notifications?.length ? (
                <div>No notifications to show</div>
              ) : (
                <NotificationList notifications={notifications} />
              )}
              {isFetchingNextPage && <Loader />}
              {!isFetchingNextPage && hasNextPage && (
                <Button onClick={fetchNextPage}>Load More</Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

type NotificationListProps = { notifications: IMessage[] };

/**
 * @deprecated
 */
const NotificationList = ({ notifications }: NotificationListProps) => {
  const { markNotificationAsRead } = useNotifications();
  const handleLinkClick = (id: any) => {
    markNotificationAsRead(id);
  };

  return (
    <List sx={{ width: "100%", minWidth: 360, bgcolor: "background.paper" }}>
      {(notifications ?? []).map((notification) => (
        <>
          <ListItem alignItems="flex-start">
            {/* <ListItemAvatar>
              <Avatar alt={el.name} src="/static/images/avatar/1.jpg" />
            </ListItemAvatar> */}
            <ListItemText
              // primary={el?.name}
              secondary={
                <React.Fragment>
                  <Link
                    target="_blank"
                    href={notification?.cta?.data?.url ?? "#"}
                    onClick={() => handleLinkClick(notification._id)}
                  >
                    <Typography
                      component={Box}
                      variant="body2"
                      color="text.primary"
                      sx={{
                        fontWeight: notification.seen ? "regular" : "bold",
                        fontStyle: notification.read ? "normal" : "italic",
                      }}
                    >
                      <>{notification.content}</>
                    </Typography>
                  </Link>
                  <Typography component={Box} variant="caption">
                    {/* <span>On {dateFormatter(notification?.createdAt)}</span> */}

                    {/* {notification.seen && (
                      <span>
                        , Seen {dateFormatter(notification?.lastSeenDate)}
                      </span>
                    )} */}
                    {notification.read ? (
                      <span>
                        Read {dateFormatter(notification?.lastReadDate)}
                      </span>
                    ) : (
                      <span>On {dateFormatter(notification?.createdAt)}</span>
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider component="li" />
        </>
      ))}
    </List>
  );
};
