import { IAccessRequestType } from "models/AccessRequest";
import { IAccessRequestStatusType } from "pages/dashboard/admin/access-request/AccessRequestTabs";
import httpClient from "./httpClient";

export const createAccessRequest = async (payload: IAccessRequestType) => {
  return await httpClient.post("/idea-request", payload);
};

export const getAllAccessRequests = async (
  status: IAccessRequestStatusType | null
) => {
  return await httpClient.get("/idea-request", {
    params: {
      status,
    },
  });
};

export const getInvestorAccessRequests = async (
  id: string | number,
  status: IAccessRequestStatusType | null
) => {
  return await httpClient.get<any>(`/idea-request/investor_id/${id}`, {
    params: {
      status,
    },
  });
};

export const getIdeaholderAccessRequests = async (
  id: string | number,
  status: IAccessRequestStatusType | null
) => {
  return await httpClient.get<any>(`/idea-request/ideaholder_id/${id}`, {
    params: {
      status,
    },
  });
};

export const updateAccessRequestStatus = async (
  id: number | undefined,
  payload: {
    idea_id: string | number;
    investor_id: string | number;
    status: string;
  }
) => {
  return await httpClient.put(`/idea-request/${id}/update`, payload);
};

export const deleteAccessRequest = async (id: number | undefined) => {
  return await httpClient.delete(`/idea-request/${id}/delete`);
};
