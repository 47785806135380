import { Box, styled } from "@mui/material";
import Logo from "assets/png/enitiation_logo2.png";
import DropDownHam from "components/DropDownHam";
import useLoginState from "hooks/useLoginState";
import useOutsideClick from "hooks/useOutsideClick";
import * as React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import BottomNavbar from "./BottomNavbar";

const SectionButton = styled("li")({
  "& @media only screen and (max-width: 576px)": {
    display: "none",
  },
});

export default function Navbar({ isLandingPage }: { isLandingPage?: boolean }) {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };
  const loc = useLocation();

  const dropdownRef = React.useRef<any>(null);
  useOutsideClick(dropdownRef, () => {
    setShow(false);
  });

  const { isAdmin } = useLoginState();

  return (
    <>
      {!isLandingPage && <BottomNavbar />}
      <header className="header-light">
        <div className="container-fluid custom-padding">
          <div
            className="header-section"
            style={{
              minHeight: "68px",
            }}
          >
            <Box className="header-right">
              <ul className="option-list">
                <li>
                  <div className="brand-logo">
                    <Link to="/">
                      <img
                        src={Logo}
                        alt="logo"
                        className="lazyload"
                        height="26px"
                      />
                    </Link>
                  </div>
                </li>
                {/* {!isLandingPage && (
               
                )} */}
              </ul>
            </Box>
            <Box className="header-left">
              <>
                <Box
                  style={{
                    margin: 0,
                  }}
                  className="search-box"
                  position="relative"
                >
                  <AiOutlineSearch className="nav-searchicon" />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search for ..."
                  />
                </Box>
                {!isLandingPage && (
                  <>
                    <SectionButton
                      className="nav-list"
                      sx={{
                        backgroundColor:
                          `/${loc?.pathname?.split("/")[1]}` === "/idea"
                            ? "#0389c9"
                            : "#fff",
                        "span.nav-text": {
                          color:
                            `/${loc?.pathname?.split("/")[1]}` === "/idea"
                              ? "#fff"
                              : "#0389c9",
                        },
                        "@media only screen and (max-width: 576px)": {
                          display: "none",
                        },
                      }}
                    >
                      <Link to="/idea">
                        <span className="nav-text">Business</span>
                      </Link>
                    </SectionButton>
                    <SectionButton
                      className="nav-list"
                      sx={{
                        backgroundColor:
                          `/${loc?.pathname?.split("/")[1]}` === "/investor"
                            ? "#0389c9"
                            : "#fff",
                        "span.nav-text": {
                          color:
                            `/${loc?.pathname?.split("/")[1]}` === "/investor"
                              ? "#fff"
                              : "#0389c9",
                        },
                        "@media only screen and (max-width: 576px)": {
                          display: "none",
                        },
                      }}
                      id={"2"}
                    >
                      <Link to="/investor">
                        <span className="nav-text">Investor</span>
                      </Link>
                    </SectionButton>
                    {isAdmin && (
                      <SectionButton
                        className="nav-list"
                        sx={{
                          backgroundColor:
                            `${loc?.pathname?.split("/")[1]}` === "admin"
                              ? "#0389c9"
                              : "#fff",
                          "span.nav-text": {
                            color:
                              `/${loc?.pathname?.split("/")[1]}` === "/admin"
                                ? "#fff"
                                : "#0389c9",
                          },
                          "@media only screen and (max-width: 576px)": {
                            display: "none",
                          },
                        }}
                        id="2"
                      >
                        <Link to="/admin">
                          <span className="nav-text">Admin</span>
                        </Link>
                      </SectionButton>
                    )}
                  </>
                )}
              </>

              <DropDownHam />
            </Box>
          </div>
        </div>
      </header>
    </>
  );
}
