import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import AgreementsTable from "./AgreementsTable";

const TabCover = styled("div")({
  height: "85vh",
  width: "100%",
});

export default function AgreementsTabs() {
  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title m-bottom-10">Agreements</span>
        <AgreementsTable />
      </TabCover>
    </Box>
  );
}
