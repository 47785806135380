import { TextField } from "@mui/material";
import ModalComponent from "components/ModalComponent";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { updateStatus } from "services/InvestorService";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

export default function ReviewModal(props: IProps) {
  const { handleClose, open } = props;
  const [datas, setDatas] = useState("");
  const payload = { status: "resubmit", resubmit_reason: datas };
  const { id } = useParams<{
    id: string;
  }>();

  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      if (id) {
        const response = await updateStatus(+id, payload);
        // setDatas(response.data);
        if (response?.data?.message === "Status changed to resubmit") {
          toast("Investor profile requested for review.", {
            type: "info",
          });
          props?.handleClose();
          navigate(-1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleReason = (e: any) => {
    setDatas(e.target.value);
  };

  return (
    <ModalComponent
      title="Resubmit"
      subTitle="Are you sure you want to request resubmission?"
      open={open}
      handleClose={handleClose}
      path="/admin/investor-screening"
      onConfirm={onConfirm}
    >
      <div className="box-gap m-top">
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Reason for resubmission"
          onChange={(e) => handleReason(e)}
        />
      </div>
    </ModalComponent>
  );
}
