import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Link } from "react-router-dom";
import { IDataType } from "redux/features/connect/connectAllSlice";
import useLoginState from "hooks/useLoginState";
import { updateConnect } from "services/connectService";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function UserAllConnectsTable({
    allConnects,
    getConnectRequestService
}: {
    allConnects: IDataType[],
    getConnectRequestService: () => void
}) {

    const { userDetails, isLoggedIn } = useLoginState();

    const updateConnectService = async (
        id: any,
        newStatus: "accepted" | "rejected"
    ) => {
        const res = await updateConnect(id, {
            status: newStatus,
        });
        if (res.status === 200) {
            toast("Connected successfully");
            getConnectRequestService();
        }
    };

    const columns: GridColDef[] = [
        { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
        {
            field: "ideaholder_full_name",
            headerName: "Business Name",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                let result = [];
                if (params.row.idea_holder.full_name) {
                    result.push(params.row.idea_holder.full_name);
                } else {
                    result = ["Unknown"];
                }
                return result.join(", ");
            },
        },
        {
            field: "investor_full_name",
            headerName: "Investor Name",
            flex: 1,
            minWidth: 200,
            valueGetter: (params) => {
                let result = [];
                if (params.row.investor.full_name) {
                    result.push(params.row.investor.full_name);
                } else {
                    result = ["Unknown"];
                }
                return result.join(", ");
            },
        },
        {
            field: "created_at",
            headerName: "Date",
            flex: 1,
            minWidth: 100,
            valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
        },
        {
            field: "status",
            headerName: "Status",
            flex: 1,
            minWidth: 100,
            maxWidth: 100,
            renderCell: (params) => {
                return (
                    <Box className="flex-g-5 svg-size">
                        <Chip
                            label={params?.row?.status}
                            variant="filled"
                            sx={{
                                color: "#fff",
                                bgcolor:
                                    STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
                            }}
                        />
                    </Box>
                );
            },
        },
        {
            field: "Action",
            headerName: "Action",
            flex: 1,
            maxWidth: 180,
            minWidth: 180,
            renderCell: (params) => {
                const isInvestor = userDetails?.id === params?.row?.investor.user_id;
                if (params.row.requested_to === userDetails?.id && params.row.status === "Pending") {
                    return (
                        <Box className="flex-g-10 svg-size">
                            <IconButton
                                size="small"
                                onClick={() =>
                                    updateConnectService(params?.row?.id, "accepted")
                                }
                            >
                                <CheckIcon />
                            </IconButton>

                            <IconButton
                                size="small"
                                onClick={() =>
                                    updateConnectService(params?.row?.id, "rejected")
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    );
                }
                else {
                    return (
                        <Box className="flex-g-10 svg-size">
                            {!isInvestor ? (
                                <Link to={`/idea/investor/details/${params?.row?.investor.id}`}>
                                    <IconButton size="small">
                                        <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                </Link>
                            ) : (
                                <Link to={`/investor/idea-holder/${params?.row?.idea_holder.id}`}>
                                    <IconButton size="small">
                                        <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                </Link>
                            )}
                        </Box>
                    );
                }
                // return (
                //   <Box className="flex-g-10 svg-size">
                //     <Link to={`/idea/investor/details/${params?.row?.investor.id}`}>
                //       <IconButton size="small">
                //         <RemoveRedEyeOutlinedIcon />
                //       </IconButton>
                //     </Link>

                //     <Link to={`/investor/idea-holder/${params?.row?.idea_holder.id}`}>
                //       <IconButton size="small">
                //         <RemoveRedEyeOutlinedIcon />
                //       </IconButton>
                //     </Link>
                //   </Box>
                // );
            },
        },
    ];

    return (
        <div style={{ height: 631, width: "100%" }}>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
                    {
                        display: "none",
                    },
                }}
                rows={
                    allConnects?.map((el, index: number) => {
                        return {
                            ...el,
                            sn: index + 1,
                        };
                    }) ?? []
                }
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
        </div>
    );
}
