import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import accessRequestAllSlice from "redux/features/access-request/accessRequestAllSlice";
import analyticsSlice from "redux/features/analytics-idea/analyticsSlice";
import analyticsInvestorSlice from "redux/features/analytics-investor/analyticsInvetorSlice";
import connectAllSlice from "redux/features/connect/connectAllSlice";
import connectMySlice from "redux/features/connect/connectMySlice";
import ideaAllSlice from "redux/features/idea/ideaAllSlice";
import ideaSlice from "redux/features/idea/ideaSlice";
import ideaholderAllSlice from "redux/features/ideaholder/ideaholderAllSlice";
import ideaholderSlice from "redux/features/ideaholder/ideaholderSlice";
import investorAllSlice from "redux/features/investor/investorAllSlice";
import investorSlice from "redux/features/investor/investorSlice";
import proposalAllSlice from "redux/features/investor/proposal/proposalAllSlice";
import muniSlice from "redux/features/location/muniSlice";
import loginSlice from "redux/features/login/LoginSlice";
import userAllSlice from "redux/features/users/userAllSlice";
import userSlice from "redux/features/users/userSlice";
import rootSaga from "./rootSaga";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["login", "ideaholder", "investor", "municipalities"],
};

const rootReducer = combineReducers({
  accessRequestAll: accessRequestAllSlice.reducer,
  ideaholderAll: ideaholderAllSlice.reducer,
  ideaholder: ideaholderSlice.reducer,
  investorAll: investorAllSlice.reducer,
  connectAll: connectAllSlice.reducer,
  connectMy: connectMySlice.reducer,
  investor: investorSlice.reducer,
  ideaAll: ideaAllSlice.reducer,
  idea: ideaSlice.reducer,
  login: loginSlice.reducer,
  proposal: proposalAllSlice.reducer,
  municipalities: muniSlice.reducer,
  userAll: userAllSlice.reducer,
  userSingle: userSlice.reducer,
  analyticsIdea: analyticsSlice.reducer,
  analyticsInvestor: analyticsInvestorSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(sagaMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

sagaMiddleware.run(rootSaga);
