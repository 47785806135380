import ProfileContent from "components/ProfileContent";
import { CLIENT_ID, IDENTITY_SERVER_URL } from "constants/api-constants";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import useInvestorState from "hooks/useInvestorState";
import useLoginState from "hooks/useLoginState";
import { useDispatch } from "react-redux";
import { logoutUser } from "redux/features/login/LoginSlice";

const LandingCardData = () => {
  const { isLoggedIn } = useLoginState();
  const { hasInvestorProfile } = useInvestorState();
  const { hasIdeaProfile } = useIdeaHolderState();

  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const openRegister = () => {
    const redirectUrl =
      IDENTITY_SERVER_URL +
      "register?callback_url=" +
      process.env.REACT_APP_CALLBACK_URL +
      "&client_id=" +
      CLIENT_ID;

    window.location.href = redirectUrl;
  };

  const openLogin = (message?: string) => {
    let redirectUrl =
      IDENTITY_SERVER_URL +
      "?callback_url=" +
      process.env.REACT_APP_CALLBACK_URL +
      "&client_id=" +
      CLIENT_ID;

    if (message) {
      redirectUrl = `${redirectUrl}&status=${encodeURI(message)}`;
    }

    window.location.href = redirectUrl;
  };

  return [
    {
      value: 0,
      showView: false,
      title: "Join Now!",
      isMain: true,
      desc: "View and add awesome list of  Business ideas and Business investors",
      beforeAuth: {
        button1Title: "Register",
        onButton1Click: openRegister,
        button2Title: "Login",
        onButton2Click: openLogin,
      },
      afterAuth: {
        button1Title: "Go to Dashboard →",
        onButton1Link: "idea/user-profile",
        button2Title: "Logout",
        onButton2Click: handleLogout,
        component: <ProfileContent />,
      },
      color:
        "linear-gradient(90deg, rgba(125, 198, 228, 0.74) 0%, rgba(61, 167, 211, 0.74) 100%)",
      isLoggedIn: isLoggedIn,
      hasProfile: isLoggedIn,
    },

    {
      value: 1,
      showView: true,
      isMain: false,
      title: "Got a Business Proposition?",
      desc: "Create your ideaholder profile to reach our awesome list of investors",
      beforeAuth: {
        button1Title: "+ Business Profile",
        onButton1Click: () =>
          openLogin(
            "You need to login before creating a business proposition."
          ),
        button2Title: "Business Feed →",
        onButton2Click: () => {
          localStorage.setItem("route", "business_feed")
          openLogin("You need to login before viewing the business feed.")
        }
      },
      afterAuth: {
        button1Title:
          isLoggedIn && hasIdeaProfile
            ? "Business Profile →"
            : "+ Business Profile",
        onButton1Link:
          isLoggedIn && hasIdeaProfile
            ? "/idea/profile/detail"
            : "/idea/profile",
        button2Title: "Business Feed →",
        onButton2Link: "/idea",
      },
      color: "linear-gradient(90deg, #F9C975 0%, #EBAF45 100%)",
      isLoggedIn: isLoggedIn,
      hasProfile: isLoggedIn && hasIdeaProfile,
    },
    {
      value: 2,
      showView: true,
      isMain: false,
      title: "Investor or Buyer?",
      desc: "Create your Profile to view the long list of investment opportunities",
      beforeAuth: {
        button1Title: "+ Investor Profile",
        onButton1Click: () =>
          openLogin("You need to login before creating an investor."),
        button2Title: "Investor Feed →",
        onButton2Click: () => {
          localStorage.setItem("route", "investor_feed")
          openLogin("You need to login before viewing an investor feed.")
        }
      },
      afterAuth: {
        button1Title:
          isLoggedIn && hasInvestorProfile
            ? "Investor Profile →"
            : "+ Investor Profile",
        onButton1Link:
          isLoggedIn && hasInvestorProfile
            ? "/investor/detail"
            : "/investor/profile",
        button2Title: "Investor Feed →",
        onButton2Link: "/investor",
      },
      color: "linear-gradient(90deg, #82CD82 0%, #60B760 100%)",
      isLoggedIn: isLoggedIn,
      hasProfile: isLoggedIn && hasInvestorProfile,
    },
  ];
};
export default LandingCardData;
