import { call, put, takeLatest } from "redux-saga/effects";
import { getAllUsers } from "services/userService";
import userAllSlice, { userAllDataRequest } from "./userAllSlice";

function* fetchSaga(
  action: ReturnType<typeof userAllDataRequest>
): object {
  yield put(userAllSlice.actions.get_users());

  try {
    const response = yield call(getAllUsers);
    yield put(userAllSlice.actions.success_users(response.data));
  } catch (e) {
    yield put(userAllSlice.actions.error_users(e as Error));
  }
}

function* userAllSaga() {
  yield takeLatest(userAllDataRequest.type, fetchSaga);
}

export default userAllSaga;
