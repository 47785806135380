import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import EmptyTable from "components/EmptyTable";
import { STATUS_COLOR } from "constants/color-constants";
import { IIdeaType } from "models/Idea";
import { IIdeaholderType } from "models/Ideaholder";
import moment from "moment";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteProposal, updateProposal } from "services/InvestorService";

type IDataType = {
  investor: IInvestorType;
  idea: IIdeaType;
  ideaholder: IIdeaholderType;
  created_at: string;
  deleted_at: string;
  id: number;
  status: string;
  updated_at: string;
  ideaholder_id: number;
  idea_id: number;
  investor_id: number;
};

export default function ProposalsTable({
  data,
  getProposalsService,
}: {
  data: IDataType[];
  getProposalsService: () => void;
}) {
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <EmptyTable />
      </GridOverlay>
    );
  }
  const deleteProposalsService = async (id: number) => {
    const res = await deleteProposal(id);
    if (res.status === 200) {
      toast("Proposal deleted successfully");
    } else {
      toast("Oops! Error occured.", {
        type: "error",
      });
    }
  };

  const updateProposalService = async (
    detail: IDataType,
    newStatus: "accepted" | "rejected"
  ) => {
    const { id, ideaholder_id, investor_id, idea_id } = detail;
    const res = await updateProposal(id, {
      ideaholder_id: ideaholder_id,
      idea_id: idea_id,
      investor_id: investor_id,
      status: newStatus,
    });
    if (res.status === 200) {
      toast("Proposal updated successfully");
      getProposalsService();
    }
  };

  const columns: GridColDef<IDataType>[] = [
    { field: "sn", headerName: "SN", flex: 1, maxWidth: 60 },
    {
      field: "IdeaHolder",
      headerName: "Business Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.idea ? (
              <>{params?.row.idea.idea_holder.full_name}</>
            ) : (
              <>{params?.row.ideaholder.full_name}</>
            )}
          </>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            <Chip
              label={params?.row?.status}
              variant="filled"
              sx={{
                color: "#fff",
                bgcolor:
                  STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        if (params?.row.status.toLowerCase() === "pending") {
          return (
            <Box className="flex-g-5 svg-size">
              <IconButton
                size="small"
                onClick={() => updateProposalService(params?.row, "accepted")}
              >
                <CheckIcon />
              </IconButton>

              <IconButton
                size="small"
                onClick={() => updateProposalService(params?.row, "rejected")}
              >
                <CloseIcon />
              </IconButton>

              {/* <IconButton
                size="small"
                onClick={() =>
                  deleteProposalsService(params?.row?.ideaholder_id)
                }
              >
                <AiOutlineDelete />
              </IconButton> */}

              <Link to={`/investor/idea-holder/${params?.row?.ideaholder_id}`}>
                <IconButton size="small">
                  <RemoveRedEyeOutlinedIcon />
                </IconButton>
              </Link>

              {params?.row?.idea ? (
                <Link to={`/idea/view-detail/${params.row.idea_id}`}>
                  <IconButton size="small">
                    <LightbulbIcon />
                  </IconButton>
                </Link>
              ) : (
                <IconButton size="small" disabled>
                  <LightbulbIcon />
                </IconButton>
              )}
            </Box>
          );
        }
        return (
          <Box className="flex-g-10 svg-size">
            {/* <IconButton
              size="small"
              onClick={() => deleteProposalsService(params?.row?.ideaholder_id)}
            >
              <AiOutlineDelete />
            </IconButton> */}

            <Link to={`/investor/idea-holder/${params?.row?.ideaholder_id}`}>
              <IconButton size="small">
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Link>

            {params?.row?.idea ? (
              <Link to={`/idea/view-detail/${params.row.idea_id}`}>
                <IconButton size="small">
                  <LightbulbIcon />
                </IconButton>
              </Link>
            ) : (
              <IconButton size="small" disabled>
                <LightbulbIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={data?.map((el, index) => {
          return {
            ...el,
            sn: index + 1,
          };
        })}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
      />
    </div>
  );
}
