import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import * as React from "react";
import { useDispatch } from "react-redux";
import { connectAllDataRequest } from "redux/features/connect/connectAllSlice";
import ConnectTable from "./ConnectTable";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export default function ConnectTabs() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(connectAllDataRequest());
  });

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Connects</span>

        <ConnectTable />
      </TabCover>
    </Box>
  );
}
