import { FormControl, IconButton, Input, InputLabel } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { IIdeaholderType, IIdeaholderTypeResponse } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useRef, useState } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import CustomButton from "./CustomButton";
import ImageModal from "./ImageModal";
import NotchedOutlineRoot from "./NotchedOutlineRoot";

export default function DisplayFile({
  id,
  fileName,
  path,
  title,
  changeService,
  profile,
  readonly,
}: {
  id?: number;
  fileName: string;
  path: string;
  readonly?: boolean;
  title: string;
  profile?: IInvestorType | IIdeaholderType | IIdeaholderTypeResponse | null;
  changeService?: (
    id: number | null,
    e: FormData,
    ideaId?: number
  ) => Promise<AxiosResponse>;
}) {
  const [file, setFile] = useState<string>("");

  const [changed, setChanged] = useState(!!id ?? false);

  const [pathName, setPathName] = useState<string>("");

  useEffect(() => {
    setFile(fileName);
    setPathName(path);
  }, [fileName, path]);

  const changeImage = async (e: React.ChangeEvent<any>) => {
    const currentFile = e?.target?.files?.[0];

    const fd = new FormData();
    fd.append(title, currentFile);
    const res = await changeService?.(id ? +id : null, fd, profile?.id);
    if (res?.status === 200 || res?.status === 201) {
      setChanged(true);
      setFile(`${title}-abcd.${currentFile?.name?.split(".")[1]}`);
      setPathName(URL.createObjectURL(currentFile));
    }
  };

  const btnRef = useRef<HTMLInputElement | null>(null);

  const openPrompt = () => {
    btnRef?.current?.click();
  };

  const label = title?.replaceAll("_", " ");

  return (
    <Box marginTop={2}>
      <FormControl fullWidth sx={{ fontSize: "12px" }}>
        <InputLabel required shrink={true} sx={{ textTransform: "capitalize" }}>
          {label}
        </InputLabel>
        <NotchedOutlineRoot label={label + "_"} notched={true} />

        <Input
          type="file"
          hidden={true}
          onChange={changeImage}
          inputProps={{
            ref: btnRef,
            alt: "file",
            accept:
              "image/jpeg,image/gif,image/png,application/pdf,image/x-eps,image/webp,image/jpg",
          }}
        />
        {id || changed ? (
          <ImageModal fileName={file} path={pathName}>
            {!readonly && (
              <IconButton
                onClick={openPrompt}
                sx={{ width: "56px", height: "56px" }}
              >
                <BsArrowRepeat />
              </IconButton>
            )}
          </ImageModal>
        ) : (
          <Box
            sx={{
              height: "56px",
              padding: "11px 14px",
            }}
          >
            <CustomButton
              onClick={openPrompt}
              text={`Upload your ${title?.replaceAll("_", " ")}`}
              type="outlined"
            />
          </Box>
        )}
      </FormControl>
    </Box>
  );
}
