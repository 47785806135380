import { Box, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import CustomButton2 from "components/CustomButton2";
import FullButton from "components/FullButton";
import GoBackButton from "components/GoBackButton";
import IdeaHolderModal from "components/IdeaHolderModal";
import PostActions from "components/PostActions";
import { PRIMARY_COLOR } from "constants/color-constants";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import ProposalConnectModal from "pages/dashboard/admin/connect/modal/ProposalConnectModal";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { FaRegUser } from "react-icons/fa";
import { IoEarth } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/app/store";
import {
  followByIdeaHolder,
  getAllInvestor,
  getInvestor,
  postDisLikeByIdeaHolder,
  postFavouriteByIdeaHolder,
  postLikeByIdeaHolder,
  postUnfavouiteByIdeaHolder,
  unfollowByIdeaHolder,
} from "services/InvestorService";
import currencyFormatter from "utils/currencyFormatter";
import SendProposalModal from "../modal/SendProposalModal";

const PostWrapper = styled("div")({
  minHeight: "240px",
  width: "100%",
  padding: "25px 35px",
  marginBottom: "15px",
});
const TopWrap = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "10px",
});
const TitleSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
});
const ImgBox = styled("div")({
  border: "1px solid #dddd",
  borderRadius: "50%",
  padding: "10px",
  position: "relative",
  width: "45px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ActiveBox = styled("div")({});
const Type = styled("div")({
  fontFamily: "roboto",
  fontSize: "0.8rem",
  color: "#4c4c4c",
});
const Title = styled("div")({
  fontWeight: "bold",
  fontSize: "1rem",
});
const Dot = styled("span")({
  fontSize: "10px",
  position: "absolute",
  right: "-5px",
  bottom: "2px",
});
const Wrap = styled("div")({
  display: "flex",
  gap: "15px",
});

const DetailBox = styled("div")({
  fontSize: "0.8rem",
  margin: "10px 0",
  "& span": {
    fontFamily: "Roboto",
    color: "#5e5e5e",
  },
  "& .title": {
    fontWeight: "bold",
  },
});
const LastSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "10px",
  paddingBottom: "25px",
});
export type IButtonStatus =
  | "uninitialized"
  | "pending"
  | "accepted"
  | "rejected";

export default function InvestorFeedDetails() {
  const [show, setShow] = useState(true);
  const params = useParams<{
    id: string | undefined;
  }>();

  const investorId = useSelector(
    (state: RootState) => state?.investor?.data?.id
  );

  const [investor, setInvestor] = useState<IInvestorType | null>(null);
  const [loading, setLoading] = useState(false);
  const { hasIdeaProfile, status } = useIdeaHolderState();
  const [hasFollowed, setHasFollowed] = useState(investor?.has_followed);
  const [like, setLike] = useState(investor?.like_count ?? 0);
  const [favourites, setFavourites] = useState(investor?.favourite_count ?? 0);
  const [hasLikedPost, setHasLikedPost] = useState(investor?.has_liked);
  const [hasFav, setHasFav] = useState(investor?.has_favourite);

  const { id } = useParams<{
    id: string;
  }>();

  useEffect(() => {
    setLike(investor?.like_count ?? 0);
    setFavourites(investor?.favourite_count ?? 0);
    setHasLikedPost(investor?.has_liked);
    setHasFav(investor?.has_favourite);
    setHasFollowed(investor?.has_followed);
  }, [investor]);

  const getIdeaDetail = async () => {
    if (id) {
      setLoading(true);
      const res = await getInvestor(+id);
      if (res?.data) {
        setInvestor(res?.data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getIdeaDetail();
  }, [id]);

  const [requestStatus, setRequestStatus] =
    useState<IButtonStatus>("uninitialized");
  const [open, setOpen] = useState(false);
  const [ideaModal, setIdeaModal] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    setOpen(true);
  };
  const handleCloseIdea = () => {
    setIdeaModal(false);
  };

  const handleOpen2 = () => setOpen2(true);
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  useEffect(() => {
    if (investor) {
      setRequestStatus(
        (
          investor?.proposal?.[0]?.status ?? "uninitialized"
        )?.toLowerCase() as IButtonStatus
      );
    }
  }, [investor]);

  const sendRequest = () => {
    // your api goes here
    setRequestStatus("pending");
    handleClose();
  };

  const handleButton = (status: IButtonStatus) => {
    switch (status) {
      case "uninitialized": {
        return (
          <FullButton
            onClick={handleOpen}
            text="Send Proposal"
            type="filled"
            isLocked={!hasIdeaProfile}
          />
        );
      }
      case "pending": {
        return (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Proposal has been sent."
            secondaryText="Please wait for investor for approval."
          />
        );
      }
      case "rejected": {
        return (
          <StatusMessageTemplate
            icon={
              <Icon.Slash
                stroke="#d72222"
                className="icon stroke-width-3 iw-12 ih-12"
              />
            }
            primaryText="Proposal has been rejected."
            secondaryText="Please try again after 2 days."
            secondaryTextColor="#d72222"
          />
        );
      }
      case "accepted": {
        return investor?.connect_status?.toLowerCase() === "pending" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been sent."
            secondaryText="Please wait for admin for approval."
          />
        ) : investor?.connect_status?.toLowerCase() === "accepted" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been accepted."
            secondaryText="You are now connected."
          />
        ) : investor?.connect_status?.toLowerCase() === "rejected" ? (
          <StatusMessageTemplate
            icon={
              <Icon.CheckCircle className="icon icon-theme stroke-width-3 iw-12 ih-12" />
            }
            primaryText="Connect request has been rejected."
            secondaryText="Please try again."
          />
        ) : (
          <FullButton onClick={handleOpen2} text="Connect Now" type="filled" />
        );
      }
      default: {
        return <></>;
      }
    }
  };
  useEffect(() => {
    if (params?.id && investorId === +params?.id) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [params?.id, investorId]);

  // const [likes, setLikes] = useState(investor?.like_count);

  const ideaHolderId = useSelector(
    (state: RootState) => state?.ideaholder?.data?.id
  );

  const onLikeByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }

    const res = await postLikeByIdeaHolder({
      investor_id: id,
      liked_by: ideaHolderId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(1);
      setLike(like + 1);
    }
  };

  const onDisLikeByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    const res = await postDisLikeByIdeaHolder({
      investor_id: id,
      liked_by: ideaHolderId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(0);
      setLike(like - 1);
    }
  };

  const onFavByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    const res = await postFavouriteByIdeaHolder({
      investor_id: id,
      favorite_by: ideaHolderId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(1);
      setFavourites(favourites + 1);
    }
  };

  const onUnfavByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    const res = await postUnfavouiteByIdeaHolder({
      investor_id: id,
      favorite_by: ideaHolderId,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(0);
      setFavourites(favourites - 1);
    }
  };

  //follow

  const onFollowByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    const res = await followByIdeaHolder({
      investor_id: id,
      follower_user_id: ideaHolderId,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(1);
    }
  };
  const onUnFollowByIdeaHolder = async (id: number | undefined) => {
    if (!hasIdeaProfile) {
      setIdeaModal(true);
      return;
    }
    const res = await unfollowByIdeaHolder({
      investor_id: id,
      follower_user_id: ideaHolderId,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(0);
    }
  };

  // Testing something

  const [allInvestor, setAllInvestor] = useState<IInvestorType[]>([]);

  const getInvestorDetails = async () => {
    const res = await getAllInvestor("accepted");
    if (res?.status === 200 && res?.data) {
      const data = res.data;
      setAllInvestor(data);
    }
  };

  useEffect(() => {
    getInvestorDetails();
  }, []);

  const selectedItem = id
    ? allInvestor.find((item) => item.id === parseInt(id))
    : undefined;

  // Testing something

  return (
    <Box className="content-center">
      <Box className="post-panel section-t-space" style={{ marginTop: 0 }}>
        <PostWrapper className="post-wrapper">
          <TopWrap>
            <GoBackButton text="Go Back" />
            <div className="follow-button-all">
              {+(hasFollowed ?? "") === 1 ? (
                <CustomButton2
                  onClick={() => onUnFollowByIdeaHolder(investor?.id)}
                  text="Followed"
                  type="outlined"
                />
              ) : (
                <CustomButton2
                  onClick={() => onFollowByIdeaHolder(investor?.id)}
                  text="+ Follow"
                  type="filled"
                />
              )}
            </div>
            {/* <Follow acceptedIdea={investor as IInvestorType} /> */}
          </TopWrap>
          <TitleSection>
            <Wrap>
              <ImgBox>
                <FaRegUser style={{ fontSize: "1.2rem" }} />
                <Dot>&#128994;</Dot>
              </ImgBox>
              <ActiveBox>
                <Title>{selectedItem?.title_designation}</Title>
                <Type>{selectedItem?.investor_profile} Investor</Type>
                {investor?.interest_in_buying_business === "1" ? (
                  <Type>Interested in Buying Businesses</Type>
                ) : (
                  <></>
                )}
              </ActiveBox>
            </Wrap>
          </TitleSection>

          <Divider className="hrClass" />

          <DetailBox>
            <span className="title">Investor Details: </span>
            <span>{investor?.investor_detail}</span>
          </DetailBox>
          <DetailBox>
            <span className="title">Interests: </span>
            <span>{investor?.investor_interests}</span>
          </DetailBox>

          <DetailBox>
            <span className="title">Interested Sectors: </span>
            <span>
              {(investor?.investment_sectors ?? [])?.length < 1 && (
                <em>Not specified</em>
              )}
              {(investor?.investment_sectors ?? [])?.map((sector, index) => (
                <span>
                  <>
                    {sector?.name}
                    {(investor?.investment_sectors ?? [])?.length > index + 1 &&
                      ","}{" "}
                  </>
                </span>
              ))}
            </span>
          </DetailBox>

          <DetailBox className="d-flex gap-sm m-top-0">
            {" "}
            <span className="earth">
              <IoEarth />
            </span>
            <span className="title">Preferred Location: </span>{" "}
            <span> {investor?.investment_preferred_location}</span>
          </DetailBox>
          <DetailBox className="m-top-0">
            {/* <span className="title">Investment Size:</span>
            <span>
              {`${currencyFormatter(
                +(investor?.min_amount ?? 0)
              )}-${currencyFormatter(+(investor?.max_amount ?? 0))}`}{" "}
            </span> */}
            <span className="title">Minimum Investment Amount: </span>
            <span>{`${currencyFormatter(+(investor?.min_amount ?? 0))}`} </span>
          </DetailBox>

          <DetailBox className="m-top-0">
            <span className="title">Maximum Investment Amount: </span>
            <span>{`${currencyFormatter(+(investor?.max_amount ?? 0))}`} </span>
          </DetailBox>

          <Divider className="hrClass" />
          {/* <DetailBox className="flex-align-center">
            <span className="title">Personal Details</span>
            <span className="title m-top-2">
              <AiFillLock />
            </span>
          </DetailBox> */}

          <LastSection>
            <PostActions
              likes={like ?? 0}
              favourite={favourites ?? 0}
              views={investor?.view_count ?? 0}
              onLiked={() => onLikeByIdeaHolder(investor?.id)}
              onDisliked={() => onDisLikeByIdeaHolder(investor?.id)}
              onFavorite={() => onFavByIdeaHolder(investor?.id)}
              onUnfavourite={() => onUnfavByIdeaHolder(investor?.id)}
              hasLiked={hasLikedPost}
              hasFavourite={hasFav}
              className="w-100"
            >
              {show && (
                <Box paddingTop="10px">{handleButton(requestStatus)}</Box>
              )}
            </PostActions>
          </LastSection>
        </PostWrapper>
      </Box>
      <SendProposalModal
        open={open}
        handleClose={handleClose}
        onConfirm={sendRequest}
      />
      <ProposalConnectModal
        open={open2}
        handleClose={handleClose2}
        onConfirm={sendRequest}
      />
      <IdeaHolderModal open={ideaModal} handleClose={handleCloseIdea} />
    </Box>
  );
}
const StatusMessageTemplate = ({
  icon,
  primaryText,
  secondaryText,
  secondaryTextColor,
}: {
  icon: React.ReactNode;
  primaryText: string;
  secondaryText: string;
  secondaryTextColor?: string;
}) => (
  <Box>
    <Typography variant="body2">
      {icon} {primaryText}
    </Typography>
    <Typography
      letterSpacing="0.03em"
      color={secondaryTextColor ?? PRIMARY_COLOR}
      textAlign="right"
      variant="caption"
    >
      {secondaryText}
    </Typography>
  </Box>
);
