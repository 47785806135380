// import { styled } from "@mui/system";
import IdeaComponent from "components/IdeaComponent";
import NoPost from "components/NoPost";
import IdeaPostSkeleton from "components/skeleton/IdeaPostSkeleton";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { ideaAllDataRequest } from "redux/features/idea/ideaAllSlice";

export default function IdeaFeed() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ideaAllDataRequest({ status: "accepted", active: "true" }));
  }, [dispatch]);

  const acceptedIdeas =
    useSelector((state: RootState) => state?.ideaAll?.data) ?? [];

  const fetchStatus = useSelector((state: RootState) => state?.ideaAll?.status);

  return (
    <div className="post-panel section-t-space" style={{ marginTop: 0 }}>
      {fetchStatus === "loading" ? (
        <>
          {[1, 2, 3, 4, 5]?.map((el) => (
            <IdeaPostSkeleton key={el} />
          ))}
        </>
      ) : (
        <>
          {acceptedIdeas?.length < 1 && <NoPost section="idea" />}
          {acceptedIdeas?.map((acceptedIdea: IBusinessTypeResponse, index) =>
            acceptedIdea.idea_type === "BusinessIdea" ? (
              <IdeaComponent acceptedIdea={acceptedIdea} />
            ) : null
          )}
        </>
      )}
    </div>
  );
}
