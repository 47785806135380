import { useContext, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IconButton, Menu, MenuItem, Tooltip, Box } from '@mui/material';
import { CiGlobe, CiLock, CiCircleMinus } from "react-icons/ci";
import { BusinessTypeContext } from 'pages/main/MainPage';

const IdeaSaleFilter = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { businessType, setBusinessType } = useContext(BusinessTypeContext);

    const handleNoneClick = () => {
        setBusinessType("");
    }

    const handleIdeaClick = () => {
        setBusinessType("BusinessIdea");
    }

    const handleSaleClick = () => {
        setBusinessType("Sale");
    }

    return (
        <>
            <Tooltip title="Filter">
                <IconButton
                    onClick={handleClick}
                    size="small"
                >
                    <FilterAltIcon />
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={handleNoneClick}>
                    <Box display="flex" gap="10px" alignItems="center">
                        <CiCircleMinus /> None
                    </Box>
                </MenuItem>
                <MenuItem onClick={handleIdeaClick}>
                    <Box display="flex" gap="10px" alignItems="center">
                        <CiLock /> Idea
                    </Box>
                </MenuItem>
                <MenuItem onClick={handleSaleClick}>
                    <Box display="flex" gap="10px" alignItems="center">
                        <CiGlobe /> Sale
                    </Box>
                </MenuItem>
            </Menu>
        </>
    )
}

export default IdeaSaleFilter;