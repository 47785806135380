import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericState } from "redux/app/genericState";

interface IDistrict {
  id: number;
  name: string;
  district_code: string;
  status: number;
  created_at: string;
  deleted_at: string | null;
  updated_at: string;
}

export interface IMunicipality {
  created_at: string;
  deleted_at: string | null;
  district: IDistrict;
  district_id: number;
  id: number | null;
  mun_code: string;
  name: string;
  status: number;
  type: string;
  updated_at: string;
}
interface ConnectState extends GenericState<IMunicipality[]> {}

const initialState: ConnectState = {
  data: [],
  error: undefined,
  status: "none",
};

export const municipalityDataRequest = createAction("GET_MUNICIPALITY_DATA");

const muniSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    get_municipality: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_municipality: (state, action: PayloadAction<IMunicipality[]>) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_municipality: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default muniSlice;
