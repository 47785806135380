import ModalComponent from "components/ModalComponent";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { updateStatus } from "services/ideaService";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

export default function ApproveModal(props: IProps) {
  const [data, setData] = useState(null);
  const payload = { status: "accepted", resubmit_reason: "" };
  const { id } = useParams<{
    id: string;
  }>();

  const navigate = useNavigate();

  const onConfirm = async () => {
    try {
      if (id) {
        const response = await updateStatus(+id, payload);
        setData(response.data);
        if (response?.data?.message === "Status changed to accepted") {
          toast(
            "The Business Proposition has passed screening test successfully",
            {
              type: "success",
            }
          );
          props?.handleClose();
          navigate(-1);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalComponent
      title="Screening Idea"
      subTitle="Are you sure you want to approve this idea?"
      path="/idea"
      onConfirm={onConfirm}
      {...props}
    />
  );
}
