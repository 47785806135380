import { styled } from "@mui/system";
import Top from "assets/png/lightbulb.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { analyticsIdeaData } from "redux/features/analytics-idea/analyticsSlice";
import numberFormatter from "utils/numberFormatter";
import LikedPages from "../left-content/components/LikedPages";

const TitleSection = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
  color: "#ffff",
});

const ActiveBox = styled("div")({
  padding: "10px 0px",
});

const Title = styled("div")({
  fontSize: "1.1em",
  color: "#f4f4f4",
});

const Wrap = styled("div")({
  display: "flex",
  gap: "15px",
});
const UL = styled("ul")({
  padding: "15px 0px",
  height: "320px",
  overflow: "auto",
});

export default function RightContent() {
  const dispatch = useDispatch();

  const fetchAnalytics = () => {
    dispatch(analyticsIdeaData());
  };

  useEffect(() => {
    fetchAnalytics();
  }, [analyticsIdeaData]);

  const analyticsIdeaNameRequest = useSelector(
    (state: RootState) => state?.analyticsIdea?.data?.top_idea
  );
  return (
    <div className="content-right">
      <div
        className="sticky-top custom-top"
        style={{
          top: "80px",
        }}
      >
        <div className="birthday-section event-sec my-div" style={{ height: "220px", overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: "#fff",
            }}
          >
            <img src={Top} alt="top" width="20px" />
            <h3>My Top Businesses</h3>
          </div>

          <div className="list-content">
            <UL>
              {analyticsIdeaNameRequest?.map((dl, index) => (
                <li>
                  <TitleSection>
                    <Wrap>
                      <ActiveBox>
                        <Title>{dl?.business_idea?.idea_name}</Title>
                      </ActiveBox>
                    </Wrap>
                    <p>{numberFormatter(dl?.view_count ?? 0)}</p>
                  </TitleSection>
                </li>
              ))}
            </UL>
          </div>
        </div>
        <LikedPages />
      </div>
    </div>
  );
}
