export default function IdeaCreatePost() {
  return (
    <div>
      {/* <div className="create-post">
        <div className="static-section">
          <div className="card-title">
            <h3>create post</h3>

            <div className="settings">
              <div className="setting-btn ms-2 setting-dropdown no-bg">
                <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                  <div
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      className="icon icon-font-color iw-14"
                      data-feather="more-horizontal"
                    ></i>
                  </div>
                  <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                    <ul>
                      <li>
                        <Link to="">
                          <i
                            className="icon-font-light iw-16 ih-16"
                            data-feather="edit"
                          ></i>
                          edit profile
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <i
                            className="icon-font-light iw-16 ih-16"
                            data-feather="user"
                          ></i>
                          view profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-input input-style icon-right">
            <input
              type="text"
              className="form-control enable"
              placeholder="write something here.."
            />
            <Link to="#">
              <img
                src="../assets/images/icon/translate.png"
                className="img-fluid lazyload icon"
                alt="translate"
              />
            </Link>
          </div>
        </div>
        <div className="create-bg">
          <div className="bg-post" id="bg-post">
            <div className="input-sec">
              <input
                type="text"
                className="form-control enable"
                placeholder="write something here.."
              />
              <div className="close-icon">
                <Link to="">
                  <i className="iw-20 ih-20" data-feather="x"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="options-input" id="additional-input">
          <Link id="icon-close" to="">
            <i
              className="iw-15 icon-font-light icon-close"
              data-feather="x"
            ></i>
          </Link>

          <div className="search-input place-input">
            <input
              type="text"
              className="form-control"
              placeholder="search for places..."
            />
            <Link to="#">
              <i
                className="iw-15 icon-left icon-font-light"
                data-feather="map-pin"
              ></i>
            </Link>
          </div>
        </div>

        <div id="post-btn" className="post-btn">
          <button className="Disable">post</button>
        </div>
      </div> */}
    </div>
  );
}
