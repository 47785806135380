import Loader from "components/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import loginSlice, { fetchUser } from "redux/features/login/LoginSlice";

export default function CallbackHandler() {
  const route = localStorage.getItem("route") !== null ? localStorage.getItem("route") : ""

  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("syi:access-token", params?.get("access_token") || "");
    if (params?.get("access_token")) {
      dispatch(
        loginSlice?.actions?.receive_token_data({
          access_token: params?.get("access_token") ?? "",
        })
      );
      dispatch(
        fetchUser({
          access_token: params?.get("access_token") ?? "",
        })
      );
    }
  }, [params, dispatch]);

  const userDetails = useSelector(
    (state: RootState) => state?.login?.data?.userDetails
  );

  useEffect(() => {
    if (userDetails?.id) {
      const isAdmin = userDetails?.roles?.some((el) => el?.name === "ad" || el?.name === "sa");
      if (isAdmin) {
        navigate("/admin");
      } else {
        route === "business_feed"
          ? navigate("/idea")
          : route === "investor_feed"
            ? navigate("/investor")
            : navigate("/")
        localStorage.removeItem("route")
      }
      toast(`Logged in as ${userDetails?.name}.`, {
        type: "success",
      });
    }
  }, [navigate, userDetails]);

  useEffect(() => {
    const loginTimeout = setTimeout(() => {
      navigate("/");
      toast("Something went wrong. Please try again.", {
        type: "error",
      });
    }, 20000);
    return () => {
      clearTimeout(loginTimeout);
    };
  });

  return <Loader />;
}
