import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import NoPostFound from "assets/svg/no-post.svg";

export default function NoPost({
  section,
  image,
}: {
  section: string;
  image?: string;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="350px"
      gap="20px"
    >
      <img
        src={image ? image : NoPostFound}
        alt="Not found"
        style={{
          height: "14vmax",
        }}
      />
      <Typography
        fontWeight={600}
        color="#51595e"
        variant="body1"
        textTransform="capitalize"
      >
        <em>No {section}s found</em>
      </Typography>
    </Box>
  );
}
