import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdeaholderType } from "models/Ideaholder";
import { GenericState } from "redux/app/genericState";

interface IdeaholderState extends GenericState<IIdeaholderType[] | null> {}

const initialState: IdeaholderState = {
  data: [],
  error: undefined,
  status: "none",
};

export const ideaholderAllDataRequest = createAction(
  "IDEAHOLDER_ALL_DATA_REQUEST"
);

const ideaholderAllSlice = createSlice({
  name: "ideaholder",
  initialState,
  reducers: {
    get_ideaholder_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_ideaholder_all_data: (
      state,
      action: PayloadAction<IIdeaholderType[]>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_ideaholder_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default ideaholderAllSlice;
