import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAllAccessRequests,
  // getIdeaholderAccessRequests,
  // getInvestorAccessRequests,
} from "services/accessRequestService";
import accessRequestAllSlice, {
  ideaAccessAllDataRequest,
  // ideaAccessIdeaholderDataRequest,
  // ideaAccessInvestorDataRequest,
} from "./accessRequestAllSlice";

function* fetchSaga(
  action: ReturnType<typeof ideaAccessAllDataRequest>
): object {
  yield put(accessRequestAllSlice.actions.get_access_request_all_data());

  try {
    const response = yield call(getAllAccessRequests, action.payload.status);
    yield put(
      accessRequestAllSlice.actions.success_access_request_all_data(
        response.data
      )
    );
  } catch (e) {
    yield put(
      accessRequestAllSlice.actions.error_access_request_all_data(e as Error)
    );
  }
}

// function* fetchIdeaholderAccessSaga(
//   action: ReturnType<typeof ideaAccessIdeaholderDataRequest>
// ): object {
//   yield put(accessRequestAllSlice.actions.get_access_request_all_data());

//   try {
//     const response = yield call(
//       getIdeaholderAccessRequests,
//       action.payload.id,
//       action?.payload?.status
//     );
//     yield put(
//       accessRequestAllSlice.actions.success_access_request_all_data(
//         response.data
//       )
//     );
//   } catch (e) {
//     yield put(
//       accessRequestAllSlice.actions.error_access_request_all_data(e as Error)
//     );
//   }
// }

// function* fetchInvestorAccessSaga(
//   action: ReturnType<typeof ideaAccessInvestorDataRequest>
// ): object {
//   yield put(accessRequestAllSlice.actions.get_access_request_all_data());

//   try {
//     const response = yield call(
//       getInvestorAccessRequests,
//       action.payload.id,
//       action?.payload?.status
//     );
//     yield put(
//       accessRequestAllSlice.actions.success_access_request_all_data(
//         response.data
//       )
//     );
//   } catch (e) {
//     yield put(
//       accessRequestAllSlice.actions.error_access_request_all_data(e as Error)
//     );
//   }
// }

function* accessRequestAllSaga() {
  yield takeLatest(ideaAccessAllDataRequest.type, fetchSaga);
  // yield takeLatest(
  //   ideaAccessIdeaholderDataRequest.type,
  //   fetchIdeaholderAccessSaga
  // );
  // yield takeLatest(ideaAccessInvestorDataRequest.type, fetchInvestorAccessSaga);
}

export default accessRequestAllSaga;
