import { Box, Divider } from "@mui/material";
import { API_BASE_URL } from "constants/api-constants";
import useLoginState from "hooks/useLoginState";
import { IIdeaType } from "models/Idea";
import { useState } from "react";
import { IoEarth } from "react-icons/io5";
import {
  followIdea,
  postDisLiked,
  postFavourited,
  postLiked,
  postUnfavouited,
  unfollowIdea,
} from "services/ideaService";
import dateFormatter from "utils/dateFormatter";
import numberFormatter from "utils/numberFormatter";
import CustomButton2 from "./CustomButton2";
import InvestorModal from "./InvestorModal";
import PostActions from "./PostActions";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import { IBusinessTypeResponse } from "models/IdeaResponse";

export default function IdeaComponent({
  acceptedIdea,
}: {
  acceptedIdea: IBusinessTypeResponse;
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [hasFollowed, setHasFollowed] = useState(acceptedIdea?.has_followed);
  const [like, setLike] = useState(acceptedIdea?.like_count ?? 0);
  const [favourites, setFavourites] = useState(
    acceptedIdea?.favourite_count ?? 0
  );
  const [hasLikedPost, setHasLikedPost] = useState(acceptedIdea?.has_liked);
  const [hasFav, setHasFav] = useState(acceptedIdea?.has_favourite);
  const { userDetails } = useLoginState();

  const onLikeByInvestor = async (id: number | undefined) => {
    const res = await postLiked({
      idea_id: id,
      liked_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(1);
      setLike(like + 1);
    }
  };

  const onDisLikeByInvestor = async (id: number | undefined) => {
    const res = await postDisLiked({
      idea_id: id,
      liked_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasLikedPost(0);
      setLike(like - 1);
    }
  };

  const onFavByInvestor = async (id: number | undefined) => {
    const res = await postFavourited({
      idea_id: id,
      favourite_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(1);
      setFavourites(favourites + 1);
    }
  };

  const onUnfavByInvestor = async (id: number | undefined) => {
    const res = await postUnfavouited({
      idea_id: id,
      favourite_by: userDetails?.id,
    });
    if (res?.status === 200 || res?.status === 201) {
      setHasFav(0);
      setFavourites(favourites - 1);
    }
  };

  const onFollowByInvestor = async (id: number | undefined) => {
    const res = await followIdea({
      idea_id: id,
      followed_by: userDetails?.id,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(1);
    }
  };
  const onUnFollowByInvestor = async (id: number | undefined) => {
    const res = await unfollowIdea({
      idea_id: id,
      followed_by: userDetails?.id,
    });

    if (res?.status === 200 || res?.status === 201) {
      setHasFollowed(0);
    }
  };

  const coverImage = acceptedIdea?.files?.find((obj) =>
    obj?.filename?.startsWith("cover_image")
  );

  return (
    <div className="post-wrapper col-grid-box">
      <div className="idea-title">
        <div className="media">
          <h5 className="fixed-width-text">
            {acceptedIdea.business_idea.idea_name}
          </h5>
          <h6 className="light-color">
            {dateFormatter(acceptedIdea?.created_at)}
          </h6>
        </div>
        <div className="follow-button-all">
          {+(hasFollowed ?? "") === 1 ? (
            <CustomButton2
              onClick={() => onUnFollowByInvestor(acceptedIdea?.id)}
              text="Followed"
              type="outlined"
            />
          ) : (
            <CustomButton2
              onClick={() => onFollowByInvestor(acceptedIdea?.id)}
              text="+ Follow"
              type="filled"
            />
          )}
        </div>
      </div>

      <div className="post-details">
        <div className="detail-box">
          <p className="fixed-desc-box">
            {acceptedIdea?.business_idea.idea_description}
          </p>
          <h5 className="tag">
            {acceptedIdea?.business_idea.tags?.map((item, index) => (
              <span key={index}>#{item.tag} </span>
            ))}
          </h5>
        </div>
        <div
          className="img-wrapper"
          style={{ maxHeight: "350px", overflow: "hidden" }}
        >
          {acceptedIdea?.files
            ?.find((obj) => obj?.filename?.startsWith("cover_image"))
            ?.path.endsWith(".mp4") ? (
            <Plyr
              source={{
                type: "video",
                sources: [
                  {
                    src: `${API_BASE_URL}/${
                      acceptedIdea?.files?.find((obj) =>
                        obj?.filename?.startsWith("cover_image")
                      )?.path
                    }`,
                  },
                ],
              }}
              options={{
                ratio: "16:9",
              }}
            />
          ) : (
            <img
              loading="lazy"
              src={`${API_BASE_URL}/${
                acceptedIdea?.files?.find((obj) =>
                  obj?.filename?.startsWith("cover_image")
                )?.path
              }`}
              className="img-fluid lazyload"
              alt=""
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                overflow: "hidden",
              }}
            />
          )}
        </div>

        <Box className="likes-section">
          <Box className="people-likes flex-space-between flex-direction">
            <Box className="idea-location" title="Preferred Location">
              <IoEarth />
              {acceptedIdea.business_idea.preferred_location}
            </Box>
            <h5 className="tag new-tag text-right flex-align-center">
              Expected Investment: Rs.
              {numberFormatter(
                +acceptedIdea?.business_idea.expected_investment ?? 0
              )}
            </h5>
          </Box>
        </Box>
        <Divider className="hrClass hrfeed" />

        <PostActions
          path={`/idea/detail/${acceptedIdea?.id}`}
          likes={like ?? 0}
          favourite={favourites ?? 0}
          views={acceptedIdea?.view_count ?? 0}
          onLiked={() => onLikeByInvestor(acceptedIdea?.id)}
          onDisliked={() => onDisLikeByInvestor(acceptedIdea?.id)}
          onFavorite={() => onFavByInvestor(acceptedIdea?.id)}
          onUnfavourite={() => onUnfavByInvestor(acceptedIdea?.id)}
          hasLiked={hasLikedPost}
          hasFavourite={hasFav}
          className="bottom-section"
        />
        <Divider className="hrClass" />
        <InvestorModal open={open} handleClose={handleClose} />
      </div>
    </div>
  );
}
