import Feeds from "./components/Feeds";

export default function CenterContent() {
  return (
    <>
      <div className="content-center">
        <Feeds />
      </div>
    </>
  );
}
