import locationClient from "./locationClient";

export const getMunicipality = async () => {
  return await locationClient.get("/municipality");
};

export const getDistrict = async () => {
  return await locationClient.get("/district");
};

export const getArea = async (id: number | string) => {
  return await locationClient.get(`/location/area?municipality_id=${id}`);
};

export const getStreet = async (id: number | string) => {
  return await locationClient.get(`/location/street?area_id=${id}`);
};

export const getNearestLandmark = async () => {
  return await locationClient.get("/location/landmark");
};

export const addLocationService = async (data: any) => {
  return await locationClient.post("/location", data);
};
