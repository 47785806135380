import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import * as React from "react";
import ScreeningTable from "./ScreeningTable";

const TabCover = styled("div")({
  height: "85vh",
  width: "100%",
});

export default function StatusTabs() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Screening Requests</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Screenings Requests" value="1" />
              <Tab label="Access Requests" value="2" />
              <Tab label="Proposals" value="3" />
              <Tab label="Meetings" value="4" />
              <Tab label="Agreements" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ScreeningTable />
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel>
          <TabPanel value="4">Item Three</TabPanel>
          <TabPanel value="5">Item Three</TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
