import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  { field: "SN", headerName: "S.N", flex: 1, maxWidth: 60 },
  { field: "Investor", headerName: "Investor Name", flex: 1, minWidth: 200 },
  {
    field: "Ideaholder",
    headerName: "Ideaholder Name",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "Idea",
    headerName: "Idea",
    flex: 1,
    minWidth: 200,
  },
  {
    field: "MeetingRequestDate",
    headerName: "Meeting Request Date",
    flex: 1,
    minWidth: 200,
  },

  {
    field: "Action",
    headerName: "Action",
    flex: 1,
    minWidth: 90,
    maxWidth: 90,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Link to={`/admin/user/${params.id}`}>
            <IconButton size="small">
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </Link>
        </Box>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    SN: 1,
    Investor: "XYZ",
    Ideaholder: "Abc",
    Idea: "xyzidea",
    MeetingRequestDate: "2022/10/15",
    Action: "Start Screening",
  },
  {
    id: 2,
    SN: 2,
    Investor: "XYZ",
    Ideaholder: "Abc",
    Idea: "xyzidea",
    MeetingRequestDate: "2022/10/20",
    Action: "Start Screening",
  },
];

export default function MeetingTable() {
  return (
    <div style={{ height: 390, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // onRowClick={handleClick}
        // checkboxSelection
      />
    </div>
  );
}
