import ModalComponent from "components/ModalComponent";

interface IProps {
  handleClose: () => void;
  open: boolean;
}

export default function ScreeningIdeaModal(props: IProps) {
  return (
    <ModalComponent
      title="Send Idea for Screening?"
      subTitle="Are you sure you want to send this idea for scrrening?"
      {...props}
    />
  );
}
