import { call, put, takeLatest } from "redux-saga/effects";
import { getIdeaHolderAnalytics } from "services/ideaholderService";
import analyticsSlice, { analyticsIdeaData } from "./analyticsSlice";

function* fetchSaga(action: ReturnType<typeof analyticsIdeaData>): object {
  yield put(analyticsSlice.actions.get_analytics_idea_data());

  try {
    const response = yield call(getIdeaHolderAnalytics);
    yield put(
      analyticsSlice.actions.success_analytics_idea_data(response.data)
    );
  } catch (e) {
    yield put(analyticsSlice.actions.error_analytics_idea_data(e as Error));
  }
}

function* analyticsIdeaDataSaga() {
  yield takeLatest(analyticsIdeaData.type, fetchSaga);
}

export default analyticsIdeaDataSaga;
