import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ideaAllDataRequest } from "redux/features/idea/ideaAllSlice";
import ScreeningReqTable from "./ScreeningReqTable";
import { toast } from "react-toastify";
import { getAllIdeas } from "services/ideaService";
import { IDataType } from "redux/features/connect/connectAllSlice";
import IdeaSaleFilter from "components/IdeaSaleFilter";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export type IIdeaStatusType =
  | "all"
  | "pending"
  | "resubmit"
  | "accepted"
  | "rejected"
  | "re-review";

export default function ScreeningReqTabs() {
  const [value, setValue] = React.useState<IIdeaStatusType>("all");
  const [isFetched, setIsFetched] = React.useState(false);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(
      ideaAllDataRequest({
        status: value === "all" ? null : value,
      })
    );
  }, [dispatch, isFetched, value]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IIdeaStatusType
  ) => {
    setIsFetched(false);
    setValue(newValue);
  };

  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    const res = await getAllIdeas(null)
    if (res?.status === 200 || res.status === 201) {
      setAllData(res?.data);
    } else {
      toast("Something went wrong");
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [value]);

  const acceptedCount = allData.filter((data: IDataType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IDataType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IDataType) => data.status === "Pending").length
  const reSubmitCount = allData.filter((data: IDataType) => data.status === "Resubmit").length
  const reReviewCount = allData.filter((data: IDataType) => data.status === "Re-review").length

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">

        <span className="tab-title">
          Business Proposition Screening Requests
        </span>
        <TabContext value={value}>
          <Box display='flex' justifyContent='space-between'>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"All" + ` (${allData.length})`}
                  value="all"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Pending" + ` (${pendingCount})`}
                  value="pending"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Resubmit" + ` (${reSubmitCount})`}
                  value="resubmit"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Re-Review" + ` (${reReviewCount})`}
                  value="re-review"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Accepted" + ` (${acceptedCount})`}
                  value="accepted"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Rejected" + ` (${rejectedCount})`}
                  value="rejected"
                />
              </TabList>
            </Box>
            <Box mt="30px">
              <IdeaSaleFilter />
            </Box>
          </Box>

          <Box mt="-16px">
            <TabPanel value="all">
              <ScreeningReqTable />
            </TabPanel>
            <TabPanel value="pending">
              <ScreeningReqTable />
            </TabPanel>
            <TabPanel value="resubmit">
              <ScreeningReqTable />
            </TabPanel>
            <TabPanel value="re-review">
              <ScreeningReqTable />
            </TabPanel>
            <TabPanel value="accepted">
              <ScreeningReqTable />
            </TabPanel>
            <TabPanel value="rejected">
              <ScreeningReqTable />
            </TabPanel>
          </Box>
        </TabContext>
      </TabCover>
    </Box>
  );
}
