import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { GenericState } from "redux/app/genericState";

type UserSuccessResponse = (IInvestorType | IIdeaholderTypeResponse)[];

interface UserState extends GenericState<UserSuccessResponse> {
  id?: string;
}

const initialState: UserState = {
  data: [],
  error: undefined,
  status: "none",
};

export const userDataRequest = createAction<{id: string}>("GET_USER_DATA");

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    get_user: (state, action: PayloadAction<{id: string}>) => {
      state.status = "loading";
      state.data = initialState.data;
      state.error = undefined;
      state.id = action.payload.id;
    },
    success_user: (state, action: PayloadAction<UserSuccessResponse>) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_user: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default userSlice;
