import ModalComponent from "components/ModalComponent";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { createAccessRequest } from "services/accessRequestService";

interface IProps {
  handleClose: () => void;
  open: boolean;
  onConfirm: () => void;
  type: string;
}

export default function AccessRequestModal(props: IProps) {
  const params = useParams();
  const investorId = useSelector(
    (state: RootState) => state?.investor?.data?.id
  );

  const sendAccessRequest = async () => {
    const res = await createAccessRequest({
      investor_id: investorId ?? 0,
      idea_id: params?.id ?? 0,
    });
    if (res.status === 201 || res.status === 200) {
      props?.onConfirm();
      toast("Access request sent successfully");
      props.handleClose();
    }
  };
  return (
    <ModalComponent
      title="Send Access Request"
      subTitle={`Are you sure you want to send access request to this business ${props.type}?`}
      {...props}
      onConfirm={sendAccessRequest}
    />
  );
}
