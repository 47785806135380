import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "redux/app/store";
import { useState, useEffect, useContext } from "react";
import { BusinessTypeContext } from "pages/main/MainPage";
import { IBusinessTypeResponse } from "models/IdeaResponse";

const columns: GridColumns = [
  { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
  {
    field: "full_name",
    headerName: "Business Name",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      let result = [];
      if (params.row.idea_holder.full_name) {
        result.push(params.row.idea_holder.full_name);
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  {
    field: "idea_name",
    headerName: "Business Proposition",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      let result = [];
      if (params.row.idea_type === "BusinessIdea") {
        result.push(`${params.row.business_idea?.idea_name}` + ' (Idea)');
      } else if (params.row.idea_type === "Sale") {
        result.push(`${params.row.idea_sale?.company_name}` + ' (Sale)');
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  {
    field: "created_at",
    headerName: "Date",
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
  },
  // {
  //   field: "expected_investment",
  //   headerName: "Investment",
  //   flex: 1,
  //   minWidth: 100,
  // },
  // {
  //   field: "location",
  //   headerName: "Location",
  //   flex: 1,
  //   minWidth: 100,
  // },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Chip
            label={params?.row?.status}
            variant="filled"
            sx={{
              color: "#fff",
              bgcolor:
                STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
            }}
          />
        </Box>
      );
    },
  },
  {
    field: "Action",
    headerName: "Action",
    flex: 1,
    minWidth: 90,
    maxWidth: 90,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Link to={`/admin/screening-request/detail/${params?.row?.id}`}>
            <IconButton size="small">
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </Link>
        </Box>
      );
    },
  },
];

export default function ScreeningReqTable() {
  const ideas = useSelector((state: RootState) => state?.ideaAll);

  const { businessType, setBusinessType } = useContext(BusinessTypeContext);

  useEffect(() => {
    setBusinessType("");
  }, [])

  const [filteredIdeas, setFilteredIdeas] = useState<Array<IBusinessTypeResponse>>([])

  useEffect(() => {
    if (businessType === "BusinessIdea") {
      const filteredIdeasArray = ideas?.data.filter((inData) => inData.idea_type === "BusinessIdea")
      setFilteredIdeas(filteredIdeasArray);
    } else if (businessType === "Sale") {
      const filteredIdeasArray = ideas?.data.filter((inData) => inData.idea_type === "Sale")
      setFilteredIdeas(filteredIdeasArray);
    }
  }, [businessType])

  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={businessType === ""
          ? ideas?.data?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
          : filteredIdeas?.map((el: IBusinessTypeResponse, index: number) => {
            return {
              ...el,
              sn: index + 1,
            };
          })
        }
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      // onRowClick={handleClick}
      // checkboxSelection
      />
    </div>
  );
}
