import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box/Box";
import { darken, lighten } from "@mui/system";
import AddressComponent from "components/AddressComponent";
import CustomButton from "components/CustomButton";
import DisplayFile from "components/DisplayFile";
import PhoneCountryInput from "components/PhoneCountry";
import UploadFile from "components/UploadFile";
import { API_BASE_URL } from "constants/api-constants";
import GoogleLibNumber from "google-libphonenumber";
import useLoginState from "hooks/useLoginState";
import usePhoneValidator, {
  CountryCodeType,
  defaultCode,
} from "hooks/usePhoneValidator";
import { useMemo, useState } from "react";
import {
  Controller,
  FieldErrorsImpl,
  FormProvider,
  useForm,
} from "react-hook-form";
import { changeInvestorFile } from "services/InvestorService";
import * as yup from "yup";
import {
  IIndividualType,
  IInvestorType,
  InvestorCategoriesList,
} from "./InvestorProfileForm";

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

export const genderLabel: IGenderType[] = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Rather not say", value: "others" },
];

export type IGenders = "male" | "female" | "others";

type IGenderType = {
  label: string;
  value: IGenders;
};

const phoneUtil = GoogleLibNumber.PhoneNumberUtil.getInstance();

const imageOnlyField = ["profile_pic", "citizenship"];

const DESCRIPTION_LIMIT = 500;

const validationResolver = (phoneCode: string, update?: boolean) => {
  return {
    schema: yup.object().shape({
      full_name: yup
        .string()
        .required("Full Name is required")
        .max(70, "Text must be less than 70 characters"),
      investor_detail: yup
        .string()
        .required("Investor detail is required")
        .max(DESCRIPTION_LIMIT, "Text must be less than 500 characters"),
      age: yup
        .number()
        .typeError("Age must be a number")
        .required("Age is required")
        .min(18, "Age must be greater than 18")
        .max(115, "Age must be less than 115"),
      gender: yup
        .string()
        .nullable()
        .required("Gender must be male, female or undisclosed")
        .oneOf(genderLabel.map((gender) => gender.value)),
      title_designation: yup.string().required("Title designation is required"),
      phone: yup
        .string()
        .trim()
        .required("Phone is  required")
        .test(
          "is_phone_valid",
          "Phone number needs to be valid",
          function (value) {
            if (!value?.length) {
              return this.createError({
                message: "Phone number not provided.",
              });
            }
            try {
              const number = phoneUtil.parse(value, phoneCode);

              return phoneUtil.isValidNumber(number);
            } catch (err) {
              return this.createError({
                message: "Phone number is not correct",
              });
            }
          }
        ),
      email: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
      min_amount: yup
        .number()
        .required("Minimum amount is required.")
        .typeError("Minimum amount is required.")
        .max(4000000000, "Amount cannot exceed 4000000000"),

      max_amount: yup
        .number()
        .typeError("Maximum amount is required.")
        .required("Maximum amount is required.")
        .moreThan(
          yup.ref("min_amount"),
          "Maximum amount cannot be less than minimum amount."
        )
        .max(4000000000, "Amount cannot exceed 4000000000"),

      permanent_location_municipality: yup
        .object()
        .shape({
          name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Location is required"),
      permanent_location_area: yup
        .object()
        .shape({
          area_name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Street is required"),

      permanent_location_street: yup
        .object()
        .shape({
          street_name: yup.string().required("Required"),
        })
        .nullable()
        .required("Permanent Street is required"),

      investment_preferred_location: yup
        .string()
        .required("Add your preferred location"),

      investor_interests: yup
        .string()
        .required("Mention your interests")
        .max(500, "Text must be less than 500 characters"),

      investment_sectors: yup
        .array()
        .of(
          yup.object().shape({
            created_at: yup.date().nullable(),
            deleted_at: yup.date().nullable(),
            description: yup.string().required("Required"),
            id: yup.number().required("Required"),
            name: yup.string().required("Required"),
            status: yup.number().required("Required"),
            updated_at: yup.date().nullable(),
          })
        )
        .nullable()
        .min(1, "Provide at least 1 sector")
        .required("Provide at least 1 sector"),

      cv: yup
        .mixed()
        .test("required", "You need to provide a cv", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      profile_pic: yup
        .mixed()
        .test("required", "You need to provide a profile pic", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      citizenship: yup
        .mixed()
        .test("required", "You need to provide a citizenship", (file: File) => {
          if (update || file) return true;
          return false;
        })
        .test(
          "fileSize",
          "The file size must not be greater than 25MB",
          (file: File) => {
            return update || (file && file.size <= 25000000);
          }
        ),
      remarks: yup
        .string()
        .max(DESCRIPTION_LIMIT, "Text must be less than 500 characters"),
    }),
  };
};

type ErrorsType = Partial<FieldErrorsImpl<IIndividualType>>;

export default function IndividualInvestor({
  onSubmit,
  data,
  isReadOnly = false,
  loading,
  defaultCountryCode,
  viewFor,
}: {
  onSubmit: (e: IInvestorType) => void;
  isReadOnly?: boolean;
  data?: IIndividualType | null;
  loading?: boolean;
  defaultCountryCode?: CountryCodeType;
  viewFor: string | number;
}) {
  // Phone
  const [trackCountryCode, setTrackCountryCode] = useState(
    defaultCountryCode ?? defaultCode
  );

  const { userDetails } = useLoginState();

  const registerSchema = useMemo(() => {
    const { schema } = validationResolver(
      trackCountryCode?.code ?? "np",
      !!data?.id
    );
    return schema;
  }, [trackCountryCode]);
  // End phone

  const formatISGender = (val: string) => {
    const genders = ["others", "male", "female"];
    return genders[+val];
  };

  const hookForm = useForm<IInvestorType>({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      investor_profile: "Individual",
      interest_in_buying_business: "0",
      full_name: data?.full_name ?? userDetails?.name ?? "",
      email: data?.email ?? userDetails?.email ?? "",
      age: data?.age ?? "",
      phone: data?.phone ?? userDetails?.phone_no ?? "",
      gender:
        data?.gender?.toLowerCase() ??
        formatISGender(userDetails?.gender ?? "") ??
        null,
      title_designation: data?.title_designation ?? "",
      citizenship: null,
      cv: null,
      profile_pic: null,
      permanent_location_municipality:
        data?.permanent_location_municipality ??
        userDetails?.location?.municipality ??
        null,
      permanent_location_area:
        data?.permanent_location_area ?? userDetails?.location?.area ?? null,
      permanent_location_street:
        data?.permanent_location_street ??
        userDetails?.location?.street ??
        null,
      temporary_location_area: data?.temporary_location_area ?? null,
      temporary_location_street: data?.temporary_location_street ?? null,
      temporary_location_municipality:
        data?.temporary_location_municipality ?? null,
      investment_sectors: data?.investment_sectors ?? [],
      investment_preferred_location: data?.investment_preferred_location ?? "",
      investor_detail: data?.investor_detail ?? "",
      investor_interests: data?.investor_interests ?? "",
      max_amount: data?.max_amount ?? "",
      min_amount: data?.min_amount ?? "",
      remarks: data?.remarks ?? "",
    },
  });

  const {
    register,
    handleSubmit,
    control,

    formState: { errors },
  } = hookForm;

  //for phone validation

  const {
    watch: watchPhone,
    setValue: setPhoneValue,
    control: controlPhone,
  } = usePhoneValidator({
    useRegisterForm: hookForm,
    defaultCountryCode: defaultCountryCode ?? defaultCode,
  });

  const code = watchPhone("code");
  const setCountry = (code: CountryCodeType) => {
    setTrackCountryCode(code);
    setPhoneValue("code", code);
  };

  // end phone validation

  const checkFile = (name: string) => {
    if ((data?.files ?? [])?.length > 0) {
      const exists = data?.files?.find(
        (el) => el?.filename?.split("-")?.[0] === name
      );
      if (exists) {
        return (
          <UploadFile
            profile={data}
            name={name}
            imageOnly={imageOnlyField?.includes(name)}
            fileData={exists}
            changeService={changeInvestorFile}
            isEdit={!!data}
            title={exists?.filename?.split("-")?.[0]?.replaceAll("_", " ")}
          />
        );
      } else {
        return (
          <UploadFile
            profile={data}
            imageOnly={imageOnlyField?.includes(name)}
            isEdit={!!data}
            name={name}
            changeService={changeInvestorFile}
            title={`Upload your ${name?.replaceAll("_", " ")}`}
          />
        );
      }
    } else {
      return (
        <UploadFile
          changeService={changeInvestorFile}
          imageOnly={imageOnlyField?.includes(name)}
          isEdit={!!data}
          name={name}
          title={`Upload your ${name?.replaceAll("_", " ")}`}
        />
      );
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...hookForm}>
        <div>
          <div className="title-main">
            <span>Personal Details</span>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Full Name"
                type="text"
                autoComplete="Full Name"
                fullWidth
                required
                {...register("full_name")}
                error={!!(errors as ErrorsType)?.full_name}
                helperText={(errors as ErrorsType)?.full_name?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Title/Designation"
                type="text"
                autoComplete="Title Designation"
                fullWidth
                required
                {...register("title_designation")}
                error={!!errors?.title_designation}
                helperText={errors?.title_designation?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Email"
                type="email"
                autoComplete="current-email"
                fullWidth
                required
                {...register("email")}
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Age"
                type="number"
                autoComplete="age-email"
                fullWidth
                required
                {...register("age")}
                error={!!(errors as ErrorsType)?.age}
                helperText={(errors as ErrorsType)?.age?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel required>Gender</InputLabel>
                    <Select {...field} fullWidth label="Gender">
                      {genderLabel.map((el) => (
                        <MenuItem key={el?.value} value={el.value}>
                          {el?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              {/* <Autocomplete
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      value={value}
                      disablePortal
                      options={genderLabel}
                      getOptionLabel={(item) => (item.label ? item.label : "")}
                      sx={{ minWidth: 300 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gender"
                          error={!!errors?.gender}
                          helperText={errors?.gender?.message}
                        />
                      )}
                    /> */}
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <PhoneCountryInput countryCode={code} setCountryCode={setCountry}>
                <Controller
                  name="num"
                  control={controlPhone}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      label="Phone Number"
                      type="tel"
                      autoComplete="current-phone"
                      fullWidth
                      required
                      error={!!errors?.phone}
                      helperText={errors?.phone?.message}
                    />
                  )}
                />
              </PhoneCountryInput>
            </Grid>
          </Grid>
          <div className="idea-form-upload-cv">
            {!isReadOnly && (
              <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} sm={6}>
                  {checkFile("citizenship")}

                  {(errors as ErrorsType) && (
                    <h6 className="m-top" style={{ color: "#d32f2f" }}>
                      {(errors as ErrorsType)?.citizenship?.message}
                    </h6>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {checkFile("cv")}
                  {(errors as ErrorsType) && (
                    <h6 className="m-top" style={{ color: "#d32f2f" }}>
                      {(errors as ErrorsType)?.cv?.message}
                    </h6>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {checkFile("profile_pic")}

                  {(errors as ErrorsType) && (
                    <h6 className="m-top" style={{ color: "#d32f2f" }}>
                      {(errors as ErrorsType)?.profile_pic?.message}
                    </h6>
                  )}
                </Grid>
              </Grid>
            )}
            {(data?.files ?? [])?.length > 0 && isReadOnly && (
              <Grid container spacing={2} marginTop="2px">
                {data?.files?.map((el, index) => (
                  <Grid item xs={12} sm={6}>
                    <DisplayFile
                      id={el?.id}
                      readonly={isReadOnly}
                      title={el?.filename
                        ?.split("-")?.[0]
                        ?.replaceAll("_", " ")}
                      fileName={el?.filename}
                      path={`${API_BASE_URL}/${el?.path}`}
                      key={index}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>
          <AddressComponent
            hookForm={hookForm}
            isReadOnly={isReadOnly}
            viewFor={data?.id ? "update" : "create"}
          />

          <div className="title-main">
            <span>Investment Details</span>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="investment_sectors"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value}
                    disablePortal
                    readOnly={isReadOnly}
                    options={InvestorCategoriesList}
                    getOptionLabel={(item) => (item ? item?.name : "")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        inputProps={{
                          ...params.inputProps,
                          required: value?.length === 0,
                        }}
                        label="Investment Sectors"
                        error={!!errors?.investment_sectors}
                        helperText={errors?.investment_sectors?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Investment Preferred Location"
                type="text"
                autoComplete="Investment Preferred Location"
                fullWidth
                required
                {...register("investment_preferred_location")}
                error={!!errors?.investment_preferred_location}
                helperText={errors?.investment_preferred_location?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="investor_detail"
                control={control}
                render={({ field }) => {
                  const { value } = field;
                  return (
                    <TextField
                      {...field}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      inputProps={{
                        maxlength: DESCRIPTION_LIMIT,
                      }}
                      fullWidth
                      required
                      multiline
                      rows={4}
                      label="Investor Details"
                      {...register("investor_detail")}
                      error={!!errors?.investor_detail}
                      helperText={
                        value?.length +
                        "/" +
                        DESCRIPTION_LIMIT +
                        (errors?.investor_detail?.message
                          ? ` ${errors?.investor_detail?.message}`
                          : "")
                      }
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Interests"
                type="text"
                autoComplete="Interests"
                fullWidth
                required
                {...register("investor_interests")}
                error={!!errors?.investor_interests}
                helperText={errors?.investor_interests?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Min Investment Amount (NPR)"
                type="number"
                autoComplete="Min Amount"
                fullWidth
                required
                {...register("min_amount")}
                error={!!errors?.min_amount}
                helperText={errors?.min_amount?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                InputProps={{
                  readOnly: isReadOnly,
                }}
                label="Max Investment Amount (NPR)"
                type="number"
                autoComplete="Max Amount"
                fullWidth
                required
                {...register("max_amount")}
                error={!!errors?.max_amount}
                helperText={errors?.max_amount?.message}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="remarks"
                control={control}
                render={({ field }) => {
                  const { value } = field;
                  return (
                    <TextField
                      {...field}
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      inputProps={{
                        maxlength: DESCRIPTION_LIMIT,
                      }}
                      fullWidth
                      multiline
                      rows={4}
                      label="Remarks"
                      {...register("remarks")}
                      error={!!errors?.remarks}
                      helperText={
                        value?.length +
                        "/" +
                        DESCRIPTION_LIMIT +
                        (errors?.remarks?.message
                          ? ` ${errors?.remarks?.message}`
                          : "")
                      }
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </div>
      </FormProvider>

      <i style={{ color: "gray" }}>*Please wait 24 hours for approval.</i>

      {!isReadOnly && (
        <Box className="box-gap flex-center" marginTop="20px">
          <CustomButton
            loading={loading}
            isSubmit={true}
            text={
              data?.id ? "Update Investor Profile" : "+ Create Investor Profile"
            }
            type="filled"
          />
        </Box>
      )}
    </form>
  );
}
