import { Global } from "@emotion/react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {
  DetailBox,
  PostWrapper,
  Title,
  TitleSection,
  Type,
  Wrap,
} from "components/PostComponent";

export default function InvestorPostSkeleton() {
  return (
    <PostWrapper className="post-wrapper">
      <Global
        styles={{
          ".MuiSkeleton-root": {
            backgroundColor: "rgb(34 154 184 / 15%) !important",
          },
        }}
      />
      <TitleSection>
        <Wrap>
          <Skeleton
            animation="wave"
            variant="circular"
            width="40px"
            height="40px"
          />
          <Box>
            <Title>
              <Skeleton animation="wave" height={20} width="150px" />
            </Title>
            <Type>
              <Skeleton animation="wave" height={20} width="100px" />
            </Type>
          </Box>
        </Wrap>

        <Box>
          <Skeleton animation="wave" height="45px" width="87px" />
        </Box>
      </TitleSection>

      <Divider className="hrClass" />
      {[1, 2, 3, 4, 5]?.map((el) => (
        <DetailBox
          style={{
            display: "flex",
            gap: "10px",
          }}
          className="fixed-width-text"
          key={el}
        >
          <span className="title">
            <Skeleton
              animation="wave"
              height="30px"
              width={`${50 + Math.random() * 150}px`}
            />{" "}
          </span>
          <span>
            {" "}
            <Skeleton
              animation="wave"
              height="30px"
              width={`${50 + Math.random() * 250}px`}
            />{" "}
          </span>
        </DetailBox>
      ))}

      <Divider className="hrClass hrFeed" />

      <Box height="45px" display="flex" justifyContent="space-between">
        <Skeleton animation="wave" height="45px" width="87px" />
        <Box display="flex" gap="15px">
          <Skeleton
            animation="wave"
            variant="circular"
            height="40px"
            width="40px"
          />
          <Skeleton
            animation="wave"
            variant="circular"
            height="40px"
            width="40px"
          />
          <Skeleton
            animation="wave"
            variant="circular"
            height="40px"
            width="40px"
          />
        </Box>
      </Box>
    </PostWrapper>
  );
}
