import ModalComponent from "components/ModalComponent";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { createProposal } from "services/ideaholderService";

interface IProps {
  handleClose: () => void;
  open: boolean;
  onConfirm: () => void;
}

export default function SendProposalModal(props: IProps) {
  const [selectItemId, setSelectItemId] = useState<number>(0);

  const params = useParams();

  const handleMenuItemSelect = (selectedItemId: number) => {
    setSelectItemId(selectedItemId);
  };

  const { ideaProfile } = useIdeaHolderState();

  const ideaholderId = ideaProfile?.id;

  const sendProposal = async () => {
    const res = await createProposal({
      ideaholder_id: ideaholderId ?? 0,
      investor_id: params?.id ?? 0,
      idea_id: selectItemId ?? 0,
    });
    if (res.status === 201 || res.status === 200) {
      props?.onConfirm();
      toast("Proposal sent successfully");
      props.handleClose();
    }
  };

  return (
    <ModalComponent
      {...props}
      onConfirm={sendProposal}
      title="Send Proposal"
      subTitle="Are you sure you want to send proposal to the investor?"
      onMenuItemSelect={handleMenuItemSelect}
    />
  );
}
