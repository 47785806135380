import { alpha, styled } from "@mui/system";
import { PRIMARY_COLOR } from "constants/color-constants";

const Button = styled("div")({
  padding: "7px 20px",
  textTransform: "capitalize",
  fontSize: "calc(11px + (12 - 11) * ((100vw - 320px) / (1920 - 320)))",
  fontWeight: "bolder",
  borderRadius: "5px",
  minWidth: "65px",
  transition: "all 0.5s ease",
  cursor: "pointer",

  "&.outlined": {
    border: "1px solid #f1f1f1",
    backgroundColor: "#ffff",
    color: "gray",
  },
  "&.outlined:hover": {
    backgroundColor: "#eff6fc",
    color: PRIMARY_COLOR,
    border: "1px solid #eff6fc",
    boxShadow: `0px 9px 30px 0px ${alpha(PRIMARY_COLOR, 0.22)}`,
  },
  "&.filled": {
    backgroundColor: "#eff6fc",
    color: PRIMARY_COLOR,
    border: "1px solid #eff6fc",
  },
  "&.filled:hover": {
    border: "1px solid #f1f1f1",
    backgroundColor: "#ffff",
    color: "gray",
    boxShadow: `0px 9px 30px 0px ${alpha(PRIMARY_COLOR, 0.22)}`,
  },
});

const ButtonWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
});

export default function CustomButton2({
  text,
  type,
  onClick,
  icon,
}: {
  text: string;
  type: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}) {
  return (
    <Button className={`${type}`} onClick={onClick}>
      <ButtonWrapper>
        {icon}
        {text}
      </ButtonWrapper>
    </Button>
  );
}
