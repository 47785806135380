import { Global } from "@emotion/react";
import { Skeleton } from "@mui/lab";
import { Box, Divider } from "@mui/material";

export default function IdeaPostSkeleton() {
  return (
    <div className="post-wrapper col-grid-box">
      <Global
        styles={{
          ".MuiSkeleton-root": {
            backgroundColor: "rgb(34 154 184 / 15%) !important",
          },
        }}
      />
      <div className="idea-title">
        <div className="media">
          <h5 className="fixed-width-text">
            <Skeleton animation="wave" width="140px" height="25px" />
          </h5>
          <h6 className="light-color">
            <Skeleton animation="wave" width="80px" height="25px" />
          </h6>
        </div>
        <div className="follow-button-all">
          <Skeleton animation="wave" height="45px" width="87px" />
        </div>
      </div>

      <div className="post-details">
        <div className="detail-box">
          <p className="fixed-desc-box">
            <Skeleton animation="wave" width="180px" height="25px" />
          </p>
          <h5 className="tag">
            <Box display="flex" gap="10px">
              <Skeleton animation="wave" width="30px" height="25px" />
              <Skeleton animation="wave" width="35px" height="25px" />
              <Skeleton animation="wave" width="25px" height="25px" />
            </Box>
          </h5>
        </div>
        <div
          className="img-wrapper"
          style={{ height: "350px", overflow: "hidden" }}
        >
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height="100%"
          />
        </div>

        <Box className="likes-section">
          <Box className="people-likes flex-space-between flex-direction">
            <Box className="idea-location" title="Preferred Location">
              <Skeleton
                variant="circular"
                animation="wave"
                width="18px"
                height="18px"
              />
              <Skeleton animation="wave" width="50px" height="25px" />
            </Box>
            <h5 className="tag new-tag text-right flex-align-center">
              <Box display="flex" gap="10px">
                <Skeleton animation="wave" width="40px" height="25px" />
                <Skeleton animation="wave" width="120px" height="25px" />
              </Box>
            </h5>
          </Box>
        </Box>
        <Divider className="hrClass hrfeed" />

        <Box
          padding="0 35px 45px 35px"
          height="45px"
          display="flex"
          justifyContent="space-between"
        >
          <Skeleton animation="wave" height="45px" width="87px" />
          <Box display="flex" gap="15px">
            <Skeleton
              animation="wave"
              variant="circular"
              height="40px"
              width="40px"
            />
            <Skeleton
              animation="wave"
              variant="circular"
              height="40px"
              width="40px"
            />
            <Skeleton
              animation="wave"
              variant="circular"
              height="40px"
              width="40px"
            />
          </Box>
        </Box>
      </div>
    </div>
  );
}
