import ModalComponent from "components/ModalComponent";
import useLoginState from "hooks/useLoginState";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { RootState } from "redux/app/store";
import { createConnect } from "services/connectService";
import { getIdea } from "services/ideaService";

interface IProps {
  handleClose: () => void;
  open: boolean;
  onConfirm: () => void;
  type: string;
}

export default function ConnectModal(props: IProps) {
  const { userDetails } = useLoginState();

  const refresh = () => window.location.reload();

  const params = useParams<{
    id: string | undefined;
  }>();

  const investorId = useSelector(
    (state: RootState) => state?.investor?.data?.id
  );

  const [idea, setIdea] = useState<IBusinessTypeResponse | null>(null);

  const getIdeaDetails = async () => {
    if (params?.id) {
      const res = await getIdea(+params?.id);
      if (res?.status === 200 && res?.data) {
        const data: IBusinessTypeResponse = { ...res?.data };
        setIdea(data);
      }
    }
  };

  useEffect(() => {
    getIdeaDetails();
  }, [params]);

  const ideaholderId = idea?.ideaholder_id;

  const requestedById = userDetails?.id;

  const requestedToId = idea?.idea_holder?.user_id;

  const sendConnectRequest = async () => {
    if (
      typeof investorId === "undefined" ||
      typeof ideaholderId === "undefined" ||
      typeof requestedById === "undefined" ||
      typeof requestedToId === "undefined"
    ) {
      return;
    }

    const res = await createConnect({
      investor_id: investorId,
      ideaholder_id: ideaholderId,
      requested_by: requestedById,
      requested_to: requestedToId,
    });
    if (res.status === 201 || res.status === 200) {
      toast("Connect request sent successfully. Please wait until approval.");
      props.handleClose();
    }
  };

  return (
    <ModalComponent
      title="Connect Now!"
      subTitle={`Are you sure you want to connect with this business ${props.type}?`}
      {...props}
      onRefresh={refresh}
      onConfirm={sendConnectRequest}
    />
  );
}
