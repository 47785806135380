import { useState } from "react";
import { useLocation } from "react-router-dom";
import IdeaCreatePost from "./feed/IdeaCreatePost";
import IdeaFeed from "./feed/IdeaFeed";
import InvestorFeed from "./feed/InvestorFeed";
import SaleFeed from "./feed/SaleFeed";
import { Button, ButtonGroup } from "@mui/material";

export default function Feeds() {
  const loc = useLocation();
  const currentLoc = loc?.pathname?.split("/")[1];

  const [currentFeed, setCurrentFeed] = useState("idea");

  const handleIdeaFeedClick = () => {
    setCurrentFeed("idea");
  };

  const handleSaleFeedClick = () => {
    setCurrentFeed("sale");
  };

  return (
    <>
      {currentLoc === "idea" && (
        <>
          <div style={{ textAlign: "center" }}>
            <ButtonGroup
              fullWidth
              sx={{ margin: "0 0 20px 0" }}
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: currentFeed === "idea" ? "#0389c9" : "#fff",
                  color: currentFeed === "idea" ? "#fff" : "#0389c9",
                  "&.MuiButtonBase-root:hover": {
                    backgroundColor: "#219dd9 !important",
                    color: "#fff !important"
                  }
                }}
                onClick={handleIdeaFeedClick}
              >
                Idea
              </Button>
              {/* <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor:
                    currentFeed === "raise_investment" ? "#fff" : undefined,
                  color:
                    currentFeed === "raise_investment" ? "#000" : undefined,
                }}
                onClick={handleSaleFeedClick}
                disabled
              >
                Raise Investment
              </Button> */}
              <Button
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: currentFeed === "sale" ? "#0389c9" : "#fff",
                  color: currentFeed === "sale" ? "#fff" : "#0389c9",
                  "&.MuiButtonBase-root:hover": {
                    backgroundColor: "#219dd9 !important",
                    color: "#fff !important"
                  }
                }}
                onClick={handleSaleFeedClick}
              >
                Sale
              </Button>
            </ButtonGroup>
          </div>
          {currentFeed === "idea" && (
            <>
              <IdeaCreatePost />
              <IdeaFeed />
            </>
          )}
          {currentFeed === "sale" && (
            <>
              <IdeaCreatePost />
              <SaleFeed />
            </>
          )}
        </>
      )
      }
      {currentLoc === "investor" && <InvestorFeed />}
    </>
  );
}
