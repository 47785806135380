import { all } from "redux-saga/effects";
import accessRequestAllSaga from "redux/features/access-request/accessRequestAllSaga";
import analyticsIdeaDataSaga from "redux/features/analytics-idea/analyticsSaga";
import analyticsInvestorDataSaga from "redux/features/analytics-investor/analyticsInvestorSaga";
import connectAllSaga from "redux/features/connect/connectAllSaga";
import connectMySaga from "redux/features/connect/connectMySaga";
import ideaAllSaga from "redux/features/idea/ideaAllSaga";
import ideaSaga from "redux/features/idea/ideaSaga";
import ideaholderAllSaga from "redux/features/ideaholder/ideaholderAllSaga";
import ideaholderSaga from "redux/features/ideaholder/ideaholderSaga";
import investorAllSaga from "redux/features/investor/investorAllSaga";
import investorSaga from "redux/features/investor/investorSaga";
import proposalAllSaga from "redux/features/investor/proposal/proposalAllSaga";
import muniSaga from "redux/features/location/muniSaga";
import loginSaga from "redux/features/login/LoginSaga";
import userAllSaga from "redux/features/users/userAllSaga";
import usersSaga from "redux/features/users/userSaga";

export default function* rootSaga() {
  yield all([
    accessRequestAllSaga(),
    connectAllSaga(),
    connectMySaga(),
    ideaholderAllSaga(),
    ideaholderSaga(),
    investorAllSaga(),
    investorSaga(),
    ideaAllSaga(),
    ideaSaga(),
    loginSaga(),
    proposalAllSaga(),
    muniSaga(),
    userAllSaga(),
    usersSaga(),
    analyticsIdeaDataSaga(),
    analyticsInvestorDataSaga(),
  ]);
}
