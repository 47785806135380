import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button as ButtonBase, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";

const fontSize = "0.75em";

const Button = styled(ButtonBase)({
  fontSize,
  margin: "0 0 10px 0",
  width: "fit-content",
});

export default function GoBackButton({
  style,
  text,
}: {
  text: string;
  style?: React.CSSProperties;
}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Button style={style} onClick={goBack}>
      <ArrowBackIosIcon fontSize="small" /> {text}
    </Button>
  );
}
