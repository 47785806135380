import { ButtonBase, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/system";
import { ILandingData } from "pages/landing/LandingPage";
import { useState } from "react";
import { Link } from "react-router-dom";
import CreateProfileMessage from "./CreateProfileMessage";
import MessageModal from "./MessageModal";

const CardButton = styled(ButtonBase)({
  position: "relative",
  height: "30px",
  width: "100%",
  background: "rgba(255, 255, 255, 0.25)",
  display: "flex",
  alignItems: "center",
  borderRadius: "3px",
  minHeight: "30px",

  "&:hover": {
    background: "rgba(255, 255, 255, 0.5) !important",
  },
});

const ViewButton = styled(ButtonBase)({
  position: "relative",
  height: "30px",
  width: "100%",
  background: "rgba(255, 255, 255, 0.25)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "3px",
  minHeight: "30px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.5) !important",
  },
});
const CardButtonTitle = styled("span")({
  height: "100%",
  flexBasis: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const ViewButtonTitle = styled("span")({
  height: "100%",
  flexBasis: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const CardContentBox = styled("span")({
  display: "flex",
  flexDirection: "column",
});
const Cards = styled(Card)({
  flex: 1,
});
const CardsContent = styled(CardContent)({
  justifyContent: "space-between",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  "& span": {
    fontFamily: "Roboto",
  },
  "& h2, span": {
    color: "#fff",
  },
});
const ArrowButton = styled(Box)({
  height: "100%",
  flexBasis: "25%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingInline: "10px",
  borderLeft: "1px solid #e6e6e6",
  "& svg": {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#fff",
  },
});

export default function LandingCard({
  dl,
  showView,
}: {
  dl: ILandingData;
  showView: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [clickedBtn, setClickedBtn] = useState(0);

  const handleClick = (btn: number) => {
    setClickedBtn(btn);
    setOpen(true);
  };
  return (
    <Cards
      sx={{
        backgroundImage: dl.color,
        height: "100%",
      }}
      className="cards-main"
    >
      <CardsContent
        className="card-content"
        sx={{
          minHeight: "inherit",
        }}
      >
        <CardContentBox>
          {dl.isLoggedIn && dl.isMain ? (
            <>{dl?.afterAuth?.component}</>
          ) : (
            <>
              <Typography gutterBottom variant="h5" component="div">
                {dl.title}
              </Typography>
              <Typography gutterBottom variant="body2">
                {dl.desc}
              </Typography>
            </>
          )}
        </CardContentBox>
        <Box display="flex" gap={"5px"}>
          <Link
            to={dl?.isLoggedIn ? dl?.afterAuth?.onButton1Link ?? "" : ""}
            style={{
              minHeight: "inherit",
              width: "100%",
              flex: 1,
            }}
          >
            <CardButton
              onClick={() => {
                if (!dl?.isLoggedIn) {
                  if (!dl?.isMain) {
                    handleClick(1);
                    return;
                  }
                  dl?.beforeAuth?.onButton1Click();
                  return;
                }
                dl?.afterAuth?.onButton1Click?.();
              }}
            >
              <CardButtonTitle>
                {dl?.isLoggedIn
                  ? dl?.afterAuth?.button1Title
                  : dl?.beforeAuth?.button1Title}
              </CardButtonTitle>
            </CardButton>
          </Link>

          <Link
            to={dl?.isLoggedIn ? dl?.afterAuth?.onButton2Link ?? "" : ""}
            style={{
              minHeight: "inherit",
              width: "100%",
              flex: 1,
            }}
          >
            <ViewButton
              onClick={() => {
                if (!dl?.isLoggedIn) {
                  if (!dl?.isMain) {
                    handleClick(2);
                    return;
                  }
                  dl?.beforeAuth?.onButton2Click();
                  return;
                }
                dl?.afterAuth?.onButton2Click?.();
              }}
            >
              <ViewButtonTitle>
                {dl?.isLoggedIn
                  ? dl?.afterAuth?.button2Title
                  : dl?.beforeAuth?.button2Title}
              </ViewButtonTitle>
            </ViewButton>
          </Link>
        </Box>
      </CardsContent>
      <MessageModal open={open} handleClose={() => setOpen(!open)}>
        <CreateProfileMessage
          description={`Hey! Are you sure you have logged in? Make sure you are logged in to view the ${
            clickedBtn === 1 ? "details" : "feed"
          }.`}
          buttonTitle="Login"
          message="*Click here to go to login now."
          onClick={() =>
            clickedBtn === 1
              ? dl?.beforeAuth?.onButton1Click()
              : dl?.beforeAuth?.onButton2Click()
          }
        />
      </MessageModal>
    </Cards>
  );
}
