import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import * as React from "react";
import MeetingTable from "./MeetingTable";

const TabCover = styled("div")({
  height: "85vh",
  width: "100%",
});

export default function MeetingTab() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >
      <TabCover className="idea-form">
        <span className="tab-title">Meeting Scheduler</span>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Scheduler" value="1" />
              <Tab label="Scheduled Meetings" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <MeetingTable />
          </TabPanel>
          <TabPanel value="2">
            <MeetingTable />
          </TabPanel>
        </TabContext>
      </TabCover>
    </Box>
  );
}
