import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross1 } from "react-icons/rx";
import CreateProfileMessage from "./CreateProfileMessage";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
  "@media (max-width: 768px)": {
    width: 445,
  },
};

interface IProps {
  handleClose: () => void;
  open: boolean;
  children?: React.ReactNode;
  onConfirm?: () => void;
  path?: string;
}

export default function IdeaHolderModal({
  open,
  handleClose,
  onConfirm,
}: IProps) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} className="modal-cross">
            <RxCross1 />
          </IconButton>
          <CreateProfileMessage
            id="center-content"
            description="Hey! are you sure you have a business profile? Make sure you have a business profile to view the details."
            path="/idea/profile"
            buttonTitle=" + Create Business Profile"
            message="*Click here to create a business profile now."
          />

          {/* <Box className="modal-text">
            <h5>{title}</h5>
            {children && children}
            <h6>{subTitle}</h6>
            <Box className="flex-end">
              {onConfirm ? (
                <CustomButton
                  onClick={onConfirm}
                  text="Confirm"
                  type="filled"
                />
              ) : (
                <Link to={path ?? "#"}>
                  <CustomButton text="Confirm" type="filled" />
                </Link>
              )}

              <CustomButton
                onClick={handleClose}
                text="Cancel"
                type="outlined"
              />
            </Box>
          </Box> */}
        </Box>
      </Modal>
    </div>
  );
}
