import { Box, Typography } from "@mui/material";
import Warn from "assets/svg/warn.svg";
import CustomButton from "components/CustomButton";

export default function NotAllowed({
  description,
  message,
  onClick,
}: {
  description: string;
  message: string;
  onClick: () => void;
}) {
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="25px"
      padding={5}
    >
      <img
        src={Warn}
        alt="Warning"
        style={{
          height: "20vmax",
        }}
      />
      <Typography variant="body2" textAlign="center">
        {description}
      </Typography>
      <Box display="flex" flexDirection="column" gap="5px">
        <CustomButton onClick={onClick} text="Login as Admin" type="filled" />
        <Typography variant="caption" textAlign="center">
          <em>{message}</em>
        </Typography>
      </Box>
    </Box>
  );
}
