import { call, put, takeLatest } from "redux-saga/effects";
import { getMunicipality } from "services/locationService";
import muniSlice, { municipalityDataRequest } from "./muniSlice";

function* fetchSaga(
  action: ReturnType<typeof municipalityDataRequest>
): object {
  yield put(muniSlice.actions.get_municipality());

  try {
    const response = yield call(getMunicipality);
    yield put(muniSlice.actions.success_municipality(response.data));
  } catch (e) {
    yield put(muniSlice.actions.error_municipality(e as Error));
  }
}

function* muniSaga() {
  yield takeLatest(municipalityDataRequest.type, fetchSaga);
}

export default muniSaga;
