import ProfileIcon from "components/ProfileIcon";
import useLoginState from "hooks/useLoginState";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "redux/app/store";
import { analyticsIdeaData } from "redux/features/analytics-idea/analyticsSlice";
import { analyticsInvestorData } from "redux/features/analytics-investor/analyticsInvetorSlice";
import { fetchUser } from "redux/features/login/LoginSlice";

export default function ProfileBox() {
  const [ideaAnalytics, setIdeaAnalytics] = useState("");
  const { userDetails } = useLoginState();
  const dispatch = useDispatch();

  const loc = useLocation();
  const currentLoc = loc?.pathname?.split("/")[1];

  const refetchUser = () => {
    dispatch(fetchUser());
  };

  const fetchAnalytics = () => {
    dispatch(analyticsIdeaData());
  };
  const fetchInvestorAnalytics = () => {
    dispatch(analyticsInvestorData());
  };
  useEffect(() => {
    fetchAnalytics();
  }, [analyticsIdeaData]);

  useEffect(() => {
    fetchInvestorAnalytics();
  }, [analyticsInvestorData]);

  const analyticsIdeaRequest = useSelector(
    (state: RootState) => state?.analyticsIdea?.data
  );
  const analyticsInvestorRequest = useSelector(
    (state: RootState) => state?.analyticsInvestor?.data
  );

  return (
    <div className="profile-box">
      <div className="profile-setting"></div>
      <div className="profile-content">
        <div className="image-section">
          <div className="profile-img">
            <div className="bg-size">
              <ProfileIcon
                name={userDetails?.name ?? ""}
                profile={userDetails?.profile_pic}
                sx={{
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                }}
              />
            </div>
            {/* <span className="stats">
              <img
                src={VerifiedImg}
                className="img-fluid  lazyload"
                alt="verified"
              />
            </span> */}
          </div>
        </div>
        <div className="profile-detail">
          <Link to="profile.html">
            <h2>{userDetails?.name}</h2>
          </Link>
          <h5>{userDetails?.email}</h5>
          <div className="counter-stats">
            {currentLoc === "idea" && (
              <ul id="counter">
                <li>
                  <h3
                    className="counter-value"
                    data-count={analyticsIdeaRequest?.idea_count}
                  >
                    <CountUp
                      end={analyticsIdeaRequest?.idea_count ?? 0}
                      duration={3}
                    />
                  </h3>
                  <h5>Ideas</h5>
                </li>
                <li>
                  <h3
                    className="counter-value"
                    data-count={analyticsIdeaRequest?.access_request_count}
                  >
                    <CountUp
                      end={analyticsIdeaRequest?.access_request_count ?? 0}
                      duration={2}
                    />
                  </h3>
                  <h5>Access</h5>
                </li>
                <li>
                  <h3
                    className="counter-value"
                    data-count={analyticsIdeaRequest?.proposal_count}
                  >
                    <CountUp
                      end={analyticsIdeaRequest?.proposal_count ?? 0}
                      duration={2}
                    />
                  </h3>
                  <h5>Proposals</h5>
                </li>
              </ul>
            )}
            {currentLoc === "investor" && (
              <ul id="counter">
                <li>
                  <h3
                    className="counter-value"
                    data-count={analyticsInvestorRequest?.access_request_sent}
                  >
                    <CountUp
                      end={analyticsInvestorRequest?.access_request_sent ?? 0}
                      duration={3}
                    />
                  </h3>
                  <h5>Access</h5>
                </li>
                <li>
                  <h3
                    className="counter-value"
                    data-count={analyticsInvestorRequest?.proposal_received}
                  >
                    <CountUp
                      end={analyticsInvestorRequest?.proposal_received ?? 0}
                      duration={3}
                    />
                  </h3>
                  <h5>Proposals</h5>
                </li>
              </ul>
            )}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
