import { call, put, takeLatest } from "redux-saga/effects";
import { getInvestorAnalytics } from "services/InvestorService";
import analyticsSlice, { analyticsInvestorData } from "./analyticsInvetorSlice";

function* fetchSaga(action: ReturnType<typeof analyticsInvestorData>): object {
  yield put(analyticsSlice.actions.get_analytics_investor_data());

  try {
    const response = yield call(getInvestorAnalytics);
    yield put(
      analyticsSlice.actions.success_analytics_investor_data(response.data)
    );
  } catch (e) {
    yield put(analyticsSlice.actions.error_analytics_investor_data(e as Error));
  }
}

function* analyticsInvestorDataSaga() {
  yield takeLatest(analyticsInvestorData.type, fetchSaga);
}

export default analyticsInvestorDataSaga;
