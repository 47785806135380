import Idea from "pages/dashboard/idea/business/IdeaForm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "redux/app/store";
import { ideaDataRequest } from "redux/features/idea/ideaSlice";
import GoBackButton from "components/GoBackButton";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import Sale from "../business/BusinessSaleForm";
import { getIdea } from "services/ideaService";
import { BusinessType } from "../business/BusinessForm";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { CiGlobe, CiLock } from "react-icons/ci";
import { Box } from "react-feather";
import Loader from "components/Loader";

const BusinessTypes = [
  {
    label: "Idea" as BusinessType,
    value: "BusinessIdea" as BusinessType,
    icon: <CiLock />,
  },
  {
    label: "Sale" as BusinessType,
    value: "Sale" as BusinessType,
    icon: <CiGlobe />,
  },
];

export default function ViewIdea() {
  const dispatch = useDispatch();

  const params = useParams<{
    id: string;
  }>();

  useEffect(() => {
    if (params?.id) dispatch(ideaDataRequest({ id: +params?.id }));
  }, [dispatch, params]);

  const [select, setSelect] = useState<BusinessType>("BusinessIdea");
  const [loading, setLoading] = useState(false);
  const [screeningData, setScreeningData] =
    useState<IBusinessTypeResponse | null>(null);

  const getIdeaDetails = async () => {
    if (params?.id) {
      setLoading(true);
      const res = await getIdea(+params?.id);
      if (res?.status === 200 && res?.data) {
        const data: IBusinessTypeResponse = { ...res?.data };
        setSelect(data?.idea_type);
        setScreeningData(data);
        setLoading(false);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as BusinessType);
  };

  useEffect(() => {
    getIdeaDetails();
  }, [params]);

  if (loading) {
    return <Loader />;
  }

  // const screeningData = useSelector((state: RootState) => state?.idea?.data);

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="title-main flex-space-between m-top-0 m-bottom-0">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <GoBackButton text="Go Back" />
            <span>Idea Details</span>
          </div>
        </div>

        <div>
          <FormControl
            sx={{
              margin: "25px 0 15px 0",
              minWidth: "50%",
              paddingRight: "8px",
            }}
          >
            <InputLabel id="select-investor-type-label">
              Business Proposition
            </InputLabel>
            <Select
              labelId="select-investor-type-label"
              id="select-investor-type"
              value={select}
              label="Business Proposition"
              onChange={handleChange}
              style={{ width: "100%" }}
              sx={{
                "& .MuiSelect-select": {
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                },
              }}
              disabled={!!screeningData}
            >
              {BusinessTypes.map((el) => (
                <MenuItem
                  value={el.value}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box display="flex">{el?.icon}</Box>
                  <span className="dropdown-text">{el?.label}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {screeningData &&
          (screeningData.idea_type === "BusinessIdea" ? (
            <Idea
              data={screeningData as IBusinessTypeResponse}
              isReadOnly={true}
              style1={{ width: "auto", padding: "10px 0px" }}
              style2={{ display: "none" }}
              onSubmit={() => null}
            />
          ) : (
            screeningData.idea_type === "Sale" && (
              <Sale
                data={screeningData as IBusinessTypeResponse}
                isReadOnly={true}
                style1={{ width: "auto", padding: "10px 0px" }}
                style2={{ display: "none" }}
                style3={{ marginTop: "16px" }}
                onSubmit={() => null}
              />
            )
          ))}
      </div>
    </div>
  );
}
