import { Box } from "@mui/material";
import Typography from "@mui/material/Typography/Typography";
import useLoginState from "hooks/useLoginState";
import ProfileIcon from "./ProfileIcon";

export default function ProfileContent() {
  const { userDetails } = useLoginState();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Box width="50px" height="50px">
        <ProfileIcon
          name={userDetails?.name ?? null}
          profile={userDetails?.profile_pic}
          sx={{
            borderRadius: "50%",
            backgroundColor: "#fff",
          }}
        />
      </Box>
      <Box
        marginTop="10px"
        className="flex-center"
        style={{ flexDirection: "column" }}
      >
        <Typography
          variant="body1"
          sx={{ fontWeight: "medium" }}
          component="div"
        >
          {userDetails?.name}
        </Typography>
        <Typography variant="body2">{userDetails?.email}</Typography>
      </Box>
    </Box>
  );
}
