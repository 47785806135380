import useLoginState from "hooks/useLoginState";
import React, { Suspense } from "react";
import Loader from "../components/Loader";

const AuthGuardComponent = ({ component }: { component: React.ReactNode }) => {
  const { isLoggedIn } = useLoginState();

  if (isLoggedIn) {
    return <Suspense fallback={<Loader />}>{component}</Suspense>;
  }

  return <div>Not found</div>;
};

export default AuthGuardComponent;
