import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { GenericState } from "redux/app/genericState";

interface IdeaState extends GenericState<IBusinessTypeResponse[]> {}

const initialState: IdeaState = {
  data: [],
  error: undefined,
  status: "none",
};

export const ideaAllDataRequest = createAction<{
  status: "pending" | "resubmit" | "accepted" | "rejected" | "re-review" | null;
  active?: "true" | "false";
}>("IDEA_ALL_DATA_REQUEST");

export const ideaMyDataRequest = createAction("IDEA_MY_DATA_REQUEST");

const ideaAllSlice = createSlice({
  name: "idea",
  initialState,
  reducers: {
    get_idea_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_idea_all_data: (
      state,
      action: PayloadAction<IBusinessTypeResponse[]>
    ) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_idea_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default ideaAllSlice;
