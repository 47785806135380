const InvestorFeedData = [
  {
    id: 1,
    title: "Food And Beverage Manager",
    slug: "food-and-beverage-manager",
    type: "Institutional Investor",
    details:
      "Ideal asset acquired or invested in to build wealth and save money from the hard earned income or appreciation.Investment meaning is primarily.",
    interests: "Investments, Business Aanalysis, Book Reading, Home Decor",
    sectors: "Investments, Business Aanalysis",
    location: "Kathmandu Nepal",
    size: 230000,
    likes: 23,
    favourite: 45,
    views: 2600000,
    isFollowed: true,
  },
  {
    id: 2,
    title: "House Keeping and Local",
    slug: "house-keeping-and-local",
    type: "Individual Investor",
    details:
      "Investment definition is an asset acquired or invested in to build wealth and save money from the hard earned income or appreciation.Investment meaning is primarily.",
    interests: "Investments, Business Aanalysis, Book Reading, Home Decor",
    sectors: "Investments, Business Aanalysis",
    location: "Kathmandu Nepal",
    size: 3000000,
    likes: 6789,
    favourite: 456,
    views: 9700000,
    isFollowed: false,
  },
  {
    id: 3,
    title: "Hospital and Medicines",
    slug: "hospital-and-medicines",
    type: "Institutional Investor",
    details:
      "Hospital is an asset acquired or invested in to build wealth and save money from the hard earned income or appreciation.Investment meaning is primarily.",
    interests: "Investments, Business Aanalysis, Book Reading, Home Decor",
    sectors: "Investments, Business Aanalysis",
    location: "Kathmandu Nepal",
    size: 12479999000,
    likes: 20000,
    favourite: 450000,
    views: 1260000,
    isFollowed: false,
  },
];
export default InvestorFeedData;
