import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from "constants/api-constants";
import { authInterceptor } from "./authInterceptor";

const httpClient: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

httpClient.interceptors.request.use(
  authInterceptor("syi:access-token"),
  (er) => {
    return Promise.reject(er);
  }
);

export default httpClient;
