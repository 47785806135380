import CreateProfileMessage from "components/CreateProfileMessage";
import UserDetailCard from "components/InvestorDetailCard";
import Loader from "components/Loader";
import MoreUserDetails from "components/MoreInvestorDetails";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

export default function InvestorDetail() {
  const investor = useSelector((state: RootState) => state?.investor);

  if (investor?.status === "loading") {
    return <Loader />;
  }

  if (!investor?.data) {
    return (
      <CreateProfileMessage
        description="Hey! are you sure you have a investor profile? Make sure you have a investor profile to view the details."
        path="/investor/profile"
        buttonTitle="Create Investor Profile"
        message="*Click here to create a investor profile now."
      />
    );
  }

  return (
    <>
      <div
        className="content-left col-4 res-full-width order-1 new-content-left"
        style={{ width: "fit-content" }}
      >
        <div
          className="profile-about sticky-top"
          style={{ position: "sticky", top: "80px" }}
        >
          <UserDetailCard details={investor?.data} />
          <div className="about-footer">
            <ul>
              <li className="fb">
                <a href="https://www.facebook.com">
                  <Icon.Facebook />
                </a>
              </li>
              <li className="tw">
                <a href="https://www.twitter.com">
                  <Icon.Twitter />
                </a>
              </li>
              <li className="wa">
                <a href="https://www.linkedin.com">
                  <Icon.Linkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="content-center col-xl-8"
        style={{ width: "calc(100% - 380px)" }}
      >
        <MoreUserDetails data={investor?.data} />
      </div>
    </>
  );
}
