import Box from "@mui/material/Box/Box";
import { API_BASE_URL } from "constants/api-constants";
import { IIdeaholderTypeResponse } from "models/Ideaholder";
import { genderLabel } from "pages/main/componnets/contents/center-content/components/profile/investor/IndividualInvestor";
import { useMemo } from "react";

import {
  CiAt,
  CiBoxes,
  CiCircleCheck,
  CiHashtag,
  CiMobile3,
  CiSquareCheck,
} from "react-icons/ci";
import ProfileIcon from "./ProfileIcon";

export default function IdeaDetailCard({
  details,
}: {
  details?: IIdeaholderTypeResponse | null;
}) {
  let data: IIdeaholderTypeResponse | null | undefined;

  data = details;

  const profile = useMemo(() => {
    const pic = data?.files?.find((dl) => {
      return dl?.filename.split("-")[0] === "profile_pic";
    });
    return pic;
  }, [data]);

  return (
    <>
      <div className="card-title">
        <h3>Business Profile</h3>
        {/* <div className="settings">
          <div className="setting-btn">
            <IconButton>
              <Icon.Edit2 className="icon icon-dark stroke-width-3 iw-11 ih-11" />
            </IconButton>
          </div>
        </div> */}
      </div>
      <div className="about-content">
        <ul>
          <li>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box width="80px" height="80px">
                <ProfileIcon
                  name={profile?.filename ?? ""}
                  profile={`${API_BASE_URL}/${profile?.path}`}
                  sx={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
          </li>
          {data?.connect_status === "Pending" || data?.connect_status === "" ? (
            <div style={{ marginTop: "20px" }}>
              <span>
                Contact Detail will be available once you are connected with
                this user.
              </span>
            </div>
          ) : data?.connect_status === "Rejected" ? (
            <div style={{ marginTop: "20px" }}>
              <span>Your connection request has been rejected.</span>
            </div>
          ) : (
            <li>
              <div className="icon">
                <CiCircleCheck />
              </div>
              <div className="details">
                <h5>Full Name</h5>
                <h6 className="fixed-width-text">
                  {data?.full_name ?? <em>Not specified</em>}
                </h6>
              </div>
            </li>
          )}

          {data?.connect_status === "Pending" ||
          data?.connect_status === "Rejected" ||
          data?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiAt />
              </div>
              <div className="details">
                <h5>Email</h5>
                <h6 style={{ textTransform: "none" }}>
                  {data?.email ?? <em>Not specified</em>}
                </h6>
              </div>
            </li>
          )}

          {data?.connect_status === "Pending" ||
          data?.connect_status === "Rejected" ||
          data?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiMobile3 />
              </div>
              <div className="details">
                <h5>Phone</h5>
                <h6>{data?.phone}</h6>
              </div>
            </li>
          )}

          {data?.connect_status === "Pending" ||
          data?.connect_status === "Rejected" ||
          data?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiBoxes />
              </div>
              <div className="details">
                <h5>Age</h5>
                <h6>{data?.age ?? <em>Not specified</em>}</h6>
              </div>
            </li>
          )}

          {data?.connect_status === "Pending" ||
          data?.connect_status === "Rejected" ||
          data?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiHashtag />
              </div>
              <div className="details">
                <h5>Gender</h5>
                <h6>
                  {genderLabel?.find(
                    (el) => el?.value === data?.gender?.toLowerCase()
                  )?.label ?? ""}
                </h6>
              </div>
            </li>
          )}

          {data?.connect_status === "Pending" ||
          data?.connect_status === "Rejected" ||
          data?.connect_status === "" ? (
            <></>
          ) : (
            <li>
              <div className="icon">
                <CiSquareCheck />
              </div>
              <div className="details">
                <h5>Citizenship no.</h5>
                <h6>{data?.citizenship_no ?? <em>Not specified</em>}</h6>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
