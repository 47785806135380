import { call, put, takeLatest } from "redux-saga/effects";
import { getIdea } from "../../../services/ideaService";
import ideaSlice, { ideaDataRequest } from "./ideaSlice";

function* fetchSaga(action: ReturnType<typeof ideaDataRequest>): object {
  yield put(ideaSlice.actions.get_idea_data());

  try {
    const response = yield call(getIdea, action.payload.id);
    yield put(ideaSlice.actions.success_idea_data(response.data));
  } catch (e) {
    yield put(ideaSlice.actions.error_idea_data(e as Error));
  }
}

function* ideaSaga() {
  yield takeLatest(ideaDataRequest.type, fetchSaga);
}

export default ideaSaga;
