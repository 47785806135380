import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IIdeaType } from "models/Idea";
import { IIdeaholderType } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { GenericState } from "redux/app/genericState";

type IDataType = {
  investor: IInvestorType;
  idea: IIdeaType;
  ideaholder: IIdeaholderType;
  created_at: string;
  deleted_at: string;
  id: number;
  status: string;
  updated_at: string;
  ideaholder_id: number;
  idea_id: number;
  investor_id: number;
};

interface InvestorState extends GenericState<IDataType[]> {}

const initialState: InvestorState = {
  data: [],
  error: undefined,
  status: "none",
};

export const proposalAllDataRequest = createAction<{
  status: "accepted" | "rejected" | "pending" | null;
}>("PROPOSAL_ALL_DATA_REQUEST");

const proposalAllSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    get_proposal_all_data: (state) => {
      state.status = "loading";
      state.data = [];
      state.error = undefined;
    },
    success_proposal_all_data: (state, action: PayloadAction<IDataType[]>) => {
      state.status = "loaded";
      state.data = action.payload;
      state.error = undefined;
    },
    error_proposal_all_data: (state, action: PayloadAction<Error>) => {
      state.status = "error";
      state.error = action.payload;
    },
  },
});

export default proposalAllSlice;
