import { call, put, takeLatest } from "redux-saga/effects";
import { getProposalRequestAll } from "services/InvestorService";
import proposalSlice, { proposalAllDataRequest } from "./proposalAllSlice";

function* fetchSaga(action: ReturnType<typeof proposalAllDataRequest>): object {
  yield put(proposalSlice.actions.get_proposal_all_data());

  try {
    const response = yield call(getProposalRequestAll, action.payload.status);
    yield put(proposalSlice.actions.success_proposal_all_data(response.data));
  } catch (e) {
    yield put(proposalSlice.actions.error_proposal_all_data(e as Error));
  }
}

function* proposalAllSaga() {
  yield takeLatest(proposalAllDataRequest.type, fetchSaga);
}

export default proposalAllSaga;
