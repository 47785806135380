import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import useIdeaHolderState from "hooks/useIdeaHolderState";
import { IAccessRequestStatusType } from "pages/dashboard/admin/access-request/AccessRequestTabs";
import * as React from "react";
import { toast } from "react-toastify";
// import { ideaAccessIdeaholderDataRequest } from "redux/features/access-request/accessRequestAllSlice";
import AccessRequestTable from "./IdeaholderAccessRequestTable";
import { getIdeaholderAccessRequests } from "services/accessRequestService";
import { IDataType } from "redux/features/connect/connectAllSlice";
import IdeaSaleFilter from "components/IdeaSaleFilter";

const TabCover = styled("div")({
  height: "100%",
  width: "100%",
});

export default function IdeaholderAccessRequestTabs() {
  const [value, setValue] = React.useState<IAccessRequestStatusType>("all");
  const [isFetched, setIsFetched] = React.useState(false);
  const [display, setDisplay] = React.useState([]);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: IAccessRequestStatusType
  ) => {
    setIsFetched(false);
    setValue(newValue);
  };

  const { ideaProfile } = useIdeaHolderState();

  // const dispatch = useDispatch();

  const getAccessRequestService = async () => {
    if (ideaProfile?.id) {
      const res = await getIdeaholderAccessRequests(
        ideaProfile?.id,
        value === "all" ? null : value
      );
      if (res?.status === 200 || res.status === 201) {
        setDisplay(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  };

  const [allData, setAllData] = React.useState([]);

  const getAllData = async () => {
    if (ideaProfile?.id) {
      const res = await getIdeaholderAccessRequests(ideaProfile?.id, null)
      if (res?.status === 200 || res.status === 201) {
        setAllData(res?.data);
      } else {
        toast("Something went wrong");
      }
    }
  }

  React.useEffect(() => {
    getAllData();
  }, [ideaProfile?.id, value]);

  const acceptedCount = allData.filter((data: IDataType) => data.status === "Accepted").length
  const rejectedCount = allData.filter((data: IDataType) => data.status === "Rejected").length
  const pendingCount = allData.filter((data: IDataType) => data.status === "Pending").length

  React.useEffect(() => {
    getAccessRequestService();
  }, [ideaProfile?.id, value]);

  const [businessType, setBusinessType] = React.useState<string>();

  const handleTypeChange = (state: string) => {
    setBusinessType(state);
  }

  // React.useEffect(() => {
  //   if (ideaProfile?.id)
  //     dispatch(
  //       ideaAccessIdeaholderDataRequest({
  //         id: ideaProfile?.id,
  //         status: value === "all" ? null : value,
  //       })
  //     );
  // }, [dispatch, ideaProfile?.id, isFetched, value]);

  return (
    <Box
      sx={{ width: "100%", typography: "body1" }}
      className="content-center"
      id="center-content"
    >

      <TabCover className="idea-form">
        <span className="tab-title">Ideas Access Request</span>
        <TabContext value={value}>
          <Box display='flex' justifyContent='space-between'>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"All" + ` (${allData.length})`}
                  value="all"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Pending" + ` (${pendingCount})`}
                  value="pending"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Accepted" + ` (${acceptedCount})`}
                  value="accepted"
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label={"Rejected" + ` (${rejectedCount})`}
                  value="rejected"
                />
              </TabList>
            </Box>
            <Box mt="30px">
              <IdeaSaleFilter />
            </Box>
          </Box>
          <Box mt="-16px">
            <TabPanel value="all">
              <AccessRequestTable
                getAccessRequestService={getAccessRequestService}
                data={display}
              />
            </TabPanel>
            <TabPanel value="pending">
              <AccessRequestTable
                getAccessRequestService={getAccessRequestService}
                data={display}
              />
            </TabPanel>
            <TabPanel value="accepted">
              <AccessRequestTable
                getAccessRequestService={getAccessRequestService}
                data={display}
              />
            </TabPanel>
            <TabPanel value="rejected">
              <AccessRequestTable
                getAccessRequestService={getAccessRequestService}
                data={display}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </TabCover>
    </Box>
  );
}
