import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "redux/app/store";
import currencyFormatter from "utils/currencyFormatter";

const columns: GridColumns = [
  { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
  { field: "full_name", headerName: "Investor Name", flex: 1, minWidth: 200 },
  { field: "title_designation", headerName: "Title", flex: 1, minWidth: 200 },
  {
    field: "Investment Amount",
    headerName: "Investment",
    flex: 1,
    minWidth: 300,
    renderCell: (params) => {
      return (
        <Box>
          {currencyFormatter(params?.row?.min_amount ?? 0)} -{" "}
          {currencyFormatter(params?.row?.max_amount ?? 0)}
        </Box>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Date",
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Chip
            label={params?.row?.status}
            variant="filled"
            sx={{
              color: "#fff",
              bgcolor:
                STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
            }}
          />
        </Box>
      );
    },
  },
  {
    field: "Action",
    headerName: "Action",
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Link to={`/admin/investor-screening/detail/${params?.row?.id}`}>
            <IconButton size="small">
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          </Link>
        </Box>
      );
    },
  },
];

export default function InvestorScreeningTable() {
  const investors = useSelector((state: RootState) => state?.investorAll);
  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={investors?.data?.map((el, index) => {
          return {
            ...el,
            sn: index + 1,
          };
        })}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        // onRowClick={handleClick}
        // checkboxSelection
      />
    </div>
  );
}
