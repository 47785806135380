import CreateProfileMessage from "components/CreateProfileMessage";
import IdeaDetailCard from "components/IdeaDetailCard";
import Loader from "components/Loader";
import MoreIdeaDetailCard from "components/MoreIdeaDetailCard";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

export default function IdeaDetail() {
  const ideaholder = useSelector((state: RootState) => state?.ideaholder);

  if (ideaholder?.status === "loading") {
    return <Loader />;
  }

  if (!ideaholder?.data) {
    return (
      <CreateProfileMessage
        description="Hey! Are you sure you have a ideaholder profile? Make sure you have a business profile to view the details."
        path="/idea/profile"
        buttonTitle="Create Business Profile"
        message="*Click here to create a ideaholder profile now."
      />
    );
  }

  return (
    <>
      <div
        className="content-left col-4 res-full-width order-1"
        style={{ width: "fit-content" }}
      >
        <div
          className="profile-about sticky-top"
          style={{ position: "sticky", top: "80px" }}
        >
          <IdeaDetailCard details={ideaholder?.data} />

          <div className="about-footer">
            <ul>
              <li className="fb">
                <a href="https://www.facebook.com">
                  <Icon.Facebook />
                </a>
              </li>
              <li className="tw">
                <a href="https://www.twitter.com">
                  <Icon.Twitter />
                </a>
              </li>
              <li className="wa">
                <a href="https://www.linkedin.com">
                  <Icon.Linkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        className="content-center col-xl-8"
        style={{ width: "calc(100% - 380px)" }}
      >
        <MoreIdeaDetailCard data={ideaholder?.data} />
      </div>
    </>
  );
}
