import Empty from "assets/svg/empty.svg";

export default function EmptyTable() {
  return (
    <div className="flex-direction-center">
      <img src={Empty} alt="No Data" height="100vh" />
      <h5 className="italic-bold">There are no records to display.</h5>
    </div>
  );
}
