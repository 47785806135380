import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Chip, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridOverlay } from "@mui/x-data-grid";
import EmptyTable from "components/EmptyTable";
import { STATUS_COLOR } from "constants/color-constants";
import { IIdeaholderType } from "models/Ideaholder";
import moment from "moment";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteProposal } from "services/InvestorService";

type IDataType = {
  investor: IInvestorType;
  idea_holder: IIdeaholderType;
  created_at: string;
  deleted_at: string;
  id: number;
  status: string;
  updated_at: string;
  ideaholder_id: number | undefined;
  investor_id: number;
};

export default function ProposalsTable({ data }: { data: IDataType[] }) {
  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <EmptyTable />
      </GridOverlay>
    );
  }
  const deleteProposalsService = async (id: number) => {
    const res = await deleteProposal(id);
    if (res.status === 200) {
      toast("Deleted successfully");
    } else {
      toast("Oops! Error occured.", {
        type: "error",
      });
    }
  };
  const columns: GridColDef<IDataType>[] = [
    { field: "sn", headerName: "SN", flex: 1, maxWidth: 60 },
    {
      field: "Investor",
      headerName: "Investor Name",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const { investor } = params?.row;
        return <>{investor?.full_name}</>;
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            <Chip
              label={params?.row?.status}
              variant="filled"
              sx={{
                color: "#fff",
                bgcolor:
                  STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box className="flex-g-5 svg-size">
            {/* <IconButton
              size="small"
              onClick={() => deleteProposalsService(params?.row?.id)}
            >
              <AiOutlineDelete />
            </IconButton> */}

            <Link to={`/idea/investor/details/${params?.row?.investor_id}`}>
              <IconButton size="small">
                <RemoveRedEyeOutlinedIcon />
              </IconButton>
            </Link>
          </Box>
        );
      },
    },
  ];
  return (
    <div style={{ height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
            {
              display: "none",
            },
        }}
        rows={data?.map((el, index) => {
          return {
            ...el,
            sn: index + 1,
          };
        })}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        components={{ NoRowsOverlay: CustomNoRowsOverlay }}
      />
    </div>
  );
}
