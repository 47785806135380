import UserDetailCard from "components/InvestorDetailCard";
import Loader from "components/Loader";
import MoreUserDetails from "components/MoreInvestorDetails";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useParams } from "react-router-dom";
import { getInvestor } from "services/InvestorService";
import MuniData from "services/json/muni.json";
import StreetData from "services/json/streets.json";

export default function ViewInvestorDetail() {
  const [loading, setLoading] = useState(false);
  const [screeningData, setScreeningData] = useState<IInvestorType | null>(
    null
  );

  const params = useParams<{
    id: string | undefined;
  }>();

  const getInvestorDetails = async () => {
    if (params?.id) {
      setLoading(true);
      const res = await getInvestor(+params?.id);
      if (res?.status === 200 && res?.data) {
        const investor: IInvestorType = {
          ...res?.data,
          permanent_location_municipality: {
            id: null,
            name: res.data?.permanent_location_municipality,
          },
          permanent_location_street: {
            id: null,
            street_name: res.data?.permanent_location_street,
          },
          permanent_location_area: {
            id: null,
            area_name: res.data?.permanent_location_area,
          },
          temporary_location_municipality: {
            id: null,
            name: res.data?.temporary_location_municipality,
          },
          temporary_location_street: {
            id: null,
            street_name: res.data?.temporary_location_street,
          },
          temporary_location_area: {
            id: null,
            area_name: res.data?.temporary_location_area,
          },
        };
        setScreeningData(investor);
        setLoading(false);
      }
    }
  };

  const eligibleConnect = screeningData?.proposal !== undefined && screeningData?.proposal.filter((data: { status: string }) => data.status === "Accepted").length > 0 && screeningData.connect_status === "" ? true : false;

  useEffect(() => {
    getInvestorDetails();
  }, [params]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="content-left col-4 res-full-width order-1"
        style={{ width: "fit-content", maxWidth: "380px" }}
      >
        <div
          className="profile-about sticky-top"
          style={{ position: "sticky", top: "80px" }}
        >
          <UserDetailCard details={screeningData} eligibleConnect={eligibleConnect} />
          {/* <div className="about-footer">
            <ul>
              <li className="fb">
                <a href="https://www.facebook.com">
                  <Icon.Facebook />
                </a>
              </li>
              <li className="tw">
                <a href="https://www.twitter.com">
                  <Icon.Twitter />
                </a>
              </li>
              <li className="wa">
                <a href="https://www.linkedin.com">
                  <Icon.Linkedin />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div
        className="content-center col-xl-8"
        style={{ width: "calc(100% - 380px)" }}
      >
        <MoreUserDetails data={screeningData} hideStatus={true} hideDeactivate={true} />
      </div>
    </>
  );
}
