import { Box, Typography } from "@mui/material";
import CreateProfile from "assets/svg/create-profile.svg";
import { Link } from "react-router-dom";
import CustomButton from "./CustomButton";

export default function CreateProfileMessage({
  description,
  message,
  path,
  buttonTitle,
  id,
  onClick,
}: {
  description: string;
  message: string;
  path?: string;
  buttonTitle: string;
  id?: string;
  onClick?: () => void;
}) {
  return (
    <Box
      id={id ?? "center-content"}
      className="page-list"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="25px"
      padding={5}
      sx={{
        width: "calc(100% - 380px)",
        "@media all and (max-width:1200px)": {
          width: "100%",
          minHeight: "400px",
        },
      }}
    >
      <img
        src={CreateProfile}
        style={{
          height: "15vmax",
        }}
        alt="create profile"
      />
      <Typography variant="body2" textAlign="center">
        {description}
      </Typography>
      <Box display="flex" flexDirection="column" gap="5px">
        <Link
          to={path ?? "#"}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CustomButton
            onClick={onClick}
            text={buttonTitle ?? "+ Create profile"}
            type="filled"
          />
        </Link>
        <Typography variant="caption" textAlign="center">
          <em>{message}</em>
        </Typography>
      </Box>
    </Box>
  );
}
