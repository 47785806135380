import Loader from "components/Loader";
import { CLIENT_ID, IDENTITY_SERVER_URL } from "constants/api-constants";
import { useEffect } from "react";

export default function RedirectToLogin() {
  function redirectToIdentityServer() {
    const redirectUrl =
      IDENTITY_SERVER_URL +
      "?callback_url=" +
      process.env.REACT_APP_CALLBACK_URL +
      "&client_id=" +
      CLIENT_ID;

    window.location.href = redirectUrl;
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      redirectToIdentityServer();
      clearTimeout(timeout);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return <Loader />;
}
