import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import { IIdeaholderType } from "models/Ideaholder";
import { IInvestorType } from "pages/main/componnets/contents/center-content/components/profile/investor/InvestorProfileForm";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { IMunicipality } from "redux/features/location/muniSlice";
import { getArea, getStreet } from "services/locationService";

type AnyUserRole = IInvestorType | IIdeaholderType;

export interface IArea {
  id: number | null;
  area_name: string;
  is_verified?: number;
  municipality_id?: number;
}

export interface IStreet {
  id: number | null;
  street_name: string;
  is_verified?: number;
  area_id?: number;
  municipality_id?: number;
}

export type ViewFor = "create" | "update" | "view";

export default function AddressComponent<T extends AnyUserRole = AnyUserRole>({
  hookForm,
  isReadOnly,
  viewFor,
}: {
  hookForm: UseFormReturn<T>;
  isReadOnly: boolean;
  viewFor?: ViewFor;
}) {
  const { watch, setValue } = hookForm as unknown as UseFormReturn<AnyUserRole>;

  const watchedTMuni = watch("temporary_location_municipality");
  const watchedTArea = watch("temporary_location_area");
  const watchedTStreet = watch("temporary_location_street");
  const watchedPMuni = watch("permanent_location_municipality");
  const watchedPArea = watch("permanent_location_area");
  const watchedPStreet = watch("permanent_location_street");

  const handleLocation = () => {
    setValue("temporary_location_municipality", watchedPMuni ?? null);
    setValue("temporary_location_street", watchedPStreet ?? null);
    setValue("temporary_location_area", watchedPArea ?? null);
  };

  return (
    <FormProvider {...hookForm}>
      <div className="title-main">
        <span>Permanent Location</span>
      </div>
      <AreaInputSection
        isReadOnly={isReadOnly}
        muniField="permanent_location_municipality"
        streetField="permanent_location_street"
        areaField="permanent_location_area"
        watchMuni={watchedPMuni}
        watchArea={watchedPArea}
        watchStreet={watchedPStreet}
        isRequired={true}
      />

      <Box className="title-main" display="flex" alignItems="center" gap={2}>
        <span>Temporary Location</span>{" "}
        {viewFor === "create" && (
          <Button
            onClick={handleLocation}
            variant="outlined"
            size="small"
            sx={{ textTransform: "capitalize" }}
          >
            + Copy Permanent Address
          </Button>
        )}
      </Box>

      <AreaInputSection
        isReadOnly={isReadOnly}
        muniField="temporary_location_municipality"
        streetField="temporary_location_street"
        areaField="temporary_location_area"
        watchMuni={watchedTMuni}
        watchArea={watchedTArea}
        watchStreet={watchedTStreet}
        isRequired={false}
      />
    </FormProvider>
  );
}

const AreaInputSection = ({
  isReadOnly,
  muniField,
  areaField,
  streetField,
  watchMuni,
  watchArea,
  watchStreet,
  isRequired,
}: {
  isReadOnly: boolean;
  muniField: keyof AnyUserRole;
  areaField: keyof AnyUserRole;
  streetField: keyof AnyUserRole;
  watchMuni: IMunicipality | null;
  watchArea: IArea | null;
  watchStreet: IStreet | null;
  isRequired: boolean;
}) => {
  const municipalities = useSelector(
    (state: RootState) => state?.municipalities
  );

  const hookForm = useFormContext();

  const {
    control,
    formState: { errors },
  } = hookForm;

  const [areas, setAreas] = useState<IArea[] | null>(null);
  const [streets, setStreets] = useState<IStreet[] | null>(null);

  const fetchArea = async () => {
    const res = await getArea(watchMuni?.id ?? 1);
    if (res?.status === 200) {
      setAreas(res?.data);
    }
  };

  const fetchStreet = async () => {
    if (watchArea?.id) {
      const res = await getStreet(watchArea?.id ?? 1);
      if (res?.status === 200) {
        setStreets(res?.data);
      }
    }
  };

  useEffect(() => {
    if (watchMuni) {
      fetchArea();
    }
  }, [areaField, watchMuni]);

  useEffect(() => {
    if (watchArea?.id) {
      fetchStreet();
    }
  }, [watchArea]);

  return (
    <Box>
      <Grid container spacing={2} marginTop="-4px">
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name={muniField}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disablePortal
                readOnly={isReadOnly}
                options={
                  [...municipalities?.data]?.sort(
                    (a, b) =>
                      -b.name?.split("")[0].localeCompare(a.name?.split("")[0])
                  ) ?? []
                }
                onChange={(e, item) => {
                  onChange(item);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id || option?.name === value?.name
                }
                value={value}
                groupBy={(option) => option?.name?.split("")[0]}
                renderOption={(props, muni) => {
                  return (
                    <li {...props} key={muni?.id}>
                      {muni?.name}
                    </li>
                  );
                }}
                getOptionLabel={(muni) => muni?.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required={isRequired}
                    label="Municipality"
                    error={!!errors?.[muniField]}
                    helperText={errors?.[muniField]?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name={areaField}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                options={areas ?? []}
                disabled={!watchMuni}
                readOnly={isReadOnly}
                getOptionLabel={(el) => {
                  if (typeof el === "string") {
                    return el;
                  }
                  return el?.area_name ?? "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id || option?.name === value?.name
                }
                onChange={(e, item) => {
                  onChange(item as IArea);
                }}
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required={isRequired}
                    onChange={(e) => {
                      onChange({
                        id: null,
                        area_name: e?.target?.value,
                      });
                    }}
                    label="Area Name"
                    error={!!errors?.[areaField]}
                    helperText={errors?.[areaField]?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name={streetField}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                readOnly={isReadOnly}
                options={streets ?? []}
                getOptionLabel={(el) => {
                  if (typeof el === "string") {
                    return el;
                  }
                  return el?.street_name ?? "";
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id || option?.name === value?.name
                }
                onChange={(e, item) => {
                  onChange(item as IStreet);
                }}
                value={value}
                disabled={!watchArea}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required={isRequired}
                    onChange={(e) => {
                      onChange({
                        id: null,
                        street_name: e?.target?.value,
                      });
                    }}
                    label="Street Name"
                    error={!!errors?.[streetField]}
                    helperText={errors?.[streetField]?.message as string}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
