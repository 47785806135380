import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import GoBackButton from "components/GoBackButton";
import { CountryCodeType, defaultCode } from "hooks/usePhoneValidator";
import { IIdeaType } from "models/Idea";
import { ISaleType } from "models/Sale";
import { useEffect, useState } from "react";
import { CiGlobe, CiLock } from "react-icons/ci";
import { useNavigate, useParams } from "react-router-dom";
import { getIdea, updateIdea } from "services/ideaService";
import { countries } from "services/json/countries";
import { getDefaultCountryService } from "services/userService";
import Idea from "./IdeaForm";
import Sale from "./BusinessSaleForm";
import { AxiosError } from "axios";
import { IResponseMessageType } from "models/ResponseMessage";
import { toast } from "react-toastify";
import { IBusinessTypeResponse } from "models/IdeaResponse";
import { BusinessType, IBusinessType } from "./BusinessForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/app/store";
import { ideaDataRequest } from "redux/features/idea/ideaSlice";

type IStatus = "unknown" | "success" | "error";

const BusinessTypes = [
  {
    label: "Idea" as BusinessType,
    value: "BusinessIdea" as BusinessType,
    icon: <CiLock />,
  },
  {
    label: "Sale" as BusinessType,
    value: "Sale" as BusinessType,
    icon: <CiGlobe />,
  },
];

export default function UpdateBusinessForm() {
  // Phone
  const [defaultCountryCode, setDefaultCountryCode] =
    useState<CountryCodeType>(defaultCode);
  // End Phone

  const [sending, setSending] = useState(false);
  const [select, setSelect] = useState<BusinessType>("BusinessIdea");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const ideaData = useSelector((state: RootState) => state.idea.data);

  //   const [ideaData, setIdea] = useState<IBusinessTypeResponse | null>(null);
  //   const [loading, setLoading] = useState(false);

  const params = useParams<{
    id: string | undefined;
  }>();

  //   const getIdeaDetail = async () => {
  //     if (params?.id) {
  //       setLoading(true);
  //       const res = await getIdea(+params?.id);
  //       if (res?.data) {
  //         setIdea(res?.data);
  //       }
  //     }
  //   };

  //   useEffect(() => {
  //     getIdeaDetail();
  //   }, [params?.id]);

  const onSubmit = async (data: IBusinessType) => {
    let mainData: IBusinessType;

    const fd = new FormData();
    if (data?.business_type === "BusinessIdea") {
      mainData = data as IIdeaType;
      fd.append("idea_type", mainData?.business_type?.toLowerCase());
      fd.append("idea[idea_name]", mainData?.idea_name);
      fd.append("idea[idea_description]", mainData?.idea_description);
      fd.append(
        "idea[ideaholder_investment]",
        mainData?.ideaholder_investment?.toString()
      );
      fd.append(
        "idea[expected_investment]",
        mainData?.expected_investment?.toString()
      );
      fd.append("idea[location]", mainData?.location);
      fd.append("idea[preferred_location]", mainData?.preferred_location);
      fd.append("idea[investment_type]", mainData?.investment_type ?? "");
      data?.idea_category?.forEach((category) => {
        fd.append("idea[sector][]", category?.id?.toString());
      });
      mainData?.tags.forEach((el) => {
        fd.append("idea[tags][]", el?.toString());
      });

      fd.append("debt_injection_plan", mainData?.debt_injection_plan as Blob);
      fd.append("exit_plan", mainData?.exit_plan as Blob);
    }

    if (data?.business_type === "Sale") {
      mainData = data as ISaleType;
      fd.append("idea_type", mainData?.business_type?.toLowerCase());
      fd.append("idea[company_name]", mainData?.company_name);
      fd.append("idea[address]", mainData?.address);
      fd.append("idea[email]", mainData?.email);
      fd.append("idea[mobile_no]", mainData?.phone);
      fd.append("idea[postal_code]", mainData?.postal_code?.toString());
      fd.append("idea[type_of_business]", mainData?.type_of_business);
      fd.append("idea[valuation]", mainData?.valuation?.toString());
      fd.append("idea[annual_turnover]", mainData?.annual_turnover?.toString());
      fd.append("idea[business_description]", mainData?.business_description);
      fd.append("idea[selling_reason]", mainData?.selling_reason);
      fd.append("latest_share_lagat", mainData?.latest_share_lagat as Blob);
      fd.append("moa", mainData?.moa as Blob);
      fd.append("aoa", mainData?.aoa as Blob);
      fd.append("pan", mainData?.pan as Blob);
      fd.append("business_profile", mainData?.business_profile as Blob);
      fd.append("tax_clearance", mainData?.tax_clearance as Blob);
      fd.append("ocr_update", mainData?.ocr_update as Blob);
      fd.append("audit_report", mainData?.audit_report as Blob);
      fd.append("expected_valuation", mainData?.expected_valuation as Blob);
    }

    fd.append("cover_image_or_video", data?.cover_image_or_video as Blob);
    fd.append("feasibility_study", data?.feasibility_study as Blob);
    fd.append("swot_analysis", data?.swot_analysis as Blob);
    fd.append("financial_projection", data?.financial_projection as Blob);
    fd.append("financial_analysis", data?.financial_analysis as Blob);

    setSending(true);

    try {
      await updateIdea(params?.id, fd);
      toast("Business Proposition Updated Successfully!", {
        type: "success",
      });
      navigate("/idea");

      setSending(false);
    } catch (e) {
      const error = e as AxiosError<IResponseMessageType>;
      let message = error.response?.data?.message || "Some error";
      if (Array.isArray(message)) {
        message = message.join(", ");
      }
      setSending(false);
      toast(message, {
        type: "error",
      });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as BusinessType);
  };

  // Phone
  const getDefaultCountry = async () => {
    const res = await getDefaultCountryService();
    if (res?.status === 200) {
      const country = countries.find((x) => x.code === res?.data?.country_code);
      setDefaultCountryCode({
        code: country?.code ?? "",
        label: country?.label ?? "",
        phone: country?.phone ?? "",
      });
    }
  };

  useEffect(() => {
    if (params?.id) dispatch(ideaDataRequest({ id: +params?.id }));
  }, [dispatch, params]);

  useEffect(() => {
    if (ideaData?.idea_type === "BusinessIdea") {
      setSelect(ideaData?.idea_type);
    } else if (ideaData?.idea_type === "Sale") {
      setSelect(ideaData?.idea_type);
    }
  }, [ideaData]);

  useEffect(() => {
    getDefaultCountry();
  }, []);

  // End Phone

  return (
    <div className="content-center" id="center-content">
      <div className="overlay-bg"></div>
      <div className="idea-form">
        <div className="top-form">
          <GoBackButton text="Go Back" />
          <h3>Update business proposition</h3>
          <h6>Change details below to update your business proposition</h6>
        </div>
        <div>
          <Grid container marginTop="20px">
            <Grid xs={12} sm={12} md={6} marginBottom={1}>
              <FormControl fullWidth sx={{ paddingRight: "8px" }}>
                <InputLabel id="select-business-type-label">
                  Business Proposition
                </InputLabel>
                <Select
                  labelId="select-business-type-label"
                  id="select-business-type"
                  value={select}
                  label="Business Proposition"
                  onChange={handleChange}
                  sx={{
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    },
                  }}
                  disabled={!!ideaData}
                >
                  {BusinessTypes.map((el) => (
                    <MenuItem
                      key={el?.value}
                      value={el.value}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box component="span" display="flex">
                        {el?.icon}
                      </Box>
                      <span className="dropdown-text">{el?.label}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {ideaData?.idea_type === "BusinessIdea" && (
          <Idea
            data={ideaData as IBusinessTypeResponse}
            onSubmit={onSubmit}
            loading={sending}
            // viewFor="view"
          />
        )}
        {ideaData?.idea_type === "Sale" && (
          <Sale
            defaultCountryCode={defaultCountryCode}
            data={ideaData as IBusinessTypeResponse}
            onSubmit={onSubmit}
            loading={sending}
            style3={{ marginTop: "16px" }}
            // viewFor="view"
          />
        )}
      </div>
    </div>
  );
}
