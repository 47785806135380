import NotchedOutline from "@mui/material/OutlinedInput/NotchedOutline";
import { styled } from "@mui/material/styles";

const NotchedOutlineRoot = styled(NotchedOutline, {
  name: "MuiOutlinedInput",
  slot: "NotchedOutline",
  overridesResolver: (props, styles) => styles.notchedOutline,
})(({ theme }) => {
  const borderColor =
    theme.palette.mode === "light"
      ? "rgb(6 8 11 / 10%)"
      : "rgba(255, 255, 255, 0.23)";
  return {
    fontFamily: "inherit",
    fontSize: "1rem",
    borderRadius: theme.shape.borderRadius,
    borderColor: (theme as any).vars
      ? `rgba(${(theme as any).vars.palette.common.onBackgroundChannel} / 0.23)`
      : borderColor,
  };
});
export default NotchedOutlineRoot;
