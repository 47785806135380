import { Box, Chip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { STATUS_COLOR } from "constants/color-constants";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/app/store";

const columns: GridColDef[] = [
  { field: "sn", headerName: "S.N", flex: 1, maxWidth: 60 },
  {
    field: "ideaholder_full_name",
    headerName: "Business Name",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      let result = [];
      if (params.row.idea_holder.full_name) {
        result.push(params.row.idea_holder.full_name);
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  {
    field: "investor_full_name",
    headerName: "Investor Name",
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      let result = [];
      if (params.row.investor.full_name) {
        result.push(params.row.investor.full_name);
      } else {
        result = ["Unknown"];
      }
      return result.join(", ");
    },
  },
  {
    field: "created_at",
    headerName: "Date",
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => moment(params.value).format("MM/DD/YYYY"),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 100,
    maxWidth: 100,
    renderCell: (params) => {
      return (
        <Box className="flex-g-5 svg-size">
          <Chip
            label={params?.row?.status}
            variant="filled"
            sx={{
              color: "#fff",
              bgcolor:
                STATUS_COLOR[params?.row?.status?.toLowerCase() as string],
            }}
          />
        </Box>
      );
    },
  },
];

export default function ConnectTable() {
  const connectRequest = useSelector((state: RootState) => state?.connectAll);

  return (
    <div style={{ marginTop: "30px", height: 631, width: "100%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator":
          {
            display: "none",
          },
        }}
        rows={
          connectRequest?.data?.map((el, index) => {
            return {
              ...el,
              sn: index + 1,
            };
          }) ?? []
        }
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
}
